import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PronunciationsService } from "../../services/pronunciations.service";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-pronunciation-list",
  templateUrl: "./pronunciation-list.component.html",
  styleUrls: ["./pronunciation-list.component.scss"],
})
export class PronunciationListComponent implements OnInit, OnDestroy {
  @Input() item;
  unsubscribe: Subject<void> = new Subject();
  visible = false;
  pronunciation_display = "";

  constructor(
    private pronService: PronunciationsService,
    private annotatorEvent: AnnotatorEventService
  ) {
    this.annotatorEvent.onPronunciationUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        const ev = e.exist ? e.exist : e;

        if (this.item.word.toLowerCase() === ev.word.toLowerCase()) {
          if (!e.exist) {
            this.item.pronunciations.push(ev);
          }
          this.item.pronunciation = ev.id;
          this.setPronunciationDisplay();
        }
      });
  }

  ngOnInit() {
    this.setPronunciationDisplay();
  }

  setPronunciationDisplay() {
    if (this.item.pronunciation) {
      for (const pronunciation of this.item.pronunciations) {
        if (this.item.pronunciation === pronunciation.id) {
          this.pronunciation_display = pronunciation.text;
        }
      }
    }
  }

  selectPronunciation(id) {
    this.item.pronunciation = id;
    this.setPronunciationDisplay();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  addPronunciation(item) {
    this.pronService.getPronunciations(item.word).subscribe((res) => {
      this.annotatorEvent.onModalOpen$.emit({ word: item.word, result: res });
    });
  }
}
