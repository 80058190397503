import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ProjectService } from "../../shared/services/project.service";
import { EventService } from "../../core/services/event.service";
import { tap } from "rxjs/internal/operators/tap";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { AnnotatorEventService } from "../../shared/services/annotator-event.service";
import { PronunciationsService } from "../services/pronunciations.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";

declare let WaveSurfer;

@Component({
  selector: "app-audio-annotator",
  templateUrl: "./audio-annotator.component.html",
  styleUrls: ["./audio-annotator.component.scss"],
})
export class AudioAnnotatorComponent implements OnInit, OnDestroy {
  @ViewChild("regionForm") regionForm: ElementRef;
  @ViewChild("pronunciationModal") pronunciationModal: ElementRef;
  @Input() isInspection = false;
  @Input() isViewMode = false;
  @Input() project: any;
  @Input() userTask;
  @Input() canPostpone = false;
  @Input() actions;

  PRONUNCIATION = "pronunciation";
  METATAG = "meta";

  COLOR_REGION_SPLIT = "rgba(218, 56, 56, 0.36)";
  COLOR_REGION_JOIN = "rgba(19, 168, 107, 0.36)";
  COLOR_REGION_DEFAULT = "rgba(57, 97, 226, 0.36)";

  audioLabelMode = this.PRONUNCIATION;
  unsubscribe: Subject<void> = new Subject();

  wavesurfer: any;
  duration: any;
  mode = 3;
  audioSegmentationList = {};
  audioScriptsList = {};
  audioScriptsPronunciationList = {};
  sectionMetaList = {};
  isEdit = false;

  startTimes = [];
  regionStartObj = {};
  loading = true;

  playDeletedSegment = true;
  continuousPlay = true;
  enableCreateByDrag = true;
  minPxPerSec = 50;
  playbackRate = 1;

  isWaveformFocus = false;
  isLockKeyEvent = false;
  isKeydownState = false;

  // 작업 체크용
  isMouseClickEvent = false;
  isKeyDownEvent = false;
  timer;

  currentRegion = null;
  currentPronunciation = null;
  newPronunciation = "";
  errorObj = null;
  pronunciationModeObj = false;

  numOfWords = 0;
  numOfSections = 0;

  variations = null;

  constructor(
    private projectService: ProjectService,
    private cdf: ChangeDetectorRef,
    private event: EventService,
    public modal: NgbModal,
    private route: ActivatedRoute,
    private pronService: PronunciationsService,
    public annotatorEvent: AnnotatorEventService
  ) {
    this.captureEvents();
  }

  ngOnInit(): void {
    this.loading = true;
    this.cdf.detectChanges();

    if (this.project) {
      if (this.userTask.status === "rejected") {
        this.annotatorEvent.onUserTaskRejected$.emit(true);
      }

      if (!this.isEmpty(this.project.variations)) {
        this.audioLabelMode = this.METATAG;
        this.variations = this.project.variations;
      }

      this.numOfWords = this.project.raw_data.data.script.split(" ").length;
      this.numOfSections = Math.floor((this.numOfWords - 1) / 12) + 1;

      this.audioInit(this.project.raw_data.file, this.userTask.data);
      this.event.onProjectUpdate$.emit({ raw_data: this.project.raw_data });
    }
  }

  onAttributeSubmit(e, start) {
    this.sectionMetaList[start] = e.value;
  }

  ngOnDestroy(): void {
    // this.annotatorService.projectSettings.audio['playbackRate'] = this.playbackRate;
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.wavesurfer.unAll();
    this.wavesurfer.destroy();
    this.event.onProjectDestroy$.emit({ project: "audio" });
    clearInterval(this.timer);
  }

  destroy() {
    this.wavesurfer.unAll();
    this.wavesurfer.destroy();
    this.startTimes = [];
    this.audioSegmentationList = {};
    this.audioScriptsList = {};
  }

  captureEvents() {
    this.annotatorEvent.onInspectionCreate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.userTask.inspections.unshift(e);
      });
    this.event.onKeydownEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        if (!this.modal.hasOpenModals()) {
          this.isKeyDownEvent = true;
          if (!this.isLockKeyEvent) {
            this.keydownHandler(e);
          }
        }
      });

    this.event.onKeyupEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        if (!this.isLockKeyEvent) {
          this.keyupHandler(e);
        }
      });

    this.event.onMouseDownEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.isMouseClickEvent = true;
      });

    this.event.onMouseWheelEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: WheelEvent) => {
        this.mousewheelHandler(res);
      });

    this.annotatorEvent.onPlayPause$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.playPause();
      });

    this.annotatorEvent.onStop$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.wavesurfer.stop();
      });

    this.annotatorEvent.onClearUndefinedRegion$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.clearUndefinedRegion();
      });

    this.annotatorEvent.onPlaySplitAudio$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.playSplitAudio(e);
      });

    this.annotatorEvent.onCreateRegion$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.onCreateRegion(e);
      });

    this.annotatorEvent.onZoomUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.setZoom(e);
      });

    this.annotatorEvent.onSubmit$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        const temp: any = e;
        this.submit(temp.is_completed, temp.data);
      });

    this.annotatorEvent.onInputFocus$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        const event: any = e;
        this.isLockKeyEvent = event;
      });

    this.annotatorEvent.onTextToHtml$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        if (this.pronunciationModeObj === true) {
          if (
            confirm(
              "스크립트 모드로 전환시 발음사전이 초기화 됩니다. 전환하시겠습니까?"
            )
          ) {
            this.pronunciationModeObj = !this.pronunciationModeObj;
          }
        } else {
          this.pronunciationModeObj = !this.pronunciationModeObj;
          if (!!this.pronunciationModeObj) {
            this.onTextToHtml();
          }
        }
      });

    this.annotatorEvent.onUpdateRegion$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        const res: any = e;
        if (res.category === "update") {
          this.updateRegion(res.doClose);
        } else if (res.category === "delete") {
          this.deleteRegion();
        } else if (res.category === "cancel") {
          this.isEdit = false;
          this.currentRegion = null;
        }
      });

    this.annotatorEvent.onPlayRegion$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.onPlayRegion();
      });

    this.annotatorEvent.onFormValueChange$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        const res: any = e;
        this.onFormValueChange(res.category, res.value, res.doClose);
      });

    this.annotatorEvent.onModalOpen$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.currentPronunciation = e;
        this.modal.open(this.pronunciationModal, {
          size: "sm",
          centered: true,
        });
      });

    this.annotatorEvent.onWordSubmit$
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => {
          const data = {
            word: this.currentPronunciation.word,
            text: this.newPronunciation,
          };
          this.errorObj = null;
          return this.pronService.postPronunciations(data);
        })
      )
      .subscribe((res) => {
        const result: any = res;
        if (result.error) {
          this.errorObj = result.error;
        } else {
          this.annotatorEvent.onPronunciationUpdate$.emit(res);
          this.newPronunciation = "";
          this.currentPronunciation = null;
          this.modal.dismissAll();
        }
      });
  }

  audioInit(src, data?) {
    if (this.wavesurfer) {
      this.destroy();
    }
    this.wavesurfer = WaveSurfer.create({
      container: "#waveform",
      height: 150,
      pixelRatio: 1,
      scrollParent: true,
      normalize: true,
      minimap: true,
      partialRender: true,
      backend: "MediaElement",
      waveColor: "#adb1bd",
      progressColor: "#3a3f51",
      cursorColor: "#da3838",
      cursorWidth: 2,
      minPxPerSec: this.minPxPerSec,
      timeInterval: 30,
      plugins: [
        WaveSurfer.regions.create(),
        WaveSurfer.minimap.create({
          height: 52,
          waveColor: "#adb1bd",
          progressColor: "#3a3f51",
          cursorWidth: 0,
        }),
        WaveSurfer.timeline.create({
          container: "#wave-timeline",
        }),
        WaveSurfer.cursor.create(),
      ],
    });
    this.wavesurfer.load(src);
    this.wavesurfer.on("ready", () => {
      this.wavesurfer.enableDragSelection({
        color: this.selectColor(),
      });

      // if (!this.isEmpty(this.annotatorService.projectSettings.audio) && !!this.annotatorService.projectSettings.audio['playbackRate']) {
      //   this.playbackRate = this.annotatorService.projectSettings.audio['playbackRate'];
      // }
      if (data) {
        const segments = [];
        for (const segment of data.segments) {
          const temp = {
            start: segment.start,
            end: segment.end,
            data: {
              note: segment.action,
            },
          };
          segments.push(temp);
        }

        this.loadRegions(segments);
        this.loadScripts(data.scripts);
      }
      this.setSegments();
    });

    this.wavesurfer.on("region-created", (region) => {
      region.update({
        attributes: {
          color:
            region.data.note === "split"
              ? "red"
              : region.data.note === "join"
              ? "green"
              : "blue",
        },
      });
      this.editRegion(region);
    });

    this.wavesurfer.on("region-click", (region) => {
      this.editRegion(region);
    });

    this.wavesurfer.on("region-update-end", (region) => {
      // start보다 이른 end를 가진 region 생성시 제거
      if (region.end > this.project.raw_data.data.audio_length) {
        region.end =
          Math.floor(this.project.raw_data.data.audio_length * 100) / 100;
      }
      if (region.start <= region.end) {
        this.initFormValue(region);
        this.setSegments();
      } else {
        region.remove();
      }
    });

    this.wavesurfer.on("region-removed", () => {
      this.setSegments();
    });

    this.wavesurfer.on("waveform-ready", () => {
      this.loading = false;
      if (this.project.raw_data.data.audio_length < 100) {
        this.annotatorEvent.onZoomUpdate$.emit(10);
        this.annotatorEvent.onZoomUpdate$.emit(-10);
      }
      this.cdf.detectChanges();
    });

    this.wavesurfer.on("audioprocess", (time) => {
      if (this.playDeletedSegment === false) {
        const current = time;
        const keys = Object.keys(this.regionStartObj);

        keys.sort((a, b) => {
          return parseFloat(a) < parseFloat(b) ? -1 : 1;
        });

        let closestSmallVal;

        if (keys.length > 0) {
          for (let i = keys.length - 1; i >= 0; i--) {
            if (current >= keys[i]) {
              closestSmallVal = this.regionStartObj[keys[i]];
              break;
            }
          }
          if (closestSmallVal && current < closestSmallVal.end) {
            if (
              closestSmallVal.end <= this.project.raw_data.data.audio_length
            ) {
              this.wavesurfer.play(closestSmallVal.end);
            } else {
              this.wavesurfer.pause();
            }
            if (!this.continuousPlay && closestSmallVal.mode === "split") {
              this.wavesurfer.pause();
            }
          }
        }
      }
    });
  }

  playPause() {
    this.wavesurfer.playPause();
  }

  isPlaying() {
    return this.wavesurfer ? this.wavesurfer.isPlaying() : false;
  }

  editRegion(region) {
    const form = this.regionForm.nativeElement;
    this.currentRegion = region;
    this.isEdit = true;
    (form.elements.start.value = Math.round(region.start * 100) / 100),
      (form.elements.end.value = Math.round(region.end * 100) / 100);
    form.elements.note.value = region.data.note || "";
    form.onsubmit = (e) => {
      e.preventDefault();
      region.update({
        start: form.elements.start.value,
        end: form.elements.end.value,
        data: {
          note: form.elements.note.value,
        },
        attributes: {
          color:
            form.elements.note.value === "split"
              ? "red"
              : form.elements.note.value === "join"
              ? "green"
              : "blue",
        },
      });
      this.currentRegion = null;
      this.isEdit = false;
    };

    form.onreset = () => {
      this.currentRegion = null;
      this.isEdit = false;
      form.dataset.region = null;
    };
    form.dataset.region = region.id;
  }

  updateRegion(doClose?) {
    const form = this.regionForm.nativeElement;
    this.wavesurfer.regions.list[form.dataset.region].update({
      start: form.elements.start.value,
      end: form.elements.end.value,
      data: {
        note: form.elements.note.value,
      },
      color: this.selectColor(form.elements.note.value),
      attributes: {
        color:
          form.elements.note.value === "split"
            ? "red"
            : form.elements.note.value === "join"
            ? "green"
            : "blue",
      },
    });

    this.setSegments();
    if (doClose) {
      form.reset();
    }
  }

  initFormValue(region) {
    const form = this.regionForm.nativeElement;
    (form.elements.start.value = Math.round(region.start * 100) / 100),
      (form.elements.end.value = Math.round(region.end * 100) / 100);
    form.elements.note.value = region.data.note || "";
  }

  deleteRegion() {
    const form = this.regionForm.nativeElement;
    const regionId = form.dataset.region;
    if (regionId) {
      this.wavesurfer.regions.list[regionId].remove();
      form.reset();
    }
  }

  setSegments() {
    const region_list = this.wavesurfer.regions.list;
    const list = this.convertObjectToListByStart(region_list);
    this.audioSegmentationList = {};
    this.audioSegmentationList["0"] = { start: 0 };
    this.startTimes = ["0"];
    this.regionStartObj = {};

    if (Object.keys(list).length > 0) {
      let strIndex = "";
      for (const region of list) {
        let segmentation = {};
        if (!strIndex) {
          if (region.data.note === "split" || region.data.note === "join") {
            segmentation = {
              mode: region.data.note,
              start: 0,
              end: Math.round(region.start * 100) / 100,
              join: Math.round(region.end * 100) / 100,
            };
            strIndex = String(0);
            if (region.data.note === "split") {
              // if (segmentation['end'] <= 0) {
              //   this.startTimes = [];
              //   delete this.audioScriptsList[0];
              // }
              this.startTimes.push(String(segmentation["join"]));
            }
          }
        } else {
          segmentation = {
            mode: region.data.note,
            start:
              Math.round(this.audioSegmentationList[strIndex].join * 100) / 100,
            end: Math.round(region.start * 100) / 100,
            join: Math.round(region.end * 100) / 100,
          };
          if (region.data.note === "split") {
            this.startTimes.push(String(segmentation["join"]));
          }
        }
        if (
          segmentation["mode"] === "split" ||
          segmentation["mode"] === "join"
        ) {
          this.audioSegmentationList[String(segmentation["start"])] =
            segmentation;
          strIndex = String(segmentation["start"]);
        }
        if (region.data.note === "split" || region.data.note === "join") {
          this.regionStartObj[region.start] = {
            mode: region.data.note,
            end: Math.round(region.end * 100) / 100,
          };
        }
      }
    }

    if (Object.keys(this.audioScriptsList).length > 0) {
      for (const key of Object.keys(this.audioScriptsList)) {
        if (Number(key) !== 0) {
          let flag = false;
          for (const time of this.startTimes) {
            if (key === time) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            delete this.audioScriptsList[key];
          }
        }
      }
    } else {
      this.audioScriptsList["0"] = this.project.raw_data.data.script;
    }

    const has_default_script =
      !!this.project.specification &&
      !!this.project.specification.script_default;
    if (has_default_script) {
      const default_script = this.project.specification.script_default;
      for (const time of this.startTimes) {
        if (has_default_script) {
          if (this.isEmpty(this.audioScriptsList[time])) {
            this.audioScriptsList[time] = default_script;
          }
        }
      }
    }
  }

  playSplitAudio(index) {
    this.playDeletedSegment = false;
    this.continuousPlay = false;
    const startTime = this.startTimes[index];
    const segment = this.audioSegmentationList[startTime];
    if (segment) {
      this.wavesurfer.play(segment.start, segment.end);
    } else {
      this.wavesurfer.play(startTime, this.project.raw_data.data.audio_length);
    }
  }

  submit(is_completed, action) {
    const data = {
      segments: [],
      scripts: [],
    };

    if (!!is_completed) {
      for (const key of Object.keys(action.data)) {
        data[key] = action.data[key];
      }
    }

    for (const key of Object.keys(this.wavesurfer.regions.list)) {
      const segment = this.wavesurfer.regions.list[key];
      if (segment.data.note) {
        const item = {
          start: Math.round(segment.start * 100) / 100,
          end: Math.round(segment.end * 100) / 100,
          action: segment.data.note,
        };
        if (item.start === item.end) {
          item.end += 0.01;
        }
        if (item.start < 0) {
          item.start = 0;
        }
        if (item.end >= this.project.raw_data.data.audio_length) {
          item.end = this.project.raw_data.data.audio_length;
        }
        data.segments.push(item);
      }
    }

    for (const key of Object.keys(this.audioScriptsList)) {
      let script = this.audioScriptsList[key];
      if (!script) {
        script = "";
      }
      const item = {
        start: key,
        text: script,
      };
      if (this.audioLabelMode === this.PRONUNCIATION) {
        item["pronunciations"] = this.audioScriptsPronunciationList[key];
      } else {
        item["classification"] = this.sectionMetaList[key];
      }
      // tslint:disable-next-line:radix
      if (parseInt(item.start) < 0) {
        item.start = "0";
      }
      data.scripts.push(item);
    }

    this.projectService
      .putUserTask(this.project.id, data)
      .pipe(
        tap(
          () => {
            if (is_completed) {
              this.destroy();
              for (const act of action.post_actions) {
                if (act === "load_more") {
                  this.event.onProjectSubmit$.emit();
                } else if (act === "close_window") {
                  window.close();
                }
              }
            }
          },
          (err) => {
            const error = err.error;
            let text = "";
            for (const key of Object.keys(error)) {
              text = error[key];
              break;
            }
            alert(text);
          }
        )
      )
      .subscribe();
  }

  changeDragMode(mode) {
    if (mode !== this.enableCreateByDrag) {
      this.enableCreateByDrag = mode;
      if (mode) {
        this.wavesurfer.enableDragSelection({
          color: this.selectColor(),
        });
      } else {
        this.wavesurfer.disableDragSelection();
      }
    }
  }

  selectColor(mode?) {
    if (mode === "split") {
      return this.COLOR_REGION_SPLIT;
    } else if (mode === "join") {
      return this.COLOR_REGION_JOIN;
    } else {
      return this.COLOR_REGION_DEFAULT;
    }
  }

  convertObjectToListByStart(obj) {
    const list = [];
    for (const key of Object.keys(obj)) {
      list.push(obj[key]);
    }

    list.sort((a, b) => {
      return a.start <= b.start ? -1 : 1;
    });

    return list;
  }

  clearUndefinedRegion() {
    for (const key of Object.keys(this.wavesurfer.regions.list)) {
      if (!this.wavesurfer.regions.list[key].data.note) {
        this.wavesurfer.regions.list[key].remove();
      }
    }
  }

  setZoom(zoom) {
    if (zoom > 0) {
      this.minPxPerSec *= zoom * 1.5;
      if (this.minPxPerSec > 1000) {
        this.minPxPerSec = 1000;
      }
    } else {
      if (this.minPxPerSec > 10) {
        this.minPxPerSec *= 0.66;
        this.minPxPerSec /= Math.abs(zoom);
      }
    }
    this.wavesurfer.zoom(this.minPxPerSec);
  }

  private keydownHandler(e) {
    if (!this.isKeydownState) {
      e.preventDefault();
      e.stopPropagation();
      if (e.code === "Space" || e.code === "KeyH") {
        this.annotatorEvent.onPlayPause$.emit();
        return;
      }
      if (e.code === "KeyG") {
        this.annotatorEvent.onPlayRegion$.emit();
        return;
      }
      if (e.code === "KeyQ") {
        this.annotatorEvent.onStop$.emit();
        return;
      }
      if (e.code === "KeyN") {
        this.annotatorEvent.onCreateRegion$.emit(
          this.wavesurfer.getCurrentTime()
        );
        return;
      }
      if (this.isEdit) {
        if (e.code === "ArrowLeft") {
          let obj = { category: "", value: 0 };
          if (e.ctrlKey) {
            obj = { category: "start", value: -0.01 };
          } else {
            obj = { category: "end", value: -0.01 };
          }
          this.annotatorEvent.onFormValueChange$.emit(obj);
          return;
        }
        if (e.code === "ArrowRight") {
          let obj = { category: "", value: 0 };
          if (e.ctrlKey) {
            obj = { category: "start", value: 0.01 };
          } else {
            obj = { category: "end", value: 0.01 };
          }
          this.annotatorEvent.onFormValueChange$.emit(obj);
          return;
        }
        if (e.code === "Backspace") {
          this.annotatorEvent.onUpdateRegion$.emit({ category: "delete" });
          return;
        }
        if (e.code === "KeyS") {
          if (e.ctrlKey) {
            this.annotatorEvent.onSubmit$.emit(false);
          } else {
            this.annotatorEvent.onUpdateRegion$.emit({
              category: "update",
              doClose: true,
            });
          }
          return;
        }
        if (e.code === "KeyA") {
          const obj = { category: "note", value: "join", doClose: false };
          this.annotatorEvent.onFormValueChange$.emit(obj);
          return;
        }
        if (e.code === "KeyD") {
          const obj = { category: "note", value: "split", doClose: false };
          this.annotatorEvent.onFormValueChange$.emit(obj);
          return;
        }
        if (e.code === "Escape") {
          this.annotatorEvent.onUpdateRegion$.emit({ category: "cancel" });
          return;
        }
      } else {
        const skipOffset = 1;
        if (e.code === "ArrowLeft") {
          if (e.ctrlKey) {
            this.wavesurfer.skip(-0.1 * skipOffset);
          } else {
            this.wavesurfer.skip(-1 * skipOffset);
          }
          return;
        }
        if (e.code === "ArrowRight") {
          if (e.ctrlKey) {
            this.wavesurfer.skip(0.1 * skipOffset);
          } else {
            this.wavesurfer.skip(skipOffset);
          }
          return;
        }
        if (e.code === "ArrowUp") {
          if (this.playbackRate <= 2) {
            this.playbackRate += 0.1;
          }
          this.wavesurfer.setPlaybackRate(this.playbackRate);
          return;
        }
        if (e.code === "ArrowDown") {
          if (this.playbackRate >= 0.5) {
            this.playbackRate -= 0.1;
          }
          this.wavesurfer.setPlaybackRate(this.playbackRate);
          return;
        }
      }
    }
    if (e.key !== "Control" && e.key !== "Alt" && e.key !== "Meta") {
      this.isKeydownState = true;
    }

    return true;
  }

  private onFormValueChange(category, val, doClose?) {
    const form = this.regionForm.nativeElement;
    if (category === "note") {
      form.elements[category].value = val;
    } else {
      form.elements[category].value =
        Math.round((parseFloat(form.elements[category].value) + val) * 100) /
        100;
    }
    this.annotatorEvent.onUpdateRegion$.emit({ category: "update", doClose });
  }

  private keyupHandler(e) {
    if (e.key !== "Control" && e.key !== "Alt" && e.key !== "Meta") {
      this.isKeydownState = false;
    }
  }

  private mousewheelHandler(res: WheelEvent) {
    if (this.isWaveformFocus && !res.shiftKey) {
      const absDeltaY = Math.abs(res.deltaY);
      const absDeltaX = Math.abs(res.deltaX);
      if (absDeltaY > absDeltaX) {
        event.preventDefault();
        event.stopPropagation();
        res.deltaY < 0
          ? this.annotatorEvent.onZoomUpdate$.emit(1)
          : this.annotatorEvent.onZoomUpdate$.emit(-1);
      }
    }
  }

  private onCreateRegion(start) {
    const startTime = Math.round(start * 100) / 100;
    const newColor = this.selectColor();
    this.wavesurfer.addRegion({
      data: {},
      start: startTime,
      end: startTime + 1,
      color: newColor,
    });
  }

  private onTextToHtml() {
    for (let i = 0; i < this.startTimes.length; i++) {
      const time = this.startTimes[i];
      const data = {
        user_task: this.userTask.id,
        index: i,
        content: this.audioScriptsList[time],
      };
      this.pronService.convertTextToArray(data).subscribe(
        (res) => {
          this.audioScriptsPronunciationList[time] = res;
        },
        (err) => {
          this.audioScriptsPronunciationList = {};
          this.pronunciationModeObj = false;
          alert("예기치 않은 오류가 발생했습니다.");
        }
      );
    }
  }

  private onPlayRegion() {
    if (this.wavesurfer && this.currentRegion) {
      this.playDeletedSegment = true;
      this.currentRegion.play();
    }
  }

  private loadRegions(regions) {
    regions.forEach((region) => {
      region.color = this.selectColor(region.data.note);
      region.attributes = {
        color:
          region.data.note === "split"
            ? "red"
            : region.data.note === "join"
            ? "green"
            : "blue",
      };
      this.wavesurfer.addRegion(region);
    });
    this.currentRegion = null;
    this.isEdit = false;
  }

  private loadScripts(textList) {
    textList.forEach((object) => {
      this.audioScriptsList[object.start] = object.text;
      this.sectionMetaList[object.start] = object.classification;
    });
  }

  public onActionClicked(e) {
    const action: any = e;
    const isCompleted = !!action.data.status || !!action.data.status_reserved;
    if (!e.message_confirm || confirm(action.message_confirm)) {
      this.annotatorEvent.onSubmit$.emit({
        is_completed: isCompleted,
        data: action,
      });
    }
  }

  private selectPronunciation(item) {
    this.annotatorEvent.onPronunciationUpdate$.emit({ exist: item });
    this.modal.dismissAll();
  }

  isEmpty(obj) {
    if (!!obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }
}
