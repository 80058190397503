import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ImageClassificationPageComponent } from "./pages/image-classification-page/image-classification-page.component";

@NgModule({
  declarations: [ImageClassificationPageComponent],
  imports: [SharedModule, CommonModule, FormsModule],
  exports: [ImageClassificationPageComponent],
})
export class ClassificationAnnotatorModule {}
