import { Component, OnInit } from "@angular/core";
import { EventService } from "../../services/event.service";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../../account/services/user.service";
import { Router } from "@angular/router";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  user;
  startTime;
  worktime;
  totalseconds;
  file;
  group;
  worker;
  isRejected = false;
  isViewFileName = false;
  isViewGroupName = false;
  configurations;
  interval;

  constructor(
    private auth: AuthService,
    private event: EventService,
    private annotatorEvent: AnnotatorEventService,
    private userClient: UserService,
    private router: Router,
    public toastr: ToastrService
  ) {
    event.onUserUpdate$.subscribe(this.setUser.bind(this));

    event.onProjectUpdate$.subscribe((e) => {
      if (e) {
        this.configurations = e.configurations;
        if (this.configurations && this.configurations.is_view_filename) {
          this.isViewFileName = !!this.configurations.is_view_filename;
        }

        if (this.configurations && this.configurations.is_view_groupname) {
          this.isViewGroupName = !!this.configurations.is_view_groupname;
        }

        if (this.isViewGroupName || this.isViewFileName) {
          const file_legacy = e.raw_data.data.data_legacy;
          if (file_legacy) {
            const group = file_legacy.group
              ? file_legacy.group
              : file_legacy.file;
            if (typeof group === "string") {
              const split_by_slash = group.split("/");
              this.file = split_by_slash[split_by_slash.length - 1];
              this.group = split_by_slash[split_by_slash.length - 1];
            }
          } else {
            const full_file = e.raw_data.data.file
              ? e.raw_data.data.file
              : e.raw_data.data.file_video;
            if (typeof full_file === "string") {
              const split_by_slash = full_file.split("/");
              this.file = split_by_slash[split_by_slash.length - 1];
            }
            const full_group = e.raw_data.data.group;
            if (typeof full_group === "string") {
              const split_by_slash = full_group.split("/");
              this.group = split_by_slash[split_by_slash.length - 1];
            }
          }
        }
      } else {
        // this.file = "";
        // this.group = "";
      }
      this.startTime = new Date();
      this.totalseconds = 0;
      this.setTimer();
    });

    event.onProjectDestroy$.subscribe((e) => {
      this.worker = null;
      this.file = "";
      this.group = "";
      this.isRejected = false;
      clearInterval(this.interval);
    });

    event.onInspectionUpdate$.subscribe((e) => {
      this.worker = e;
    });

    annotatorEvent.onSubmit$.subscribe((e) => {
      if (!e || !e.isCompleted) {
        this.toastr.success("저장되었습니다.");
      }
    });

    annotatorEvent.onUserTaskRejected$.subscribe((e) => {
      this.isRejected = e;
    });
  }

  ngOnInit() {}

  setUser() {
    this.user = this.userClient.getUser();
  }

  logout() {
    this.auth.logout();
    this.router.navigate(["/"]);
  }

  isLoggedIn() {
    return this.auth.isLoggedIn();
  }

  setTimer() {
    let minutes = 0;
    let seconds = 0;

    this.interval = setInterval(() => {
      this.totalseconds += 1;
      minutes = Math.floor(this.totalseconds / 60);
      seconds = this.totalseconds % 60;
      this.worktime = "Timer: " + minutes + "분 " + seconds + "초";
    }, 1000);
  }
}
