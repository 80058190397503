import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PronunciationsService {
  constructor(private http: HttpClient) {}

  convertTextToArray(data) {
    return this.http.post(
      environment.apiUrl + "audio_segmentation/pronunciations/convert/",
      data
    );
  }

  getPronunciations(word) {
    return this.http.get(
      environment.apiUrl +
        "audio_segmentation/pronunciations/?word=" +
        encodeURIComponent(word)
    );
  }

  postPronunciations(data) {
    return this.http
      .post(environment.apiUrl + "audio_segmentation/pronunciations/", data)
      .pipe(
        catchError((err) => {
          return of({ error: err });
        })
      );
  }
}
