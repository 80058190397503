import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { ProjectService } from "../../../shared/services/project.service";
import { EventService } from "../../../core/services/event.service";
import { tap } from "rxjs/internal/operators/tap";
import { UserService } from "../../../account/services/user.service";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";

@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent implements OnInit {
  project$: Observable<any>;
  canParticipateProject;
  loading = false;
  projectObject;
  usertask;
  canPostpone;
  actions;
  isInspection = false;
  isViewMode = false;
  preloadedImgs;
  error_msg = "";

  constructor(
    private route: ActivatedRoute,
    private event: EventService,
    private projectService: ProjectService,
    private userService: UserService,
    private annotatorEvent: AnnotatorEventService
  ) {
    this.event.onProjectSubmit$.subscribe(() => {
      this.project$ = null;
      this.ngOnInit();
    });

    this.annotatorEvent.onImageLoadend$.subscribe(() => {
      if (
        this.usertask.urls_file_next &&
        this.usertask.urls_file_next.length > 0
      ) {
        this.preloadImages(this.usertask.urls_file_next);
      }
    });
  }

  ngOnInit() {
    if (this.getBrowserName() !== "chrome") {
      alert(
        "크롬브라우저에서 실행해주세요.\nPlease Use Chrome (Unsupported Browser)"
      );
    } else {
      this.loading = true;
      this.canParticipateProject = false;
      this.projectObject = null;
      this.project$ = this.route.paramMap.pipe(
        tap((params: ParamMap) => {
          let userTaskGetter$;
          let userTaskId =
            this.route.snapshot.queryParamMap.get("user_task_id");
          const projectId = params.get("id");
          const mode = this.route.snapshot.queryParamMap.get("mode");

          if (userTaskId) {
            userTaskGetter$ = this.projectService.getUserTask(userTaskId);
          } else {
            userTaskGetter$ = this.projectService.participate(projectId).pipe(
              tap(
                (res) => {
                  const result: any = res;
                  userTaskId = result.id;
                },
                (err) => {
                  this.error_msg = err.error;
                }
              ),
              switchMap(() => {
                return this.projectService.getUserTask(userTaskId);
              })
            );
          }

          userTaskGetter$
            .pipe(
              tap((res) => {
                const result: any = res;
                this.error_msg = "";
                this.canParticipateProject = !!result;
                this.canPostpone = result.can_postpone;
                this.usertask = result;
                this.actions = result.actions;
                this.projectObject = result;
              }),
              switchMap(() => {
                return this.userService.getMe();
              })
            )
            .subscribe(
              (res) => {
                const result: any = res;
                if (mode === "inspect" && result.is_staff) {
                  this.isInspection = true;
                }
                if (mode === "view") {
                  this.isViewMode = true;
                }
                this.loading = false;
              },
              (err) => {
                this.loading = false;
              }
            );
        }),
        switchMap((params: ParamMap) =>
          this.projectService.get(params.get("id"))
        )
      );
    }
  }

  preloadImages(array) {
    if (!this.preloadedImgs) {
      this.preloadedImgs = [];
    }
    const list = this.preloadedImgs;
    for (let i = 0; i < array.length; i++) {
      const img = new Image();
      img.onload = function () {
        const index = list.indexOf(this);
        if (index !== -1) {
          list.splice(index, 1);
        }
      };
      list.push(img);
      img.src = array[i];
    }
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!window["opr"]:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!window["chrome"]:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }
}
