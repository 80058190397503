import { Component, Input, OnInit } from "@angular/core";
import { AnnotatorEventService } from "../../services/annotator-event.service";
import { InspectionService } from "../../services/inspection.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-comment-form",
  templateUrl: "./comment-form.component.html",
  styleUrls: ["./comment-form.component.scss"],
})
export class CommentFormComponent implements OnInit {
  @Input() userTaskId;
  @Input() labelId;
  @Input() tagsList;
  @Input() inspection;
  @Input() isViewMode;

  scope;
  comment = "";
  tags = [];
  is_resolved;

  constructor(
    public annotatorEvent: AnnotatorEventService,
    public toastr: ToastrService,
    private inspectionService: InspectionService
  ) {}

  ngOnInit() {
    if (this.inspection) {
      this.comment = this.inspection.content;
      this.tags = this.inspection.tags;
      this.is_resolved = this.inspection.is_resolved;
      this.scope = this.inspection.scope;
    } else {
      this.scope = "user_task";
    }
  }

  submit(id?) {
    if (id === null || id === undefined) {
      const data = {
        user_task: this.userTaskId,
        content: this.comment,
        scope: this.scope,
      };
      this.inspectionService.create(data).subscribe((res) => {
        this.annotatorEvent.onInspectionCreate$.emit(res);
        this.comment = "";
        this.toastr.success("코멘트가 등록되었습니다.");
      });
    } else {
      if (this.inspection && this.inspection.id) {
        const data = {
          content: this.comment,
          tags: this.tags,
          is_resolved: this.is_resolved,
        };
        this.inspectionService
          .patch(this.inspection.id, data)
          .subscribe((res) => {
            this.inspection = res;
            this.annotatorEvent.onInspectionCreate$.emit({
              data: res,
              action: "change",
            });
            this.toastr.info("코멘트가 변경되었습니다.");
          });
      } else {
        const data = {
          user_task: this.userTaskId,
          label_id: id,
          scope: "label",
          content: this.comment,
          tags: this.tags,
          is_resolved: this.is_resolved,
        };

        this.inspectionService.create(data).subscribe((res) => {
          this.inspection = res;
          this.annotatorEvent.onInspectionCreate$.emit(res);
          this.toastr.success("코멘트가 등록되었습니다.");
        });
      }
    }
  }

  hasId(id) {
    return this.tags.includes(id);
  }

  toggle(id) {
    if (this.hasId(id)) {
      this.tags = this.tags.filter((num) => num !== id);
    } else {
      this.tags.push(id);
    }
  }
}
