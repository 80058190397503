import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import {fromEvent, Subject} from "rxjs";
import { ProjectService } from "../../../shared/services/project.service";
import { EventService } from "../../../core/services/event.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { DynamicFormComponent } from "../../../shared/components/dynamic-form/dynamic-form.component";

@Component({
  selector: "app-image-classification-page",
  templateUrl: "./image-classification-page.component.html",
  styleUrls: ["./image-classification-page.component.scss"],
})
export class ImageClassificationPageComponent implements OnInit, OnDestroy {
  @ViewChild("shorcutModal") shorcutModal: ElementRef;
  @ViewChild("image") public image: ElementRef;
  @ViewChild(DynamicFormComponent) dynamicForm;

  unsubscribe: Subject<void> = new Subject();
  brightness = 100;
  zoom = 1;
  zoom_fitvalue = 1;
  currentScroll;
  toolbar = [];
  specification;
  variations;
  submitData = {};
  comments_usertask;
  comments_label;
  comments_project;
  variationIndex = "0";
  isSelectDisabled: boolean;

  public configurations = null;

  public imageEl;

  public split_guide = {
    horizontal_block_length: 0,
    vertical_block_length: 0,
    block_array: [],
    image_width: 0,
    image_height: 0,
  };

  @Input() isInspection = false;
  @Input() isViewMode = false;
  @Input() project: any;
  @Input() userTask;
  @Input() canPostpone = false;
  @Input() actions;

  constructor(
    private projectService: ProjectService,
    private event: EventService,
    public modal: NgbModal,
    private annotatorEvent: AnnotatorEventService
  ) {
    this.captureEvents();
  }

  loadImage(): void {
    this.imageEl = this.image.nativeElement;
    this.split_guide.image_width = this.imageEl.width;
    this.split_guide.image_height = this.imageEl.height;
  }

  ngOnInit(): void {
    window.addEventListener("resize", () => {
      this.loadImage();
    });
    if (this.project) {
      this.variations = this.project.variations;
      if (this.isEmpty(this.project.data)) {
        this.submitData = {
          classification: null,
        };
      } else {
        this.submitData["classification"] = this.project.data;
        if (this.variations && this.variations.length > 1) {
          this.variationIndex = this.variations.indexOf(
            this.variations.find(
              (d) => d.code === this.submitData["classification"].code
            )
          );
        }
      }

      this.updateInspection();

      this.specification = this.project.specification
        ? this.project.specification
        : null;
      if (this.specification.configurations) {
        this.configurations = this.specification.configurations;
        if (this.configurations.split_guide_size) {
          const arr = this.configurations.split_guide_size.split("x");
          if (arr.length === 2) {
            this.split_guide.horizontal_block_length = arr[0];
            this.split_guide.vertical_block_length = arr[1];
            this.split_guide.block_array = new Array(
              this.split_guide.horizontal_block_length *
                this.split_guide.vertical_block_length
            );
          }
        }
      } else if (
        this.specification &&
        this.specification.configurations &&
        this.specification.configurations.classification
      ) {
        this.isSelectDisabled =
          !!this.specification.configurations.classification.disable_update;
      }
      this.event.onProjectUpdate$.emit();
    }
  }

  isVideo(filename) {
    if (filename && filename.length > 0) {
      const ext = this.getExtensionOfFilename(filename);
      const movieExtensions = [".mp4", ".mov", ".wmv", ".avi", ".webm", ".mkv"];
      return movieExtensions.includes(ext);
    } else {
      return false;
    }
  }

  private getExtensionOfFilename(filename) {
    const fileLen = filename.length;
    const lastDot = filename.lastIndexOf(".");
    const fileExt = filename.substring(lastDot, fileLen).toLowerCase();
    return fileExt;
  }

  findObjectIndex(arr, id) {
    return arr.findIndex((element) => element.id === id);
  }

  onRegionVariationIndexChanged($event) {
    this.dynamicForm.onSubmit();
    this.annotatorEvent.onRegionVariationIndexChanged$.emit({
      val: this.variationIndex,
    });
  }

  updateInspection = () => {
    if (this.userTask.inspections && this.userTask.inspections.length > 0) {
      const comments = this.userTask.inspections;
      this.comments_label = this.getFilteredComments(comments, "label");
      this.comments_usertask = this.getFilteredComments(comments, "user_task");
      this.comments_project = this.getFilteredComments(comments, "project");
    }
  };

  captureEvents() {
    this.annotatorEvent.onInspectionCreate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        if (e.action && e.action === "change") {
          const inspections = this.project.inspections;
          const index = this.findObjectIndex(inspections, e.data.id);
          if (index > -1) {
            inspections[index] = e.data;
          }
        } else {
          this.project.inspections.unshift(e);
        }
        const comments = this.project.inspections;
        this.comments_label = this.getFilteredComments(comments, "label");
        this.comments_usertask = this.getFilteredComments(
          comments,
          "user_task"
        );
        this.comments_project = this.getFilteredComments(comments, "project");
      });

    this.annotatorEvent.onInspectionCreate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.userTask.inspections.unshift(e);
      });

    this.annotatorEvent.onZoomUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.setZoom(e);
      });

    this.annotatorEvent.onBrightnessUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.setBrightness(e);
      });

    this.annotatorEvent.onScrollUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        // this.updateScroll(e);
      });

    this.annotatorEvent.onSubmit$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        if (!!e && e.isCompleted) {
          this.submitTask(e.isCompleted, e.action);
        } else {
          this.submitTask(false);
        }
      });

    this.annotatorEvent.onModalOpen$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.modal.open(this.shorcutModal, { centered: true });
      });

    this.annotatorEvent.onZoomReset$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setZoom({ zoom: this.zoom_fitvalue });
        this.annotatorEvent.onPositionUpdate$.emit();
      });
    const windowKeydown = fromEvent(window, "keydown")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.windowKeydownHandler(e);
      });
  }

  ngOnDestroy(): void {
    this.event.onProjectDestroy$.emit();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getFilteredComments(comments, name) {
    return comments.filter((comment) => comment.scope === name);
  }

  submitTask(isComplete, action?) {
    if (!this.isViewMode) {
      if (!!isComplete) {
        for (const key of Object.keys(action.data)) {
          this.submitData[key] = action.data[key];
        }
      }
      this.annotatorEvent.onErrorsUpdated$.emit({ error: [] });
      this.projectService
        .putUserTask(this.project.id, this.submitData)
        .subscribe(
          (res) => {
            if (!!isComplete) {
              this.event.onProjectSubmit$.emit();
            }
          },
          (err) => {
            if (err.error.non_field_errors) {
              alert(err.error.non_field_errors[0]);
            } else if (err.error.labels) {
              const labelErrors = err.error.labels;
              const errors = [];
              for (let i = 0; i < labelErrors.length; i++) {
                if (!this.isEmpty(labelErrors[i])) {
                  errors.push(i);
                }
              }
              this.annotatorEvent.onErrorsUpdated$.emit({ error: errors });
              alert("There is an uncompleted work. Please check it.");
            } else {
              const errors = [];

              for (const key of Object.keys(err.error)) {
                errors.push(err.error[key][0]);
              }

              if (errors[0].classification && errors[0].classification.code) {
                alert(errors[0].classification.code);
              } else {
                alert(errors[0]);
              }
            }

            this.projectService
              .getUserTask(this.userTask.id)
              .subscribe((res) => {
                this.userTask = res;
                this.updateInspection();
              });
          }
        );
    }
  }

  setBrightness(val) {
    const temp = this.brightness + val;
    if (temp > 100) {
      this.brightness = 100;
      return;
    } else if (temp < 0) {
      this.brightness = 0;
    } else {
      this.brightness = temp;
    }
  }

  setZoom(e) {
    this.zoom = e.zoom;
    if (e.zoom_fitvalue) {
      this.zoom_fitvalue = e.zoom_fitvalue;
    }
    if (e.scroll) {
      this.setScroll(e.scroll);
    }
  }

  setScroll(scroll) {
    const area = document.getElementById("displayArea");
    area.scrollTop = Math.floor(scroll.y);
    area.scrollLeft = Math.floor(scroll.x);
    if (area.scrollLeft < scroll.x || area.scrollTop < scroll.y) {
      setTimeout(() => {
        area.scrollTop = Math.floor(scroll.y);
        area.scrollLeft = Math.floor(scroll.x);
      }, 10);
    }
    this.currentScroll = scroll;
  }

  onChanged(e) {
    if (this.variations && this.variations.length > 1) {
      this.submitData["classification"] = {
        code: this.variations[this.variationIndex].code,
        attribute: { ...e.value },
      };
    } else {
      this.submitData["classification"] = e.value;
    }
  }

  isEmpty(obj) {
    if (!!obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  private windowKeydownHandler(e) {
    if (e.target === document.body) {
      // process the keyboard event
      this._rdp_handle_global_keydown_event(e);
    }
  }

  private _rdp_handle_global_keydown_event(e) {
      if (e.code === "Equal") {
        const afterZoom = this.zoom * 1.2;
        const temp = {
          zoom: afterZoom,
        };
        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }

      if (e.code === "Minus") {
        const afterZoom = this.zoom * 0.8;
        const temp = {
          zoom: afterZoom,
        };

        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }
  }
}
