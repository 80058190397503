import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { UserCreateFormComponent } from "./components/user-create-form/user-create-form.component";
import { AccountRoutingModule } from "./account-routing.module";
import { TokenLoginComponent } from "./components/token-login/token-login.component";

@NgModule({
  declarations: [
    LoginFormComponent,
    UserCreateFormComponent,
    TokenLoginComponent,
  ],
  exports: [LoginFormComponent, UserCreateFormComponent],
  imports: [
    SharedModule,
    AccountRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AccountModule {}
