import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { fromEvent, Subject } from "rxjs";

@Component({
  selector: "app-minimap-canvas",
  templateUrl: "./minimap-canvas.component.html",
  styleUrls: ["./minimap-canvas.component.scss"],
})
export class MinimapCanvasComponent implements AfterViewInit {
  @ViewChild("imageCanvas") public imageCanvas: ElementRef;
  @ViewChild("virtualCanvas") public virtualCanvas: ElementRef;
  @Input() imgUrl: string;
  unsubscribe: Subject<void> = new Subject();
  private cx: CanvasRenderingContext2D;
  private virtualCx: CanvasRenderingContext2D;
  public imageCanvasEl: HTMLCanvasElement;
  public virtualCanvasEl: HTMLCanvasElement;
  public srcImg;

  constructor(public annotatorEvent: AnnotatorEventService) {}

  ngAfterViewInit() {
    if (this.imgUrl) {
      this.srcImg = new Image();
      this.srcImg.src = this.imgUrl;

      fromEvent(this.srcImg, "load").subscribe(() => {
        this.imageCanvasEl = this.imageCanvas.nativeElement;
        this.cx = this.imageCanvasEl.getContext("2d");
        this.cx.imageSmoothingEnabled = false;

        this.virtualCanvasEl = this.virtualCanvas.nativeElement;
        this.virtualCx = this.virtualCanvasEl.getContext("2d");
        this.virtualCx.imageSmoothingEnabled = false;

        this.captureEvents();
      });
    }
  }

  captureEvents() {
    this.annotatorEvent.onMousemove$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        const pos = e.position;
        const canvas = e.canvas;
        this.cx.clearRect(0, 0, 200, 150);
        this.cx.drawImage(
          this.srcImg,
          pos.x - 20,
          pos.y - 15,
          40,
          30,
          0,
          0,
          200,
          150
        );
        this.cx.rect(100, 75, 4, 4);
        this.cx.fill();

        this.virtualCx.clearRect(0, 0, 200, 150);
        if (canvas) {
          this.virtualCx.drawImage(
            canvas,
            pos.x - 20,
            pos.y - 15,
            40,
            30,
            0,
            0,
            200,
            150
          );
        }
      });
  }
}
