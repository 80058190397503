import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
import { EventService } from "../../core/services/event.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private event: EventService) {}

  get(id) {
    return this.http.get(environment.apiUrl + "users/" + id + "/");
  }

  getMe() {
    return this.http.get(environment.apiUrl + "users/me/");
  }

  updateUser() {
    this.event.onUserUpdate$.emit();
    // return this.updateUser$().subscribe();
  }

  updateUser$() {
    return this.get("me").pipe(
      tap(
        (user) => {
          localStorage.setItem("user", JSON.stringify(user));
          this.event.onUserUpdate$.emit(user);
        },
        (error) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.event.onUserUpdate$.emit(error);
        }
      )
    );
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  create(data) {
    return this.http.post(environment.apiUrl + "users/", data);
  }

  update(data) {
    return this.http.put(environment.apiUrl + "users/me/", data);
  }

  changePassword(data) {
    return this.http.post(
      environment.apiUrl + "users/me/change_password/",
      data
    );
  }
}
