import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ImageCanvasComponent } from "../../components/image-canvas/image-canvas.component";
import { ProjectService } from "../../../shared/services/project.service";
import { SidebarLabelComponent } from "../../../shared/components/sidebar-label/sidebar-label.component";
import { AnnotatorService } from "../../../shared/services/annotator.service";
import { SegmentationCanvasComponent } from "../../components/segmentation-canvas/segmentation-canvas.component";
import { EventService } from "../../../core/services/event.service";
import { ActivatedRoute } from "@angular/router";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileRegion } from "../../../model/file-region";
import { tap } from "rxjs/internal/operators/tap";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { nanoid } from "nanoid";
import { UsertaskService } from "../../../project/services/usertask.service";

@Component({
  selector: "app-image-annotator-page",
  templateUrl: "./image-annotator-page.component.html",
  styleUrls: ["./image-annotator-page.component.scss"],
})
export class ImageAnnotatorPageComponent implements OnInit, OnDestroy {
  @ViewChild(ImageCanvasComponent) imageCanvas;
  @ViewChild(SegmentationCanvasComponent) segmentationCanvas;
  @ViewChild(SidebarLabelComponent) sidebarLabel;
  @ViewChild("shorcutModal") shorcutModal: ElementRef;
  @ViewChild("thumbnailList") thumbnailList: ElementRef;
  unsubscribe: Subject<void> = new Subject();
  projectData: any;
  obj_variations = {};
  mode = "annotation";
  drawMode = "draw";
  brightness = 100;
  zoom = 1;
  zoom_fitvalue = 1;
  loading = false;
  currentScroll;
  toolbar = [];
  btn_group_data_request = [];
  specification;
  init_value = "rect";
  is_video = false;
  thumbnails;
  next_thumbnails;
  previous_thumbnails;
  comments_usertask;
  comments_label;
  comments_project;
  selectedRegionIndex;

  @Input() isInspection = false;
  @Input() isViewMode = false;
  @Input() project: any;
  @Input() userTask;
  @Input() canPostpone = false;
  @Input() actions;

  constructor(
    private projectService: ProjectService,
    private userTaskService: UsertaskService,
    private event: EventService,
    public modal: NgbModal,
    private annotatorEvent: AnnotatorEventService,
    private route: ActivatedRoute,
    private annotatorService: AnnotatorService
  ) {
    this.captureEvents();
  }

  ngOnInit(): void {
    if (this.project) {
      if (this.project.variations) {
        for (let i = 0; i < this.project.variations.length; i++) {
          const item = this.project.variations[i];
          this.obj_variations[item.code] = {
            name: item.name,
            color: item.value,
            index: i,
          };
        }
      }

      if (this.project.data && this.project.data.length > 0) {
        for (const obj of this.project.data) {
          if (!obj.id) {
            obj.id = nanoid();
          }
        }
      }
      this.projectData = this.convertRawdataToAnnotationData(this.project.data);
      this.updateInspection();
      this.specification = this.project.specification
        ? this.project.specification
        : null;
      if (this.specification) {
        this.toolbar = this.project.specification.toolbar
          ? this.project.specification.toolbar
          : null;
        this.btn_group_data_request = this.project.specification
          .btn_group_data_request
          ? this.project.specification.btn_group_data_request
          : null;
        this.init_value = this.toolbar ? this.toolbar[0].value : "rect";
        this.is_video = !!this.project.specification.is_video;
        if (this.is_video && !!this.project.url_cursor) {
          this.getThumbnails(this.project.url_cursor);
        }
      }
      this.event.onProjectUpdate$.emit({
        raw_data: this.project.raw_data,
        configurations: this.specification.configurations
          ? this.specification.configurations
          : null,
      });
      if (this.isInspection) {
        this.event.onInspectionUpdate$.emit(this.project.user);
      }
    }
  }

  reloadTask(data?) {
    const init_data = data ? data : this.project.data;
    if (this.project) {
      this.projectData = this.convertRawdataToAnnotationData(init_data);
      this.event.onProjectUpdate$.emit();
    }
  }

  captureEvents() {
    this.annotatorEvent.onInspectionCreate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        if (e.action && e.action === "change") {
          const inspections = this.project.inspections;
          const index = this.findObjectIndex(inspections, e.data.id);
          if (index > -1) {
            inspections[index] = e.data;
          }
        } else {
          this.project.inspections.unshift(e);
        }
        const comments = this.project.inspections;
        this.comments_label = this.getFilteredComments(comments, "label");
        this.comments_usertask = this.getFilteredComments(
          comments,
          "user_task"
        );
        this.comments_project = this.getFilteredComments(comments, "project");
      });

    this.annotatorEvent.onZoomUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.setZoom(e);
      });

    this.annotatorEvent.onDrawmodeUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: string) => {
        this.drawMode = e;
      });

    this.annotatorEvent.onBrightnessUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.setBrightness(e);
      });

    this.event.onSelectedItemChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ rid, isSidebarClick }) => {
        this.selectItem(rid, isSidebarClick);
        this.selectedRegionIndex = rid;
      });

    this.annotatorEvent.onScrollUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        // this.updateScroll(e);
      });


    this.annotatorEvent.onDataRequest$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        const val = {
          post_participant: e.post_participant,
        };
        this.requestData(this.userTask.id, val);
      });

    this.annotatorEvent.onSubmit$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.imageCanvas.undoStackInit();
        if (!!e && e.isCompleted) {
          this.submitTask(e.isCompleted, e.action);
        } else {
          this.submitTask(false);
        }
      });

    this.annotatorEvent.onModalOpen$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.modal.open(this.shorcutModal, { centered: true });
      });

    this.annotatorEvent.onZoomReset$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setZoom({ zoom: this.zoom_fitvalue });
        this.annotatorEvent.onPositionUpdate$.emit();
      });
  }

  ngOnDestroy(): void {
    this.event.onProjectDestroy$.emit();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectItem(index, isSidebarClick) {
    this.imageCanvas.select_only_region(index, isSidebarClick);
  }

  onChangeSelectedRegion($event) {
    this.selectedRegionIndex = $event;
    if (this.sidebarLabel) {
      this.sidebarLabel.initSidebar($event);
    }
  }

  convertRawdataToAnnotationData(rawDataList) {
    const tempList = [];

    if (rawDataList) {
      for (const rawData of rawDataList) {
        if (!this.obj_variations[rawData.classification.code]) {
          alert("프로젝트 설정에 오류가 있습니다. 담당 매니저에게 문의 바랍니다.");
          return;
        }
        const item = new FileRegion();
        item.region_attributes = {
          variation: rawData.classification.code,
          variation_index:
            this.obj_variations[rawData.classification.code].index,
          variation_display:
            this.obj_variations[rawData.classification.code].name,
        };
        item.shape_attributes = {
          category: "",
        };
        item.id = rawData.id;
        item.is_lock = !!rawData.is_lock;

        for (const key of Object.keys(rawData.classification.attributes)) {
          if (Number(key) >= 0) {
            item.region_attributes[
              rawData.classification.attributes[key].code
            ] = rawData.classification.attributes[key].value;
          } else {
            item.region_attributes[key] =
              rawData.classification.attributes[key];
          }
        }

        item.shape_attributes.category = rawData.label.category;
        if (item.shape_attributes.category === "rect") {
          for (const key of Object.keys(rawData.label.data)) {
            item.shape_attributes[key] = rawData.label.data[key];
          }
        } else if (
          item.shape_attributes.category === "polygon" ||
          item.shape_attributes.category === "polyline"
        ) {
          item.shape_attributes["all_points_x"] = [];
          item.shape_attributes["all_points_y"] = [];
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < rawData.label.data.length; i++) {
            item.shape_attributes["all_points_x"].push(rawData.label.data[i].x);
            item.shape_attributes["all_points_y"].push(rawData.label.data[i].y);
          }
        } else if (item.shape_attributes.category === "point") {
          item.shape_attributes["cx"] = rawData.label.data.x;
          item.shape_attributes["cy"] = rawData.label.data.y;
        }
        tempList.push(item);
      }
    }
    return tempList;
  }

  submitTask(isComplete, action?) {
    if (!this.isViewMode) {
      this.loading = true;
      const submitData = {
        labels: [],
      };

      const currentImageId = this.annotatorService.currentImageId;
      const metadata = this.annotatorService.metadata[currentImageId];
      const regions = metadata.regions;
      const variationObj = {};
      const dataList = [];

      for (const obj of this.project.variations) {
        variationObj[obj.code] = {};
        const temp = variationObj[obj.code];
        for (const attribute of obj.attributes) {
          temp[attribute.code] = attribute;
        }
      }

      for (const region of regions) {
        const regionData = {
          classification: {
            code: "",
            attributes: [],
          },
          label: {
            category: "",
            data: null,
          },
          id: region.id,
        };
        const region_attributes = region.region_attributes;
        const shape_attributes = region.shape_attributes;
        regionData.classification.code = region_attributes["variation"];
        if (!!regionData.classification.code) {
          for (const key of Object.keys(
            variationObj[region_attributes["variation"]]
          )) {
            if (key !== "variation_index" && key !== "variation_display") {
              const attribute = {
                code: key,
                value: region_attributes[key],
              };
              regionData.classification.attributes.push(attribute);
            }
          }
          regionData.label["category"] = shape_attributes["category"];
          if (shape_attributes.category === "rect") {
            regionData.label["data"] = {};
            for (const key of Object.keys(shape_attributes)) {
              if (key !== "category") {
                regionData.label.data[key] = shape_attributes[key];
              }
            }
          } else if (
            shape_attributes.category === "polygon" ||
            shape_attributes.category === "polyline"
          ) {
            regionData.label["data"] = [];
            for (let i = 0; i < shape_attributes["all_points_x"].length; i++) {
              regionData.label["data"].push({
                x: shape_attributes.all_points_x[i],
                y: shape_attributes.all_points_y[i],
              });
            }
          } else if (shape_attributes.category === "point") {
            regionData.label["data"] = {
              x: shape_attributes.cx,
              y: shape_attributes.cy,
            };
          }
          dataList.push(regionData);
        } else {
          if (shape_attributes.category === "rect") {
            regionData.label["data"] = {};
            for (const key of Object.keys(shape_attributes)) {
              if (key !== "category") {
                regionData.label.data[key] = shape_attributes[key];
              }
            }
          } else if (
            shape_attributes.category === "polygon" ||
            shape_attributes.category === "polyline"
          ) {
            regionData.label["data"] = [];
            for (let i = 0; i < shape_attributes["all_points_x"].length; i++) {
              regionData.label["data"].push({
                x: shape_attributes.all_points_x[i],
                y: shape_attributes.all_points_y[i],
              });
            }
            dataList.push(regionData);
          } else if (shape_attributes.category === "point") {
            regionData.label["data"] = {
              x: shape_attributes.cx,
              y: shape_attributes.cy,
            };
          }
          dataList.push(regionData);
        }
      }
      submitData.labels = dataList;
      if (!!isComplete) {
        for (const key of Object.keys(action.data)) {
          submitData[key] = action.data[key];
        }
      }

      this.annotatorEvent.onErrorsUpdated$.emit({ error: [] });
      this.projectService.putUserTask(this.project.id, submitData).subscribe(
        (res) => {
          if (!!isComplete) {
            if (action.post_actions.length > 0) {
              for (const act of action.post_actions) {
                if (act === "close_window") {
                  window.close();
                } else {
                  this.event.onProjectSubmit$.emit();
                }
              }
            } else {
              this.event.onProjectSubmit$.emit();
            }
          }
          this.loading = false;
        },
        (err) => {
          if (err.error.non_field_errors) {
            alert(err.error.non_field_errors[0]);
          } else if (err.error.labels) {
            const labelErrors = err.error.labels;
            const errors = [];
            for (let i = 0; i < labelErrors.length; i++) {
              if (!this.isEmpty(labelErrors[i])) {
                errors.push(i);
              }
            }
            this.annotatorEvent.onErrorsUpdated$.emit({ error: errors });
            alert("There is an uncompleted work. Please check it.");
          } else {
            const errors = [];

            for (const key of Object.keys(err.error)) {
              if (typeof(err.error[key]) === "string") {
                errors.push(err.error[key]);
              } else {
                errors.push(err.error[key][0]);
              }
            }

            if (errors[0].classification && errors[0].classification.code) {
              alert(errors[0].classification.code);
            } else {
              alert(errors[0]);
            }
          }

          this.projectService.getUserTask(this.userTask.id).subscribe((res) => {
            this.userTask = res;
            this.updateInspection();
          });
          this.loading = false;
        }
      );
    }
  }

  updateInspection = () => {
    this.comments_label = [];
    this.comments_usertask = [];
    this.comments_project = [];

    if (this.userTask.inspections && this.userTask.inspections.length > 0) {
      const comments = this.userTask.inspections;

      setTimeout(() => {
        this.comments_label = this.getFilteredComments(comments, "label");
        this.comments_usertask = this.getFilteredComments(
          comments,
          "user_task"
        );
        this.comments_project = this.getFilteredComments(comments, "project");
      }, 0);
    }
  };

  isEmpty(obj) {
    if (!!obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  setBrightness(val) {
    const temp = this.brightness + val;
    if (temp > 100) {
      this.brightness = 100;
      return;
    } else if (temp < 0) {
      this.brightness = 0;
    } else {
      this.brightness = temp;
    }
  }

  setZoom(e) {
    this.zoom = e.zoom;
    if (e.zoom_fitvalue) {
      this.zoom_fitvalue = e.zoom_fitvalue;
    }
    if (e.scroll) {
      this.setScroll(e.scroll);
    }
  }

  setScroll(scroll) {
    const area = document.getElementById("displayArea");
    area.scrollTop = Math.floor(scroll.y);
    area.scrollLeft = Math.floor(scroll.x);
    if (area.scrollLeft < scroll.x || area.scrollTop < scroll.y) {
      setTimeout(() => {
        area.scrollTop = Math.floor(scroll.y);
        area.scrollLeft = Math.floor(scroll.x);
      }, 10);
    }
    this.currentScroll = scroll;
  }

  updateScroll(data) {
    const imageContainer = document.getElementById("displayArea");
    imageContainer.scrollLeft = imageContainer.scrollLeft - data.x;
    imageContainer.scrollTop = imageContainer.scrollTop - data.y;
  }

  onClickThumbnail(id, index, last) {
    this.projectData = null;
    this.projectService.getUserTask(id).subscribe((res) => {
      this.project = res;
      this.reloadTask();
      if (last && this.next_thumbnails != null) {
        this.getThumbnails(this.next_thumbnails);
      } else if (index === 0 && this.previous_thumbnails != null) {
        this.getThumbnails(this.previous_thumbnails);
      } else {
        this.setThumbnailListScroll(index + 1);
      }
    });
  }

  getThumbnails(url) {
    if (url) {
      this.projectService
        .getByUrl(url)
        .pipe(
          takeUntil(this.unsubscribe),
          tap((res) => {
            const temp: any = res;
            this.thumbnails = temp.results;
            this.next_thumbnails = temp.next;
            this.previous_thumbnails = temp.previous;
          }),
          switchMap((res: any) => {
            return this.projectService.getByUrl(res.previous);
          })
        )
        .subscribe((res: any) => {
          if (res.results) {
            this.thumbnails = res.results.concat(this.thumbnails);
            const length = res.results.length;
            this.setThumbnailListScroll(length);
          }
        });
    }
  }

  setThumbnailListScroll(index) {
    this.thumbnailList.nativeElement.scrollTo({
      left: index * 150 - window.innerWidth / 2 - 75,
    });
    setTimeout(() => {
      this.thumbnailList.nativeElement.scrollTo({
        left: index * 150 - window.innerWidth / 2 - 75,
      });
    }, 50);
  }

  getFilteredComments(comments, name) {
    return comments.filter((comment) => comment.scope === name);
  }

  findObjectIndex(arr, id) {
    return arr.findIndex((element) => element.id === id);
  }

  requestData(id, val) {
    let shape = null;
    let is_convert = false;
    if (val.post_participant && val.post_participant === 25) {
      const currentImageId = this.annotatorService.currentImageId;
      const metadata = this.annotatorService.metadata[currentImageId];
      const regions = metadata.regions;
      if (this.selectedRegionIndex >= 0) {
        shape = regions[this.selectedRegionIndex].shape_attributes;
        is_convert = true;
        if (shape.category !== "rect") {
          alert("선택된 객체가 박스가 아닙니다.");
          return;
        }
      } else {
        alert("박스를 선택해주세요.");
        return;
      }
    }
    this.userTaskService.requestPostProcessor(id, val).subscribe((res: any) => {
      if (is_convert) {
        if (res && res.data) {
          this.convertStaticdataToSkeleton(shape, res.data);
        }
      } else {
        if (res) {
          this.projectData = this.convertRawdataToAnnotationData(res);
          this.project.data = this.projectData;
          this.annotatorEvent.onDrawCanvasUpdate$.emit(this.projectData);
        }
      }
    });
  }

  convertStaticdataToSkeleton(shape, data) {
    const box = {
      label: {
        data: {
          x: shape.x,
          y: shape.y,
          width: shape.width,
          height: shape.height,
        },
        category: "rect",
      },
      classification: {
        code: "person",
        attributes: [],
      },
    };
    const temp = box.label.data;
    for (const point of data) {
      const init_x = point.label.data.x;
      const init_y = point.label.data.y;
      const res_x = init_x * temp.width + temp.x;
      const res_y = init_y * temp.height + temp.y;
      point.id = null;
      point.label.data.x = Math.floor(res_x);
      point.label.data.y = Math.floor(res_y);
    }
    const arr = data;
    arr.unshift(box);
    this.projectData = this.convertRawdataToAnnotationData(arr);
    this.project.data = this.projectData;
    this.annotatorEvent.onDrawCanvasUpdate$.emit(this.projectData);
  }
}
