import { Injectable } from "@angular/core";
import { VariationAttribute } from "../../model/variation-attribute";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class AttributeControlService {
  constructor() {}

  toFormGroup(attributes: VariationAttribute[], values?: any) {
    const group: any = {};
    if (!values) {
      attributes.forEach((attribute) => {
        let default_value = "";
        attribute.options.forEach((option) => {
          if (!!option.is_default) {
            default_value = option.value;
          }
        });
        group[attribute.code] = attribute.required
          ? new FormControl(
              attribute.value || default_value || "",
              Validators.required
            )
          : new FormControl(attribute.value || default_value || "");
      });
    } else {
      attributes.forEach((attribute) => {
        let default_value = "";
        attribute.options.forEach((option) => {
          if (!!option.is_default) {
            default_value = option.value;
          }
        });
        group[attribute.code] = attribute.required
          ? new FormControl(
              values[attribute.code] || attribute.value || default_value,
              Validators.required
            )
          : new FormControl(
              values[attribute.code] || attribute.value || default_value
            );
      });
    }
    return new FormGroup(group);
  }
}
