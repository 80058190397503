import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsertaskService {
  constructor(private http: HttpClient) {}

  getListPaginated(data) {
    return this.http.get(`${environment.apiUrl}user_tasks/`, { params: data });
  }

  getListPaginatedByUrl(url) {
    return this.http.get(url);
  }

  requestPostProcessor(id, data) {
    return this.http.post(
      `${environment.apiUrl}user_tasks/${id}/postprocess/`,
      data
    );
  }
}
