import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { AnnotatorService } from "../../../shared/services/annotator.service";
import { FileMetadata } from "../../../model/metadata";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { map, pairwise, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { EventService } from "../../../core/services/event.service";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";

@Component({
  selector: "app-segmentation-canvas",
  templateUrl: "./segmentation-canvas.component.html",
  styleUrls: ["./segmentation-canvas.component.scss"],
})
export class SegmentationCanvasComponent implements AfterViewInit, OnDestroy {
  @ViewChild("segmentationCanvas") public canvas: ElementRef;
  @ViewChild("segmentationPanel") public segmentationPanel: ElementRef;
  @ViewChild("virtualCanvas") public virtualCanvas: ElementRef;
  @ViewChild("mouseCanvas") public mouseCanvas: ElementRef;
  @ViewChild("emptyCheckCanvas") public emptyCheckCanvas: ElementRef;
  @ViewChild("inspectionCanvas") public inspectionCanvas: ElementRef;
  @ViewChild("srcImage") public srcImage: ElementRef;
  @Input() zoom: number;
  @Input() zoom_fitvalue: number;
  @Input() imgUrl: string;
  @Input() projectData: any;
  @Input() drawMode;
  @Input() brightness: number;
  @Input() variations;
  @Input() isInspection;
  @Input() showImageInspection;
  @Input() canPostpone;
  @Input() isViewMode;
  @Input() globalCompositeOperation;
  @Input() category;
  @Input() specification;

  unsubscribe: Subject<void> = new Subject();
  isCanvasLoad = false;

  public imagePanelLeft = 0;
  public imagePanelTop = 0;
  public panelEl: HTMLElement;
  public canvasEl: HTMLCanvasElement;
  public virtualCanvasEl: HTMLCanvasElement;
  public mouseCanvasEl: HTMLCanvasElement;
  public emptyCheckCanvasEl: HTMLCanvasElement;
  public inspectionCanvasEl: HTMLCanvasElement;
  private cx: CanvasRenderingContext2D;
  private virtualCx: CanvasRenderingContext2D;
  private mouseCx: CanvasRenderingContext2D;
  private emptyCheckCx: CanvasRenderingContext2D;
  private inspectionCheckCx: CanvasRenderingContext2D;
  private imageId;
  private canvasImageData;
  private _rdp_current_image;
  private _rdp_current_image_filename;
  private _rdp_current_image_loaded = false;
  private _rdp_current_image_width;
  private _rdp_current_image_height;
  private _rdp_canvas_width;
  private _rdp_canvas_height;

  private _rdp_canvas_scale = 1.0;

  private _rdp_canvas_undo_stack = [];
  private _rdp_canvas_redo_stack = [];
  private _rdp_current_color_index = 0;
  private polygonPointArr = [];
  private brushRadius = 20;
  private polylineWidth = 1;
  private currentPos;
  private currentPercent = 0;
  private uncoloredPixels = [];

  private clientWidth = 0;
  private clientHeight = 0;

  isShiftKeyDown = false;
  isShiftKeyDownActionStart = false;
  isEmptyCheck = false;
  isCheckOriginalImage = false;
  isCheckLabelImage = false;
  private emptyCheckTimer;

  POLYGON_INDEX = 0;
  BRUSH_INDEX = 1;
  POLYLINE_INDEX = 2;
  FLOODFILL_INDEX = 3;
  currentDrawStyle = this.POLYGON_INDEX;
  virtualCanvasStrokeColor = "#00FF00";
  canvasContainerOpacity = 0.4;

  constructor(
    private cdf: ChangeDetectorRef,
    private event: EventService,
    private annotatorEvent: AnnotatorEventService,
    public annotatorService: AnnotatorService
  ) {
    this.annotatorEvent.onCanvasOpacityUpdated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.setCavnasContainerOpacity(e);
      });
  }

  ngAfterViewInit() {
    this.canvasInit();
    this.cdf.detectChanges();
    this.clearStorage();
  }

  ngOnDestroy(): void {
    this.event.onProjectDestroy$.emit();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public canvasInit() {
    this.panelEl = this.segmentationPanel.nativeElement;
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext("2d");
    this.cx.imageSmoothingEnabled = false;

    this.virtualCanvasEl = this.virtualCanvas.nativeElement;
    this.virtualCx = this.virtualCanvasEl.getContext("2d");
    this.virtualCx.imageSmoothingEnabled = false;

    this.mouseCanvasEl = this.mouseCanvas.nativeElement;
    this.mouseCx = this.mouseCanvasEl.getContext("2d");
    this.mouseCx.imageSmoothingEnabled = false;

    this.emptyCheckCanvasEl = this.emptyCheckCanvas.nativeElement;
    this.emptyCheckCx = this.emptyCheckCanvasEl.getContext("2d");

    this.inspectionCanvasEl = this.inspectionCanvas.nativeElement;
    this.inspectionCheckCx = this.inspectionCanvasEl.getContext("2d");
    if (
      this.isInspection ||
      (this.projectData && this.projectData.image_inspection)
    ) {
      if (this.isInspection) {
        this.currentDrawStyle = this.BRUSH_INDEX;
      }
    }

    this.captureEvents(
      this.segmentationPanel,
      this.canvasEl,
      this.virtualCanvasEl,
      this.inspectionCanvasEl
    );
    this.projectFileAddUrlInputDone(this.imgUrl);

    for (const variation of this.variations) {
      variation.dexArr = [];
      for (let j = 1; j < 7; j += 2) {
        variation.dexArr.push(parseInt(variation.code.slice(j, j + 2), 16));
      }
    }
  }

  captureEvents(panel, canvasEl, virtualCanvasEl, inspectionCanvasEl) {
    /*** For Mouse ***/
    const panelContextMenu = fromEvent(panel.nativeElement, "contextmenu");
    const panelMousemove = fromEvent(panel.nativeElement, "mousemove").pipe(
      takeUntil(this.unsubscribe)
    );
    const panelMouseout = fromEvent(panel.nativeElement, "mouseout").pipe(
      takeUntil(this.unsubscribe)
    );

    panelContextMenu.subscribe((e: MouseEvent) => {
      e.preventDefault();
    });

    panelMousemove.subscribe((e: MouseEvent) => {
      const pos = this.getCanvasMousePosition(this.panelEl, e);
      this.currentPos = pos;

      if (this.currentDrawStyle === this.BRUSH_INDEX) {
        this.drawMouseGuide(pos);
      }

      const data = {
        position: pos,
        canvas:
          this.currentDrawStyle === this.POLYGON_INDEX ? virtualCanvasEl : null,
      };
      if (!this.isCheckOriginalImage) {
        this.annotatorEvent.onMousemove$.emit(data);
      }
    });

    panelMouseout.subscribe(() => {
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
    });

    const canvasMouseout = fromEvent(canvasEl, "mouseout").pipe(
      takeUntil(this.unsubscribe)
    );
    const canvasMouseup = fromEvent(canvasEl, "mouseup")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        map(() => {
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grab";
          }
        })
      );
    const canvasMousemove = fromEvent(canvasEl, "mousemove").pipe(
      takeUntil(this.unsubscribe)
    );
    const canvasMousedown = fromEvent(canvasEl, "mousedown")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        tap(() => {
          this.canvasImageData = this.cx.getImageData(
            0,
            0,
            this.canvasEl.width,
            this.canvasEl.height
          );
          this.cx.strokeStyle =
            this.variations[this._rdp_current_color_index].code;
          this.cx.fillStyle =
            this.variations[this._rdp_current_color_index].code;
          this.cx.lineWidth = this.brushRadius;
          this.cx.lineCap = "round";
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grabbing";
            this.isShiftKeyDownActionStart = true;
          }
        })
      )
      .pipe(
        switchMap((e) => {
          return canvasMousemove.pipe(
            takeUntil(canvasMouseup),
            takeUntil(canvasMouseout),
            pairwise()
          );
        })
      );

    canvasMousedown.subscribe((res: [MouseEvent, MouseEvent]) => {
      if (!this.isShiftKeyDown) {
        if (!this.isShiftKeyDownActionStart) {
          const prevPos = this.getCanvasMousePosition(this.panelEl, res[0]);
          const currentPos = this.getCanvasMousePosition(this.panelEl, res[1]);
          this.plotLine(prevPos, currentPos, this.cx.lineWidth);
        }
      } else {
        this.dragPanel(res);
      }
    });

    canvasMouseup.subscribe(() => {
      const currentCanvasDataURL = this.canvasEl.toDataURL("image/png");
      this.saveUndoStack(currentCanvasDataURL);
      this.saveCanvasToLocalstorage(currentCanvasDataURL, this.imageId);
      this.clearRedoStack();
      this.isShiftKeyDownActionStart = false;
    });

    /*** Inspection For Mouse ***/
    const inspectionMousemove = fromEvent(inspectionCanvasEl, "mousemove").pipe(
      takeUntil(this.unsubscribe)
    );
    const inspectionMouseup = fromEvent(inspectionCanvasEl, "mouseup").pipe(
      takeUntil(this.unsubscribe)
    );
    const inspectionMouseout = fromEvent(inspectionCanvasEl, "mouseout").pipe(
      takeUntil(this.unsubscribe)
    );
    const inspectionMousedown = fromEvent(inspectionCanvasEl, "mousedown")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        tap(() => {
          this.inspectionCheckCx.strokeStyle =
            this.variations[this._rdp_current_color_index].code;
          this.inspectionCheckCx.fillStyle =
            this.variations[this._rdp_current_color_index].code;
          this.inspectionCheckCx.lineWidth = this.brushRadius * 2;
          this.inspectionCheckCx.lineCap = "round";
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grabbing";
            this.isShiftKeyDownActionStart = true;
          }
        })
      )
      .pipe(
        switchMap((e) => {
          return inspectionMousemove.pipe(
            takeUntil(inspectionMouseup),
            takeUntil(inspectionMouseout),
            pairwise()
          );
        })
      );

    inspectionMousedown.subscribe((res: [MouseEvent, MouseEvent]) => {
      if (this.isInspection) {
        if (!this.isShiftKeyDown) {
          if (!this.isShiftKeyDownActionStart) {
            const prevPos = this.getCanvasMousePosition(this.panelEl, res[0]);
            const currentPos = this.getCanvasMousePosition(
              this.panelEl,
              res[1]
            );

            if (!this.inspectionCheckCx) {
              return;
            }
            if (this.drawMode !== "erase") {
              this.inspectionCheckCx.globalCompositeOperation =
                this.globalCompositeOperation;
            } else {
              this.inspectionCheckCx.globalCompositeOperation =
                "destination-out";
            }
            this.inspectionCheckCx.beginPath();
            if (prevPos) {
              this.inspectionCheckCx.moveTo(prevPos.x, prevPos.y); // from
              this.inspectionCheckCx.lineTo(currentPos.x, currentPos.y);
              this.inspectionCheckCx.stroke();
            }
          }
        } else {
          this.dragPanel(res);
        }
      }
    });

    inspectionMouseup.subscribe(() => {
      this.isShiftKeyDownActionStart = false;
    });

    /*** Virtual Canvas ***/
    const virtualCanvasClick = fromEvent(virtualCanvasEl, "click").pipe(
      takeUntil(this.unsubscribe)
    );

    /*** Inspection For Touch ***/
    const inspectionTouchemove = fromEvent(
      inspectionCanvasEl,
      "touchmove"
    ).pipe(takeUntil(this.unsubscribe));
    const inspectionTouchend = fromEvent(inspectionCanvasEl, "touchend").pipe(
      takeUntil(this.unsubscribe)
    );
    const inspectionTouchstart = fromEvent(inspectionCanvasEl, "touchstart")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        tap(() => {
          this.inspectionCheckCx.strokeStyle =
            this.variations[this._rdp_current_color_index].code;
          this.inspectionCheckCx.fillStyle =
            this.variations[this._rdp_current_color_index].code;
          this.inspectionCheckCx.lineWidth = this.brushRadius * 2;
          this.inspectionCheckCx.lineCap = "round";
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grabbing";
            this.isShiftKeyDownActionStart = true;
          }
        })
      )
      .pipe(
        switchMap((e) => {
          return inspectionTouchemove.pipe(
            takeUntil(inspectionTouchend),
            pairwise()
          );
        })
      );

    inspectionTouchstart.subscribe((e: [TouchEvent, TouchEvent]) => {
      if (!this.isShiftKeyDown) {
        if (!this.isShiftKeyDownActionStart) {
          const prevPos = this.getCanvasTouchPosition(this.panelEl, e[0]);
          const currentPos = this.getCanvasTouchPosition(this.panelEl, e[1]);

          if (!this.inspectionCheckCx) {
            return;
          }
          if (this.drawMode !== "erase") {
            this.inspectionCheckCx.globalCompositeOperation =
              this.globalCompositeOperation;
          } else {
            this.inspectionCheckCx.globalCompositeOperation = "destination-out";
          }
          this.inspectionCheckCx.beginPath();
          if (prevPos) {
            this.inspectionCheckCx.moveTo(prevPos.x, prevPos.y); // from
            this.inspectionCheckCx.lineTo(currentPos.x, currentPos.y);
            this.inspectionCheckCx.stroke();
          }
        }
      } else {
        e[0].preventDefault();
        e[0].stopPropagation();
        e[1].preventDefault();
        e[1].stopPropagation();
        this.dragPanelByTouch(e);
      }
    });

    inspectionTouchend.subscribe(() => {
      this.isShiftKeyDownActionStart = false;
      if (!this.isShiftKeyDown) {
        this.mouseCx.clearRect(
          0,
          0,
          this._rdp_canvas_width,
          this._rdp_canvas_height
        );
      }
    });

    /*** For Tablet ***/
    const panelTouchmove = fromEvent(panel.nativeElement, "touchmove").pipe(
      takeUntil(this.unsubscribe)
    );

    panelTouchmove.subscribe((e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const pos = this.getCanvasTouchPosition(this.panelEl, e);
      this.currentPos = pos;
      if (this.currentDrawStyle === this.BRUSH_INDEX) {
        this.drawMouseGuide(pos);
      } else {
        const data = {
          position: pos,
          canvas: virtualCanvasEl,
        };
        this.annotatorEvent.onMousemove$.emit(data);
      }
    });

    const canvasTouchend = fromEvent(canvasEl, "touchend")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        map(() => {
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grab";
          }
        })
      );
    const canvasTouchmove = fromEvent(canvasEl, "touchmove").pipe(
      takeUntil(this.unsubscribe)
    );
    const canvasTouchstart = fromEvent(canvasEl, "touchstart")
      .pipe(takeUntil(this.unsubscribe))
      .pipe(
        tap(() => {
          this.canvasImageData = this.cx.getImageData(
            0,
            0,
            this.canvasEl.width,
            this.canvasEl.height
          );
          this.cx.strokeStyle =
            this.variations[this._rdp_current_color_index].code;
          this.cx.fillStyle =
            this.variations[this._rdp_current_color_index].code;
          this.cx.lineWidth = this.brushRadius;
          this.cx.lineCap = "round";
          if (this.isShiftKeyDown) {
            this.segmentationPanel.nativeElement.style.cursor = "grabbing";
            this.isShiftKeyDownActionStart = true;
          }
        })
      )
      .pipe(
        switchMap((e) => {
          return canvasTouchmove.pipe(takeUntil(canvasTouchend), pairwise());
        })
      );

    canvasTouchstart.subscribe((e: [TouchEvent, TouchEvent]) => {
      if (!this.isShiftKeyDown) {
        const prevPos = this.getCanvasTouchPosition(this.panelEl, e[0]);
        const currentPos = this.getCanvasTouchPosition(this.panelEl, e[1]);
        this.plotLine(prevPos, currentPos, this.cx.lineWidth);
      } else {
        e[0].preventDefault();
        e[0].stopPropagation();
        e[1].preventDefault();
        e[1].stopPropagation();
        this.dragPanelByTouch(e);
      }
    });

    canvasTouchend.subscribe(() => {
      const currentCanvasDataURL = this.canvasEl.toDataURL("image/png");
      this.saveUndoStack(currentCanvasDataURL);
      this.saveCanvasToLocalstorage(currentCanvasDataURL, this.imageId);
      this.clearRedoStack();

      this.isShiftKeyDownActionStart = false;
      if (!this.isShiftKeyDown) {
        this.mouseCx.clearRect(
          0,
          0,
          this._rdp_canvas_width,
          this._rdp_canvas_height
        );
      }
    });

    virtualCanvasClick.subscribe((res: MouseEvent) => {
      const pos = this.getCanvasMousePosition(this.panelEl, res);
      if (
        this.currentDrawStyle === this.POLYGON_INDEX ||
        this.currentDrawStyle === this.POLYLINE_INDEX
      ) {
        this.polygonPointArr.push(pos);
        this.drawPolygonOnVirtualCanvas();
      } else if (this.currentDrawStyle === this.FLOODFILL_INDEX) {
        this.clearCanvas(this.virtualCx);
        this.drawByPaintBucket(pos, this.drawMode === "erase");
      }
    });

    this.event.onKeydownEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.keydownHandler(e);
      });

    this.event.onKeyupEvent$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.keyupHandler(e);
      });

    this.annotatorEvent.onBrushRadiusUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.setBrushRadius(e);
        if (this.currentDrawStyle === this.BRUSH_INDEX) {
          this.drawMouseGuide(this.currentPos);
        }
      });

    this.annotatorEvent.onPolylineWidthUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.setPolylineWidth(e);
        if (this.currentDrawStyle === this.POLYLINE_INDEX) {
          this.drawPolygonOnVirtualCanvas();
        }
      });

    this.annotatorEvent.onPercentUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.currentPercent = e;
      });

    this.annotatorEvent.onPositionUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.setPosition(e);
      });

    this.annotatorEvent.onCheckOriginalImageUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.isCheckOriginalImage = !this.isCheckOriginalImage;
      });

    this.annotatorEvent.onCheckLabelImageUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.isCheckLabelImage = !this.isCheckLabelImage;
      });

    this.annotatorEvent.onDrawmodeUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: string) => {
        if (e === "erase") {
          this.virtualCanvasStrokeColor = "#FF0000";
        } else {
          this.virtualCanvasStrokeColor = "#00FF00";
        }
        this.drawPolygonOnVirtualCanvas();
      });
  }

  private selectDrawStyle(category) {
    this.currentDrawStyle = category;
    if (
      this.currentDrawStyle === this.POLYLINE_INDEX ||
      this.currentDrawStyle === this.POLYGON_INDEX
    ) {
      this.drawPolygonOnVirtualCanvas();
    }
  }

  private projectFileAddUrl(url) {
    if (url !== "") {
      let id = this.getImageId(url, -1);

      if (!this.annotatorService.metadata.hasOwnProperty(id)) {
        id = this.projectAddNewFile(url);
        return id;
      } else {
        this.imageId = id;
        this.annotatorService.currentImageId = id;
        return id;
      }
    }
  }

  private projectAddNewFile(filename, size?) {
    if (typeof size === "undefined") {
      size = -1;
    }

    const id = this.getImageId(filename, size);

    if (!this.annotatorService.metadata.hasOwnProperty(id)) {
      this.annotatorService.metadata[id] = new FileMetadata(filename, size);
      this.imageId = id;
      this.annotatorService.currentImageId = id;
    }
  }

  private projectFileAddUrlInputDone(input) {
    if (input !== "") {
      this.projectFileAddUrl(input);
      this.showImage();
    }
  }

  private getImageId(filename, size) {
    if (typeof size === "undefined") {
      return filename;
    } else {
      return filename + size;
    }
  }

  private showImage() {
    this._rdp_current_image = this.srcImage.nativeElement;

    if (!this._rdp_current_image) {
      return;
    }
    fromEvent(this._rdp_current_image, "load")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this._rdp_current_image.classList.add("visible");
        this._rdp_current_image_filename =
          this.annotatorService.metadata[this.imageId].filename;
        this._rdp_current_image_loaded = true;

        // update the current state of application
        this._rdp_current_image_width = this._rdp_current_image.naturalWidth;
        this._rdp_current_image_height = this._rdp_current_image.naturalHeight;

        const de = document.documentElement;
        this.clientHeight = de.clientHeight;
        const sidebar = document.getElementById("sidebarLabel");
        this.clientWidth = de.scrollWidth - 498 - 16;

        if (sidebar.style.display === "none") {
          this.clientWidth = de.scrollWidth;
        }

        this._rdp_canvas_width = this._rdp_current_image_width;
        this._rdp_canvas_height = this._rdp_current_image_height;

        this.zoom_fitvalue = 1;

        if (this._rdp_canvas_width > this.clientWidth) {
          this.imagePanelTop = 20;
          this.zoom_fitvalue = this.clientWidth / this._rdp_canvas_width;
        }
        if (this._rdp_canvas_height + 172 > this.clientHeight) {
          const temp_zoom = this.clientWidth / (this._rdp_canvas_height + 172);
          if (temp_zoom < this.zoom_fitvalue) {
            this.zoom_fitvalue = temp_zoom;
          }
        } else {
          this.imagePanelTop =
            (this.clientHeight - this._rdp_canvas_height - 172) * 0.5;
        }

        this.annotatorEvent.onZoomUpdate$.emit({
          zoom: this.zoom_fitvalue,
          zoom_fitvalue: this.zoom_fitvalue,
        });
        this._rdp_canvas_width = Math.round(this._rdp_canvas_width);
        this._rdp_canvas_height = Math.round(this._rdp_canvas_height);
        this._rdp_canvas_scale =
          this._rdp_current_image.naturalWidth / this._rdp_canvas_width;

        this.imagePanelLeft = 20;

        this.setAllCanvasSize(this._rdp_canvas_width, this._rdp_canvas_height);
        if (this.projectData && this.projectData.image) {
          this.loadCanvasFromData(
            this._rdp_canvas_width,
            this._rdp_canvas_height,
            this.projectData.image
          );
          if (this.projectData.image_inspection) {
            this.loadCanvasFromData(
              this._rdp_canvas_width,
              this._rdp_canvas_height,
              this.projectData.image_inspection,
              true
            );
          }
        }

        this.saveUndoStack(this.canvasEl.toDataURL("image/png"));
        this.isCanvasLoad = true;
        this.canvasEl.focus();
        this.annotatorEvent.onImageLoadend$.emit(true);
      });
  }

  private setAllCanvasSize(w, h) {
    this.canvasEl.height = h;
    this.canvasEl.width = w;
    this.virtualCanvasEl.width = w;
    this.virtualCanvasEl.height = h;
    this.emptyCheckCanvasEl.width = w;
    this.emptyCheckCanvasEl.height = h;
    this.mouseCanvasEl.width = w;
    this.mouseCanvasEl.height = h;
    this.inspectionCanvasEl.width = w;
    this.inspectionCanvasEl.height = h;

    this.segmentationPanel.nativeElement.style.height = h + "px";
    this.segmentationPanel.nativeElement.style.width = w + "px";
    this.canvasImageData = this.cx.getImageData(
      0,
      0,
      this.canvasEl.width,
      this.canvasEl.height
    );
  }

  public setPosition(data?) {
    if (data) {
      this.imagePanelLeft = data.left;
      this.imagePanelTop = data.top;
    } else {
      const de = document.documentElement;
      const client_height = de.clientHeight;
      const sidebar = document.getElementById("sidebarLabel");
      let image_panel_width = de.scrollWidth - sidebar.clientWidth - 48;

      if (sidebar.style.display === "none") {
        image_panel_width = de.scrollWidth;
      }
      if (this._rdp_canvas_width > image_panel_width) {
        this.imagePanelTop = 20;
      }
      if (this._rdp_canvas_height + 172 <= client_height) {
        this.imagePanelTop =
          (client_height - this._rdp_canvas_height - 172) * 0.5;
      }
      this.imagePanelLeft = 20;
    }
  }

  public saveCanvasToLocalstorage(saveUrl, imageId) {
    localStorage.setItem(imageId, saveUrl);
  }

  public loadCanvasFromLocalstorage(w, h, imageId) {
    const loadUrl = localStorage.getItem(imageId);
    if (loadUrl) {
      const temp_img = new Image(w, h);
      temp_img.src = loadUrl;

      fromEvent(temp_img, "load").subscribe((e) => {
        this.cx.imageSmoothingEnabled = false;
        this.virtualCx.imageSmoothingEnabled = false;
        this.mouseCx.imageSmoothingEnabled = false;
        this.cx.clearRect(0, 0, w, h);
        this.cx.drawImage(temp_img, 0, 0, w, h);
        this.calculatePercent();
      });
    }
  }

  public loadCanvasFromData(w, h, data, hasInspection?) {
    if (data) {
      const temp_img = new Image(w, h);
      temp_img.src = data;

      fromEvent(temp_img, "load").subscribe((e) => {
        this.cx.imageSmoothingEnabled = false;
        this.virtualCx.imageSmoothingEnabled = false;
        this.mouseCx.imageSmoothingEnabled = false;
        if (!hasInspection) {
          this.cx.clearRect(0, 0, w, h);
          this.cx.drawImage(temp_img, 0, 0, w, h);
          this.calculatePercent();
          this._rdp_canvas_undo_stack.pop();
          this.saveUndoStack(this.canvasEl.toDataURL("image/png"));
        } else {
          this.inspectionCheckCx.clearRect(0, 0, w, h);
          this.inspectionCheckCx.drawImage(temp_img, 0, 0, w, h);
        }
      });
    }
  }

  public removeCanvasFromLocalStorage(imageId) {
    localStorage.removeItem(imageId);
  }

  public saveUndoStack(item) {
    this._rdp_canvas_undo_stack.push(item);
    this.calculatePercent();
    if (this._rdp_canvas_undo_stack.length > 20) {
      this._rdp_canvas_undo_stack.shift();
    }
  }

  public saveRedoStack(item) {
    this._rdp_canvas_redo_stack.push(item);
    if (this._rdp_canvas_redo_stack.length > 20) {
      this._rdp_canvas_redo_stack.shift();
    }
  }

  public clearRedoStack() {
    this._rdp_canvas_redo_stack = [];
  }

  public undo() {
    if (this._rdp_canvas_undo_stack.length > 1) {
      const _current_canvas = this._rdp_canvas_undo_stack.pop();
      const _history_canvas =
        this._rdp_canvas_undo_stack[this._rdp_canvas_undo_stack.length - 1];
      this.saveRedoStack(_current_canvas);
      this.saveCanvasToLocalstorage(_history_canvas, this.imageId);
      this.loadCanvasFromLocalstorage(
        this.canvasEl.width,
        this.canvasEl.height,
        this.imageId
      );
    }
  }

  public redo() {
    if (this._rdp_canvas_redo_stack.length > 0) {
      const _temp_url = this._rdp_canvas_redo_stack.pop();
      this.saveCanvasToLocalstorage(_temp_url, this.imageId);
      this._rdp_canvas_undo_stack.push(_temp_url);
      this.loadCanvasFromLocalstorage(
        this.canvasEl.width,
        this.canvasEl.height,
        this.imageId
      );
    }
  }

  public updateCurrentColor(index) {
    this._rdp_current_color_index = index;
  }

  public removeColor(index) {
    const currentColorHex = this.variations[index].code;
    const colorDexArr = [];

    for (let j = 1; j < 7; j += 2) {
      colorDexArr.push(parseInt(currentColorHex.slice(j, j + 2), 16));
    }
    this.canvasImageData = this.cx.getImageData(
      0,
      0,
      this.canvasEl.width,
      this.canvasEl.height
    );
    for (let i = 0; i < this.canvasImageData.data.length; i += 4) {
      if (
        Math.abs(colorDexArr[0] - this.canvasImageData.data[i]) < 15 &&
        Math.abs(colorDexArr[1] - this.canvasImageData.data[i + 1]) < 15 &&
        Math.abs(colorDexArr[2] - this.canvasImageData.data[i + 2]) < 15
      ) {
        this.canvasImageData.data[i] = 0;
        this.canvasImageData.data[i + 1] = 0;
        this.canvasImageData.data[i + 2] = 0;
        this.canvasImageData.data[i + 3] = 0;
      }
    }

    this.cx.putImageData(this.canvasImageData, 0, 0);

    this.saveUndoStack(this.canvasEl.toDataURL("image/png"));
    this.clearRedoStack();
  }

  private plotLine(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number },
    pure_radius
  ) {
    const radius = Math.round(pure_radius);
    if (
      Math.abs(currentPos.y - prevPos.y) < Math.abs(currentPos.x - prevPos.x)
    ) {
      if (prevPos.x > currentPos.x) {
        this.plotLineLow(
          currentPos.x,
          currentPos.y,
          prevPos.x,
          prevPos.y,
          radius
        );
      } else {
        this.plotLineLow(
          prevPos.x,
          prevPos.y,
          currentPos.x,
          currentPos.y,
          radius
        );
      }
    } else {
      if (prevPos.y > currentPos.y) {
        this.plotLineHigh(
          currentPos.x,
          currentPos.y,
          prevPos.x,
          prevPos.y,
          radius
        );
      } else {
        this.plotLineHigh(
          prevPos.x,
          prevPos.y,
          currentPos.x,
          currentPos.y,
          radius
        );
      }
    }
    this.cx.putImageData(this.canvasImageData, 0, 0);
  }

  private plotLineLow(x0, y0, x1, y1, radius) {
    const dx = x1 - x0;
    let dy = y1 - y0;
    let yi = 1;
    if (dy < 0) {
      yi = -1;
      dy = -dy;
    }
    let D = 2 * dy - dx;
    let y = y0;

    for (let x = x0; x <= x1; x++) {
      this.plotCircle(x, y, radius);
      if (D > 0) {
        y = y + yi;
        D = D - 2 * dx;
      }
      D = D + 2 * dy;
    }
  }

  private plotLineHigh(x0, y0, x1, y1, radius) {
    let dx = x1 - x0;
    const dy = y1 - y0;
    let xi = 1;
    if (dx < 0) {
      xi = -1;
      dx = -dx;
    }
    let D = 2 * dx - dy;
    let x = x0;

    for (let y = y0; y <= y1; y++) {
      this.plotCircle(x, y, radius);
      if (D > 0) {
        x = x + xi;
        D = D - 2 * dy;
      }
      D = D + 2 * dx;
    }
  }

  private plotCircle(x, y, radius) {
    const currentColorHex = this.variations[this._rdp_current_color_index].code;
    const colorDexArr = [];

    for (let j = 1; j < 7; j += 2) {
      colorDexArr.push(parseInt(currentColorHex.slice(j, j + 2), 16));
    }

    const rowSize = this._rdp_canvas_width;

    let max_x = radius;
    for (let cy = 0; cy <= radius; cy++) {
      for (let cx = max_x; cx >= 0; cx--) {
        if (cx * cx + cy * cy <= radius * radius) {
          this.plotHorizontalLine(x - cx, x + cx, y + cy, rowSize, colorDexArr);
          this.plotHorizontalLine(x - cx, x + cx, y - cy, rowSize, colorDexArr);
          max_x = cx;
          break;
        }
      }
    }
  }

  private plotHorizontalLine(min_x, max_x, y, rowSize, currentColor) {
    const width = this.canvasEl.width;
    for (let i = min_x; i <= max_x; i++) {
      if (i >= 0 && i < width) {
        this.plotPoint(i, y, rowSize, currentColor);
      }
    }
  }

  private plotPoint(x, y, rowSize, currentColor) {
    const pos = y * rowSize + x;
    if (this.drawMode === "draw") {
      if (this.globalCompositeOperation === "destination-over") {
        if (this.canvasImageData.data[pos * 4 + 3] < 255) {
          this.canvasImageData.data[pos * 4] = currentColor[0];
          this.canvasImageData.data[pos * 4 + 1] = currentColor[1];
          this.canvasImageData.data[pos * 4 + 2] = currentColor[2];
          this.canvasImageData.data[pos * 4 + 3] = 255;
        }
      } else {
        this.canvasImageData.data[pos * 4] = currentColor[0];
        this.canvasImageData.data[pos * 4 + 1] = currentColor[1];
        this.canvasImageData.data[pos * 4 + 2] = currentColor[2];
        this.canvasImageData.data[pos * 4 + 3] = 255;
      }
    } else {
      this.canvasImageData.data[pos * 4] = 0;
      this.canvasImageData.data[pos * 4 + 1] = 0;
      this.canvasImageData.data[pos * 4 + 2] = 0;
      this.canvasImageData.data[pos * 4 + 3] = 0;
    }
  }

  private drawPolygonOnVirtualCanvas() {
    const startPos = this.polygonPointArr[0];
    this.virtualCx.strokeStyle = this.virtualCanvasStrokeColor;
    this.virtualCx.clearRect(
      0,
      0,
      this.virtualCanvasEl.width,
      this.virtualCanvasEl.height
    );
    this.virtualCx.fillStyle =
      this.variations[this._rdp_current_color_index].code;
    this.virtualCx.beginPath();
    if (this.polygonPointArr.length > 0) {
      this.virtualCx.rect(startPos.x, startPos.y, 1, 1);
      this.virtualCx.moveTo(startPos.x, startPos.y);
      for (const pos of this.polygonPointArr) {
        this.virtualCx.lineTo(pos.x, pos.y);
      }
      if (this.currentDrawStyle === this.POLYGON_INDEX) {
        this.virtualCx.lineWidth = this.polylineWidth;
        this.virtualCx.closePath();
      } else {
        this.virtualCx.lineWidth = this.polylineWidth;
      }
    }
    this.virtualCx.imageSmoothingEnabled = false;
    this.virtualCx.stroke();
    this.virtualCx.lineWidth = 1;
  }

  private drawPolygonOnCanvas() {
    this.virtualCx.clearRect(
      0,
      0,
      this.virtualCanvasEl.width,
      this.virtualCanvasEl.height
    );
    if (
      this.polygonPointArr.length >= 3 ||
      (this.currentDrawStyle === this.POLYLINE_INDEX &&
        this.polygonPointArr.length >= 2)
    ) {
      const polygon = new Path2D();
      const startPos = this.polygonPointArr[0];

      polygon.moveTo(startPos.x, startPos.y);
      for (const pos of this.polygonPointArr) {
        polygon.lineTo(pos.x, pos.y);
      }

      if (this.currentDrawStyle === this.POLYGON_INDEX) {
        polygon.closePath();
        this.virtualCx.fillStyle =
          this.variations[this._rdp_current_color_index].code;
        this.virtualCx.fill(polygon, "evenodd");
      } else if (this.currentDrawStyle === this.POLYLINE_INDEX) {
        this.virtualCx.strokeStyle =
          this.variations[this._rdp_current_color_index].code;
        this.virtualCx.lineWidth = this.polylineWidth;
        this.virtualCx.stroke();
        this.virtualCx.lineWidth = 1;
      }

      this.removeOtherColor(this._rdp_current_color_index);
      this.cx.imageSmoothingEnabled = false;
      this.mouseCx.imageSmoothingEnabled = false;
      this.virtualCx.imageSmoothingEnabled = false;
      if (this.drawMode !== "erase") {
        this.cx.globalCompositeOperation = this.globalCompositeOperation;
      } else {
        this.cx.globalCompositeOperation = "destination-out";
      }
      this.filterCanvasColor(
        this.variations[this._rdp_current_color_index].code
      );
      this.cx.drawImage(this.virtualCanvasEl, 0, 0);
      this.virtualCx.clearRect(
        0,
        0,
        this.virtualCanvasEl.width,
        this.virtualCanvasEl.height
      );
      this.cx.globalCompositeOperation = this.globalCompositeOperation;
    }
    this.polygonPointArr.length = 0;
    this.saveUndoStack(this.canvasEl.toDataURL("image/png"));
    this.clearRedoStack();
  }

  private removeOtherColor(index) {
    const currentColorHex = this.variations[index].code;
    const colorDexArr = [];
    const virtualCanvasImageData = this.virtualCx.getImageData(
      0,
      0,
      this.virtualCanvasEl.width,
      this.virtualCanvasEl.height
    );
    for (let j = 1; j < 7; j += 2) {
      colorDexArr.push(parseInt(currentColorHex.slice(j, j + 2), 16));
    }

    for (let i = 0; i < virtualCanvasImageData.data.length; i += 4) {
      if (
        Math.abs(colorDexArr[0] - virtualCanvasImageData.data[i]) < 15 &&
        Math.abs(colorDexArr[1] - virtualCanvasImageData.data[i + 1]) < 15 &&
        Math.abs(colorDexArr[2] - virtualCanvasImageData.data[i + 2]) < 15 &&
        virtualCanvasImageData.data[i + 3] !== 255
      ) {
        virtualCanvasImageData.data[i] = colorDexArr[0];
        virtualCanvasImageData.data[i + 1] = colorDexArr[1];
        virtualCanvasImageData.data[i + 2] = colorDexArr[2];
        virtualCanvasImageData.data[i + 3] = 255;
      }
    }

    this.virtualCx.putImageData(virtualCanvasImageData, 0, 0);
  }

  private removeVertex() {
    this.polygonPointArr.pop();
    this.drawPolygonOnVirtualCanvas();
  }

  private drawMouseGuide(pos) {
    if (pos) {
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
      this.mouseCx.beginPath();
      this.mouseCx.strokeStyle = "#ffffff";
      this.mouseCx.imageSmoothingEnabled = false;
      this.mouseCx.arc(pos.x, pos.y, this.brushRadius, 0, 2 * Math.PI);
      this.mouseCx.stroke();
    }
  }

  private keydownHandler(e) {
    if (e.code === "ShiftLeft") {
      this.isShiftKeyDown = true;
      this.segmentationPanel.nativeElement.style.cursor = "grab";
    }
    if (
      e.code === "ArrowLeft" ||
      e.code === "ArrowRight" ||
      e.code === "ArrowUp" ||
      e.code === "ArrowDown"
    ) {
    } else {
      e.preventDefault();
      e.stopPropagation();
      if (
        e.code === "Digit1" ||
        e.code === "Digit2" ||
        e.code === "Digit3" ||
        e.code === "Digit4"
      ) {
        this.selectDrawStyle(Number(e.key) - 1);
      }
      if (e.code === "Enter") {
        this.drawPolygonOnCanvas();
      }
      if (e.code === "Equal") {
        const afterZoom = this.zoom * 1.2;
        const temp = {
          zoom: afterZoom,
          scroll: this.calculateScrollValue(afterZoom),
        };

        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }

      if (e.code === "Minus") {
        const afterZoom = this.zoom * 0.8;
        const temp = {
          zoom: afterZoom,
          scroll: this.calculateScrollValue(afterZoom),
        };

        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }

      if (e.code === "Digit0") {
        const zoom = {
          zoom: this.zoom_fitvalue,
        };
        this.annotatorEvent.onZoomUpdate$.emit(zoom);
      }

      if (e.code === "Backspace") {
        this.removeVertex();
      }

      if (e.code === "Comma") {
        if (e.ctrlKey || e.metaKey) {
          this.annotatorEvent.onCanvasOpacityUpdated$.emit(0.1);
        } else {
          this.annotatorEvent.onBrightnessUpdate$.emit(-5);
        }
      }

      if (e.code === "Period") {
        if (e.ctrlKey || e.metaKey) {
          this.annotatorEvent.onCanvasOpacityUpdated$.emit(-0.1);
        } else {
          this.annotatorEvent.onBrightnessUpdate$.emit(5);
        }
      }

      if (e.code === "KeyF") {
        this.annotatorEvent.onUpdateGlobalCompositeOperation$.emit(
          "source-over"
        );
        // this.globalCompositeOperation = 'source-over';
      }

      if (e.code === "KeyR") {
        // this.globalCompositeOperation = 'destination-over';
        this.annotatorEvent.onUpdateGlobalCompositeOperation$.emit(
          "destination-over"
        );
      }

      if (e.code === "BracketLeft") {
        if (this.currentDrawStyle === this.BRUSH_INDEX) {
          this.annotatorEvent.onBrushRadiusUpdate$.emit(-2);
        } else if (this.currentDrawStyle === this.POLYLINE_INDEX || this.currentDrawStyle === this.POLYGON_INDEX) {
          this.annotatorEvent.onPolylineWidthUpdate$.emit(-1);
        }
      }

      if (e.code === "BracketRight") {
        if (this.currentDrawStyle === this.BRUSH_INDEX) {
          this.annotatorEvent.onBrushRadiusUpdate$.emit(2);
        } else if (this.currentDrawStyle === this.POLYLINE_INDEX || this.currentDrawStyle === this.POLYGON_INDEX) {
          this.annotatorEvent.onPolylineWidthUpdate$.emit(1);
        }
      }

      if (e.code === "KeyI") {
        this.annotatorEvent.onInspectCanvasUpdate$.emit();
      }
      if (e.code === "KeyO") {
        this.annotatorEvent.onCheckOriginalImageUpdate$.emit();
      }
      if (e.code === "KeyU") {
        this.annotatorEvent.onCheckLabelImageUpdate$.emit();
      }

      if (e.code === "KeyD") {
        if (this.drawMode === "erase") {
          this.annotatorEvent.onDrawmodeUpdate$.emit("draw");
        } else {
          this.annotatorEvent.onDrawmodeUpdate$.emit("erase");
        }
      }

      if (this.isCtrlorMetaKeydown(e)) {
        if (e.code === "KeyZ") {
          if (e.shiftKey) {
            this.redo();
          } else {
            this.undo();
          }
        }
        if (e.code === "KeyS") {
          e.preventDefault();
          e.stopPropagation();
          this.submitTask(false);
        }
      }
    }
  }

  private keyupHandler(e) {
    if (e.code === "ShiftLeft") {
      this.isShiftKeyDown = false;
      this.segmentationPanel.nativeElement.style.cursor = "default";
    }
  }

  private setBrushRadius(offset) {
    if (this.brushRadius + offset > 1) {
      this.brushRadius = this.brushRadius + offset;
    } else {
      this.brushRadius = 1;
    }
  }

  private setPolylineWidth(offset) {
    if (this.polylineWidth + offset > 1) {
      this.polylineWidth = this.polylineWidth + offset;
    } else {
      this.polylineWidth = 1;
    }
  }

  private submitTask(isComplete, action?) {
    if (!this.isViewMode && this.isCanvasLoad) {
      this.calculatePercent();
      if (this.currentPercent > 0 || this.canPostpone) {
        const confirmData = action ? action.data : {};

        const data = {
          image: this.canvasEl.toDataURL("image/png"),
        };

        if (this.category === "instance_segmentation") {
          data["instances"] = this.variations;
        }

        if (!!isComplete) {
          if (this.currentPercent < 100) {
            if (this.canPostpone) {
              if (
                this.specification.configurations && this.specification.configurations.incomplete_ok && confirmData["status"] !== "postponed"
              ) {
                alert("The operation did not complete");
                return false;
              }
              for (const key of Object.keys(confirmData)) {
                data[key] = confirmData[key];
              }
              if (
                data["status_reserved"] === "rejected" ||
                data["status"] === "rejected"
              ) {
                data["image_inspection"] =
                  this.inspectionCanvasEl.toDataURL("image/png");
              }
              this.removeCanvasFromLocalStorage(this.imageId);
            } else {
              alert("The operation did not complete");
            }
          } else {
            for (const key of Object.keys(confirmData)) {
              data[key] = confirmData[key];
            }
            if (
              data["status_reserved"] === "rejected" ||
              data["status"] === "rejected"
            ) {
              data["image_inspection"] =
                this.inspectionCanvasEl.toDataURL("image/png");
            }
            this.removeCanvasFromLocalStorage(this.imageId);
          }
        }
        this.annotatorEvent.onSubmit$.emit({
          submitData: data,
          post_actions: action ? action.post_actions : {},
        });
      }
    } else {
      alert("You are not eligible for this action");
    }
  }

  private isCtrlorMetaKeydown(e) {
    return e.ctrlKey || e.metaKey;
  }

  private calculatePercent() {
    this.canvasImageData = this.cx.getImageData(
      0,
      0,
      this.canvasEl.width,
      this.canvasEl.height
    );
    this.uncoloredPixels = [];
    const canvasData = this.canvasImageData.data;
    let percent = 0;
    let count = 0;
    for (let i = 0; i < canvasData.length; i += 4) {
      if (canvasData[i] > 0 || canvasData[i + 1] > 0 || canvasData[i + 2] > 0) {
        count = count + 1;
      } else {
        this.uncoloredPixels.push(i);
      }
    }
    percent =
      Math.floor((1000 * (count * 100)) / (canvasData.length / 4)) / 1000;
    this.annotatorEvent.onPercentUpdate$.emit(percent);
  }

  private filterCanvasColor(color?) {
    const currentColorHex = color ? color : "#000000";
    const colorDexArr = [];
    const filterCx = color ? this.virtualCx : this.cx;
    for (let j = 1; j < 7; j += 2) {
      colorDexArr.push(parseInt(currentColorHex.slice(j, j + 2), 16));
    }

    const canvasData = filterCx.getImageData(
      0,
      0,
      this.canvasEl.width,
      this.canvasEl.height
    );
    for (let i = 0; i < canvasData.data.length; i += 4) {
      if (canvasData.data[i + 3] > 0 && canvasData.data[i + 3] < 255) {
        canvasData.data[i] = colorDexArr[0];
        canvasData.data[i + 1] = colorDexArr[1];
        canvasData.data[i + 2] = colorDexArr[2];
        canvasData.data[i + 3] = color ? 255 : 0;
      } else if (canvasData.data[i + 3] === 255) {
        const r = this.decimalToHexString(canvasData.data[i]);
        const g = this.decimalToHexString(canvasData.data[i + 1]);
        const b = this.decimalToHexString(canvasData.data[i + 2]);
        const rgb = "#" + r + g + b;
        if (!this.compareVariation(rgb)) {
          canvasData.data[i] = colorDexArr[0];
          canvasData.data[i + 1] = colorDexArr[1];
          canvasData.data[i + 2] = colorDexArr[2];
          canvasData.data[i + 3] = color ? 255 : 0;
        }
      }
    }
    filterCx.putImageData(canvasData, 0, 0);
    this.calculatePercent();
    if (!color) {
      const currentCanvasDataURL = this.canvasEl.toDataURL("image/png");
      this.saveUndoStack(currentCanvasDataURL);
      this.saveCanvasToLocalstorage(currentCanvasDataURL, this.imageId);
      this.clearRedoStack();
    }
  }

  private compareVariation(color) {
    if (this.variations) {
      if (color === "#000000") {
        return true;
      } else {
        for (const variation of this.variations) {
          if (variation.code === color) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  private decimalToHexString(number) {
    const temp = number.toString(16);
    return temp.length > 1 ? temp : "0" + temp;
  }

  private dragPanel(res: [MouseEvent, MouseEvent]) {
    const prevPos = {
      x: Math.round(res[0].clientX / this.zoom),
      y: Math.round(res[0].clientY / this.zoom),
    };
    const currentPos = {
      x: Math.round(res[1].clientX / this.zoom),
      y: Math.round(res[1].clientY / this.zoom),
    };

    const data = {
      x: currentPos.x - prevPos.x,
      y: currentPos.y - prevPos.y,
    };
    this.annotatorEvent.onScrollUpdate$.emit(data);
  }

  private dragPanelByTouch(res: [TouchEvent, TouchEvent]) {
    const prevPos = {
      x: Math.round(res[0].touches[0].clientX / this.zoom),
      y: Math.round(res[0].touches[0].clientY / this.zoom),
    };
    const currentPos = {
      x: Math.round(res[1].touches[0].clientX / this.zoom),
      y: Math.round(res[1].touches[0].clientY / this.zoom),
    };

    const data = {
      x: currentPos.x - prevPos.x,
      y: currentPos.y - prevPos.y,
    };
    this.annotatorEvent.onScrollUpdate$.emit(data);
  }

  private getCanvasMousePosition(el, e: MouseEvent) {
    const rect = el.getBoundingClientRect();
    const pos = {
      x: Math.round((e.clientX - rect.left) / this.zoom),
      y: Math.round((e.clientY - rect.top) / this.zoom),
    };
    return pos;
  }

  private getCanvasTouchPosition(el, e: TouchEvent) {
    const rect = el.getBoundingClientRect();
    const pos = {
      x: Math.round((e.touches[0].clientX - rect.left) / this.zoom),
      y: Math.round((e.touches[0].clientY - rect.top) / this.zoom),
    };
    return pos;
  }

  private transformPixelToPosition(i) {
    const temp = Math.floor(i / 4);
    const temp_x = Math.floor(temp % this._rdp_canvas_width);
    const temp_y = Math.floor(temp / this._rdp_canvas_width);

    return { x: temp_x, y: temp_y };
  }

  private checkEmptyPixel() {
    this.isEmptyCheck = !this.isEmptyCheck;
    const rectWidth = this._rdp_canvas_width / 100;
    if (this.isEmptyCheck) {
      this.calculatePercent();
      let i = 0;
      if (this.currentPercent > 80) {
        this.emptyCheckTimer = setInterval(() => {
          i = i + 1;
          if (i % 2 === 0) {
            this.emptyCheckCx.clearRect(
              0,
              0,
              this._rdp_canvas_width,
              this._rdp_canvas_height
            );
          } else {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < this.uncoloredPixels.length; j++) {
              const position = this.transformPixelToPosition(
                this.uncoloredPixels[j]
              );
              this.emptyCheckCx.fillStyle = "#FFFFFF";
              if (position.x + rectWidth / 2 > this._rdp_canvas_width) {
                position.x = Math.floor(position.x - rectWidth / 2);
              }
              if (position.y + rectWidth / 2 > this._rdp_canvas_height) {
                position.y = Math.floor(position.y - rectWidth / 2);
              }
              this.emptyCheckCx.fillRect(
                position.x,
                position.y,
                rectWidth,
                rectWidth
              );
            }
          }
        }, 1000);
      } else {
        this.isEmptyCheck = false;
        alert("진행률이 80% 이상이어야 사용할 수 있습니다.");
      }
    } else {
      clearInterval(this.emptyCheckTimer);
    }
  }

  private drawByPaintBucket(pos, isErase?) {
    const pixelStack = [[pos.x, pos.y]];
    const imageData = this.cx.getImageData(
      0,
      0,
      this._rdp_canvas_width,
      this._rdp_canvas_height
    );
    const pixelNum = (pos.y * this._rdp_canvas_width + pos.x) * 4;
    const selectedColor = [
      imageData.data[pixelNum],
      imageData.data[pixelNum + 1],
      imageData.data[pixelNum + 2],
    ];
    const currentColor = this.variations[this._rdp_current_color_index].dexArr;
    const currentAlpha = imageData.data[pixelNum + 3];

    if (
      (!isErase &&
        (selectedColor[0] !== currentColor[0] ||
          selectedColor[1] !== currentColor[1] ||
          selectedColor[2] !== currentColor[2])) ||
      (isErase && currentAlpha > 0)
    ) {
      while (pixelStack.length) {
        let newPos;
        let x;
        let y;
        let pixelPos;
        let reachLeft;
        let reachRight;
        newPos = pixelStack.pop();
        x = newPos[0];
        y = newPos[1];

        pixelPos = (y * this._rdp_canvas_width + x) * 4;
        while (
          y-- >= 0 &&
          this.matchStartColor(pixelPos, imageData, selectedColor)
        ) {
          pixelPos = pixelPos - this._rdp_canvas_width * 4;
        }
        pixelPos = pixelPos + this._rdp_canvas_width * 4;
        y = y + 1;
        reachLeft = false;
        reachRight = false;

        while (
          y++ < this._rdp_canvas_height - 1 &&
          this.matchStartColor(pixelPos, imageData, selectedColor)
        ) {
          this.colorPixel(pixelPos, imageData, currentColor, isErase);
          if (x > 0) {
            if (this.matchStartColor(pixelPos - 4, imageData, selectedColor)) {
              if (!reachLeft) {
                pixelStack.push([x - 1, y]);
                reachLeft = true;
              }
            } else if (reachLeft) {
              reachLeft = false;
            }
          }

          if (x < this._rdp_canvas_width - 1) {
            if (this.matchStartColor(pixelPos + 4, imageData, selectedColor)) {
              if (!reachRight) {
                pixelStack.push([x + 1, y]);
                reachRight = true;
              }
            } else if (reachRight) {
              reachRight = false;
            }
          }

          pixelPos = pixelPos + this._rdp_canvas_width * 4;
        }
      }

      this.cx.putImageData(imageData, 0, 0);
      this.saveUndoStack(this.canvasEl.toDataURL("image/png"));
      this.clearRedoStack();
    }
  }

  private matchStartColor(pixelPos, imageData, colorArr) {
    const r = imageData.data[pixelPos];
    const g = imageData.data[pixelPos + 1];
    const b = imageData.data[pixelPos + 2];
    return (
      Math.abs(r - colorArr[0]) < 15 &&
      Math.abs(g - colorArr[1]) < 15 &&
      Math.abs(b - colorArr[2]) < 15
    );
  }

  private colorPixel(pixelPos, imageData, colorArr, isErase?) {
    if (!isErase) {
      imageData.data[pixelPos] = colorArr[0];
      imageData.data[pixelPos + 1] = colorArr[1];
      imageData.data[pixelPos + 2] = colorArr[2];
      imageData.data[pixelPos + 3] = 255;
    } else {
      imageData.data[pixelPos] = 0;
      imageData.data[pixelPos + 1] = 0;
      imageData.data[pixelPos + 2] = 0;
      imageData.data[pixelPos + 3] = 0;
    }
  }

  private clearCanvas(cx: CanvasRenderingContext2D) {
    cx.clearRect(0, 0, this._rdp_canvas_width, this._rdp_canvas_height);
  }

  private calculateScrollValue(zoom) {
    if (this.currentPos) {
      const scrollX = this.currentPos.x * zoom - this.clientWidth / 2;
      const scrollY = this.currentPos.y * zoom - this.clientHeight / 2;
      const scroll = {
        x: scrollX > 0 ? scrollX : 0,
        y: scrollY > 0 ? scrollY : 0,
      };
      return scroll;
    } else {
      return null;
    }
  }

  private clearStorage() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== "token" && key !== "user") {
        localStorage.removeItem(key);
      }
    }
  }

  private setCavnasContainerOpacity(val) {
    if (val < 0) {
      if (this.canvasContainerOpacity >= 0.1) {
        this.canvasContainerOpacity += val;
      } else {
        this.canvasContainerOpacity = 0;
      }
    } else {
      if (this.canvasContainerOpacity <= 0.9) {
        this.canvasContainerOpacity += val;
      } else {
        this.canvasContainerOpacity = 1;
      }
    }
  }
}
