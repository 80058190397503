import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { NavComponent } from "./components/nav/nav.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [NavComponent],
  imports: [SharedModule, CommonModule, RouterModule, NgbModule],
  exports: [NavComponent],
})
export class CoreModule {}
