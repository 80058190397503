import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../core/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-token-login",
  templateUrl: "./token-login.component.html",
  styleUrls: ["./token-login.component.scss"],
})
export class TokenLoginComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get("token");
    let nextUrl = this.route.snapshot.queryParamMap.get("next_url");
    this.auth.setAuthorizationToken(token);

    if (!nextUrl) {
      nextUrl = "/projects";
    }
    this.router.navigateByUrl(nextUrl);
  }
}
