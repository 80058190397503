import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { VariationAttribute } from "../../../model/variation-attribute";

@Component({
  selector: "app-dynamic-field",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent implements OnInit {
  @Input() attribute: VariationAttribute;
  @Input() form: FormGroup;
  @Output() valueChanged = new EventEmitter();

  ngOnInit() {
    this.valueChanged.emit();
  }

  onChange() {
    // 다중 선택을 체크박스로 변경하면서 추가된 부분 시작
    for (const prop in this.form.value) {
      if (this.form.value.hasOwnProperty(prop)) {
        const checkboxes = document.querySelectorAll(
          "input[type=checkbox][name='" + prop + "']"
        );

        const arr = [];

        if (checkboxes.length > 0) {
          checkboxes.forEach((elm) => {
            if (elm["checked"]) {
              arr.push(elm["value"]);
            }
          });

          this.form.value[prop] = arr;
        }
      }
    }
    // 다중 선택을 체크박스로 변경하면서 추가된 부분 끝

    this.valueChanged.emit();
  }
}
