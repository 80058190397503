import { Injectable } from "@angular/core";
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getAuthorizationToken();
    const authHeader = {};
    if (authToken) {
      if (req.method === "GET") {
        authHeader["headers"] = req.headers
          .set("Authorization", "Token " + authToken)
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache");
      } else {
        authHeader["headers"] = req.headers.set(
          "Authorization",
          "Token " + authToken
        );
      }
    }

    const authReq = req.clone(authHeader);

    return next.handle(authReq);
  }
}
