import { NgModule } from "@angular/core";
import { ImageAnnotatorPageComponent } from "./pages/image-annotator/image-annotator-page.component";
import { ImageCanvasComponent } from "./components/image-canvas/image-canvas.component";
import { SharedModule } from "../shared/shared.module";
import { SegmentationCanvasComponent } from "./components/segmentation-canvas/segmentation-canvas.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SemanticSegmentationAnnotatorComponent } from "./pages/semantic-segmentation-annotator/semantic-segmentation-annotator.component";
import { InstanceSegmentationAnnotatorComponent } from "./pages/instance-segmentation-annotator/instance-segmentation-annotator.component";
import { MinimapCanvasComponent } from "./components/minimap-canvas/minimap-canvas.component";

@NgModule({
  declarations: [
    ImageAnnotatorPageComponent,
    ImageCanvasComponent,
    SegmentationCanvasComponent,
    SemanticSegmentationAnnotatorComponent,
    InstanceSegmentationAnnotatorComponent,
    MinimapCanvasComponent,
  ],
  imports: [SharedModule, CommonModule, FormsModule],
  exports: [
    ImageAnnotatorPageComponent,
    SemanticSegmentationAnnotatorComponent,
    MinimapCanvasComponent,
    InstanceSegmentationAnnotatorComponent,
  ],
})
export class ImageAnnotatorModule {}
