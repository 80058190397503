import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SegmentationCanvasComponent } from "../../components/segmentation-canvas/segmentation-canvas.component";
import { SidebarLabelComponent } from "../../../shared/components/sidebar-label/sidebar-label.component";
import { Subject } from "rxjs";
import { ProjectService } from "../../../shared/services/project.service";
import { EventService } from "../../../core/services/event.service";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { takeUntil, tap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-instance-segmentation-annotator",
  templateUrl: "./instance-segmentation-annotator.component.html",
  styleUrls: ["./instance-segmentation-annotator.component.scss"],
})
export class InstanceSegmentationAnnotatorComponent
  implements OnInit, OnDestroy
{
  @ViewChild(SegmentationCanvasComponent) segmentationCanvas;
  @ViewChild(SidebarLabelComponent) sidebarLabel;
  @ViewChild("imageCanvasContainer") imageCanvasContainer;
  unsubscribe: Subject<void> = new Subject();
  category = "instance_segmentation";
  mode = "annotation";
  drawMode = "draw";
  globalCompositeOperation = "source-over";
  brightness = 100;
  zoom = 1;
  zoom_fitvalue = 1;
  loading = false;
  isLoaded = false;
  currentScroll;
  showImageInspection = false;

  @Input() isInspection = false;
  @Input() isViewMode = false;
  @Input() project: any;
  @Input() userTask;
  @Input() canPostpone = false;
  @Input() actions;

  constructor(
    private projectService: ProjectService,
    private event: EventService,
    public modal: NgbModal,
    private annotatorEvent: AnnotatorEventService,
    private cdf: ChangeDetectorRef
  ) {
    this.captureEvents();
  }

  ngOnInit(): void {
    if (!!this.project) {
      this.isLoaded = true;
      if (this.isInspection) {
        this.toggleInspection(true);
        this.event.onInspectionUpdate$.emit(this.project.user);
      }
      this.cdf.detectChanges();
      this.event.onProjectUpdate$.emit();
    }
  }

  captureEvents() {
    this.annotatorEvent.onInspectionCreate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.userTask.inspections.unshift(e);
      });
    this.annotatorEvent.onZoomUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.setZoom(e);
      });
    this.annotatorEvent.onDrawmodeUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: string) => {
        this.drawMode = e;
      });
    this.annotatorEvent.onUpdateGlobalCompositeOperation$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.globalCompositeOperation = e;
      });
    this.annotatorEvent.onBrightnessUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.setBrightness(e);
      });

    this.annotatorEvent.onScrollUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.updateScroll(e);
      });
    this.annotatorEvent.onSubmit$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.onSubmit(e);
      });
    this.event.onSelectedItemChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ rid }) => {
        this.segmentationCanvas.updateCurrentColor(rid);
      });
    this.annotatorEvent.onInspectCanvasUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        if (
          this.isInspection ||
          (this.project.data && this.project.data.image_inspection)
        ) {
          if (!this.showImageInspection === false) {
            if (confirm("검수자 모드를 종료합니다. 괜찮습니까?")) {
              this.showImageInspection = !this.showImageInspection;
            }
          } else {
            if (e) {
              this.showImageInspection = e.value;
            } else {
              this.showImageInspection = !this.showImageInspection;
            }
          }
        } else {
          this.showImageInspection = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.event.onProjectDestroy$.emit();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  removeColor($event) {
    this.segmentationCanvas.removeColor($event);
  }

  setZoom(e) {
    this.zoom = e.zoom;
    if (e.zoom_fitvalue) {
      this.zoom_fitvalue = e.zoom_fitvalue;
    }
    if (e.scroll) {
      this.setScroll(e.scroll);
    }
  }

  setScroll(scroll) {
    const area = document.getElementById("displayArea");
    area.scrollTop = Math.floor(scroll.y);
    area.scrollLeft = Math.floor(scroll.x);
    if (area.scrollLeft < scroll.x || area.scrollTop < scroll.y) {
      setTimeout(() => {
        area.scrollTop = Math.floor(scroll.y);
        area.scrollLeft = Math.floor(scroll.x);
      }, 10);
    }
    this.currentScroll = scroll;
  }

  zoomIn() {
    const val = { zoom: this.zoom * 1.2 };
    this.setZoom(val);
  }

  zoomOut() {
    const val = { zoom: this.zoom * 0.8 };
    this.setZoom(val);
  }

  updateScroll(data) {
    const imageContainer = document.getElementById("displayArea");
    imageContainer.scrollLeft = imageContainer.scrollLeft - data.x;
    imageContainer.scrollTop = imageContainer.scrollTop - data.y;
  }

  reset() {
    this.setZoom({ zoom: this.zoom_fitvalue });
    this.annotatorEvent.onPositionUpdate$.emit();
  }

  setBrightness(val) {
    const temp = this.brightness + val;
    if (temp > 100) {
      this.brightness = 100;
      return;
    } else if (temp < 0) {
      this.brightness = 0;
    } else {
      this.brightness = temp;
    }
  }

  toggleDrawMode() {
    this.annotatorEvent.onDrawmodeUpdate$.emit(
      this.drawMode === "draw" ? "erase" : "draw"
    );
  }

  toggleInspection(val?) {
    if (val) {
      this.annotatorEvent.onInspectCanvasUpdate$.emit({ value: val });
    } else {
      this.annotatorEvent.onInspectCanvasUpdate$.emit();
    }
  }

  setGlobalCompositeOperation(category) {
    this.annotatorEvent.onUpdateGlobalCompositeOperation$.emit(category);
  }

  onSubmit(data) {
    if (!this.isViewMode) {
      this.loading = true;

      this.projectService
        .putUserTask(this.project.id, data.submitData)
        .pipe(
          tap(
            () => {
              this.loading = false;
              for (const action of data.post_actions) {
                if (action === "load_more") {
                  this.event.onProjectSubmit$.emit();
                } else if (action === "close_window") {
                  window.close();
                }
              }
            },
            (error) => {
              if (
                error.error.non_field_errors &&
                error.error.non_field_errors.length > 0
              ) {
                alert(error.error.non_field_errors[0]);
              } else {
                alert("Failed to save or submit the task. Please re-try");
              }

              this.loading = false;
            }
          )
        )
        .subscribe(
          (res) => {
            this.loading = false;
          },
          (err) => {
            this.loading = false;
          }
        );
    } else {
      alert("You are not eligible for this action");
    }
  }

  onActionClicked(e) {
    const action: any = e;
    const isCompleted = !!action.data.status || !!action.data.status_reserved;
    if (!e.message_confirm || confirm(action.message_confirm)) {
      this.segmentationCanvas.submitTask(isCompleted, action);
    }
  }

  openLink(url) {
    window.open(url, "_blank");
  }
}
