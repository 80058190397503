import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AnnotatorEventService {
  public onCreateRegion$ = new EventEmitter();
  public onPlayPause$ = new EventEmitter();
  public onPlayRegion$ = new EventEmitter();
  public onStop$ = new EventEmitter();
  public onClearUndefinedRegion$ = new EventEmitter();
  public onZoomUpdate$ = new EventEmitter();
  public onSubmit$ = new EventEmitter();
  public onPlaySplitAudio$ = new EventEmitter();
  public onInputFocus$ = new EventEmitter();
  public onTextToHtml$ = new EventEmitter();
  public onUpdateRegion$ = new EventEmitter();
  public onFormValueChange$ = new EventEmitter();
  public onModalOpen$ = new EventEmitter();
  public onWordSubmit$ = new EventEmitter();
  public onDrawmodeUpdate$ = new EventEmitter();
  public onBrightnessUpdate$ = new EventEmitter();
  public onBrushRadiusUpdate$ = new EventEmitter();
  public onPercentUpdate$ = new EventEmitter();
  public onPositionUpdate$ = new EventEmitter();
  public onScrollUpdate$ = new EventEmitter();
  public onMousemove$ = new EventEmitter();
  public onInspectCanvasUpdate$ = new EventEmitter();
  public onCheckOriginalImageUpdate$ = new EventEmitter();
  public onPronunciationUpdate$ = new EventEmitter();
  public onInspectionCreate$ = new EventEmitter();
  public onUserTaskRejected$ = new EventEmitter();
  public onPolylineWidthUpdate$ = new EventEmitter();
  public onUpdateGlobalCompositeOperation$ = new EventEmitter();
  public onNumericKeyDown$ = new EventEmitter();
  public onSelectBoundingBox$ = new EventEmitter();
  public onCheckLabelImageUpdate$ = new EventEmitter();
  public onImageLoadend$ = new EventEmitter();
  public onPolygonModeChanged$ = new EventEmitter();
  public onRegionVariationIndexChanged$ = new EventEmitter();
  public onErrorsUpdated$ = new EventEmitter();
  public onCanvasOpacityUpdated$ = new EventEmitter();
  public onBoxUpdated$ = new EventEmitter();
  public onFilterUpdated$ = new EventEmitter();
  public onZoomReset$ = new EventEmitter();
  public onCheckPatternChanged$ = new EventEmitter();
  public onDataRequest$ = new EventEmitter();
  public onDrawCanvasUpdate$ = new EventEmitter();
  public onLockStatusChanged$ = new EventEmitter();

  constructor() {}
}
