import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  getList(link?) {
    return link
      ? this.http.get(link)
      : this.http.get(environment.apiUrl + "projects/");
  }

  get(id) {
    return this.http.get(environment.apiUrl + "projects/" + id + "/");
  }

  getByUrl(link) {
    return link ? this.http.get(link) : of({});
  }

  participate(id) {
    return this.http.get(
      environment.apiUrl + "projects/" + id + "/participate/"
    );
  }

  getUserTask(id) {
    return this.http.get(environment.apiUrl + "user_tasks/" + id + "/");
  }

  getUserTasksRelated(id) {
    return this.http.get(
      environment.apiUrl + "user_tasks/?user_task_standard=" + id
    );
  }

  putUserTask(id, data) {
    return this.http.put(environment.apiUrl + "user_tasks/" + id + "/", data);
  }

  create(id, data) {
    const url = environment.apiUrl + "project/" + id + "/";

    return this.http.post(url, data);
  }
}
