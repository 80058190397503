import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AnnotatorService {
  currentImageId = "";
  selectedRegionId = -1;
  previousImageId = "";
  attributes = {
    region: {},
    file: {},
  };
  metadata = {};
  projectSettings = {
    audio: {},
    image: {},
  };

  constructor() {}

  public init() {
    if (this.currentImageId) {
      this.previousImageId = this.currentImageId;
    }
    this.currentImageId = "";
    this.selectedRegionId = -1;
    this.attributes = {
      region: {},
      file: {},
    };
    this.metadata = {};
  }

  getSelectedRegionId() {
    return this.selectedRegionId;
  }

  setSelectedRegionId(id) {
    this.selectedRegionId = id;
  }
}
