import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Output() pageChanged = new EventEmitter<string>();
  @Input() pages: any[];

  constructor() {}

  ngOnInit() {}

  changePage(url) {
    this.pageChanged.emit(url);
  }
}
