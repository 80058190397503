import { Component, OnInit } from "@angular/core";
import { fromEvent } from "rxjs";
import { EventService } from "./core/services/event.service";
import { AnnotatorEventService } from "./shared/services/annotator-event.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  keyEventLock = false;

  constructor(
    private event: EventService,
    private annotatorEvent: AnnotatorEventService
  ) {
    this.annotatorEvent.onInputFocus$.subscribe((res) => {
      this.keyEventLock = res;
    });
  }

  ngOnInit(): void {
    this.captureEvents();
  }

  private captureEvents() {
    const windowKeydownEvent = fromEvent(window, "keydown").subscribe(
      (e: any) => {
        if (!this.keyEventLock) {
          this.event.onKeydownEvent$.emit(e);
        }
      }
    );

    const windowKeyupEvent = fromEvent(window, "keyup").subscribe((e) => {
      if (!this.keyEventLock) {
        this.event.onKeyupEvent$.emit(e);
      }
    });

    const mousewheelEvent = fromEvent(window, "wheel", {
      passive: false,
    }).subscribe((e) => {
      this.event.onMouseWheelEvent$.emit(e);
    });

    const mousedownEvent = fromEvent(window, "mousedown").subscribe((e) => {
      this.event.onMouseDownEvent$.emit(e);
    });
  }
}
