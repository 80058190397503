import { Injectable } from "@angular/core";
import { UserService } from "../../account/services/user.service";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { EventService } from "./event.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private event: EventService,
    private router: Router
  ) {}

  getAuthorizationToken(): string {
    return localStorage.getItem("token");
  }

  setAuthorizationToken(token): void {
    localStorage.setItem("token", token);
    this.userService.updateUser();
  }

  login(form) {
    const data = form.value;
    return this.http.post(environment.apiUrl + "users/login/", data).pipe(
      tap(
        (res) => {
          const result: any = res;
          form.errors = null;
          this.setAuthorizationToken(result.token);
        },
        (res) => {
          form.errors = res.error;
        }
      )
    );
  }

  clear(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.event.onUserUpdate$.emit();
  }

  logout(): void {
    this.clear();
    this.router.navigate(["/"]);
  }

  isAuthenticated(): boolean {
    const user = this.userService.getUser();
    return this.isLoggedIn() && user;
  }

  isLoggedIn(): boolean {
    return !!this.getAuthorizationToken();
  }
}
