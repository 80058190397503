export class FileRegion {
  shape_attributes: any;
  region_attributes: any;
  id: any;
  is_lock: boolean;

  constructor() {
    this.shape_attributes = {}; // region shape attributes
    this.region_attributes = {}; // region attributes
    this.id = null;
    this.is_lock = false;
  }
}
