import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserService } from "../../account/services/user.service";

@Injectable({
  providedIn: "root",
})
export class LandingPageGuardService implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userClient: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.userClient.updateUser$().pipe(
      map((res) => {
        const isLoggedIn = this.auth.isLoggedIn();

        if (isLoggedIn) {
          this.router.navigate(["/projects"]);
        }

        return !isLoggedIn;
      }),
      catchError((err, caught) => {
        const isLoggedIn = this.auth.isLoggedIn();

        if (isLoggedIn) {
          this.router.navigate(["/projects"]);
        }

        return of(!isLoggedIn);
      })
    );
  }
}
