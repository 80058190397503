import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarLabelComponent } from "./components/sidebar-label/sidebar-label.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ObjectToListPipe } from "./pipes/object-to-list.pipe";
import { DynamicFormComponent } from "./components/dynamic-form/dynamic-form.component";
import { DynamicFieldComponent } from "./components/dynamic-field/dynamic-field.component";
import { PronunciationDictionaryDirective } from "./directives/pronunciation-dictionary.directive";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { CommentListComponent } from "./components/comment-list/comment-list.component";
import { CommentDetailComponent } from "./components/comment-detail/comment-detail.component";
import { CommentFormComponent } from "./components/comment-form/comment-form.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    SidebarLabelComponent,
    ObjectToListPipe,
    DynamicFormComponent,
    DynamicFieldComponent,
    PronunciationDictionaryDirective,
    PaginationComponent,
    CommentListComponent,
    CommentDetailComponent,
    CommentFormComponent,
    ToolbarComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
  exports: [
    SidebarLabelComponent,
    ObjectToListPipe,
    DynamicFormComponent,
    DynamicFieldComponent,
    PronunciationDictionaryDirective,
    PaginationComponent,
    CommentListComponent,
    CommentDetailComponent,
    CommentFormComponent,
    ToolbarComponent,
  ],
})
export class SharedModule {}
