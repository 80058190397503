import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { PronunciationsService } from "../../audio-annotator/services/pronunciations.service";
import { AnnotatorEventService } from "../services/annotator-event.service";

@Directive({
  selector: "[appPronunciationDictionary]",
})
export class PronunciationDictionaryDirective implements OnChanges {
  @Input("appPronunciationDictionary")
  public elements: any;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private pronService: PronunciationsService,
    private annotatorEventService: AnnotatorEventService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.elements = this._elementRef.nativeElement.querySelectorAll("span");
      if (this.elements.length > 0) {
        this.elements.forEach((value) => {
          if (value) {
            this._renderer.listen(value, "click", () => {
              event.stopPropagation();
              event.preventDefault();
              this.pronService
                .getPronunciations(value.innerText)
                .subscribe((res) => {
                  this.annotatorEventService.onModalOpen$.emit({
                    word: value.innerText,
                    result: res,
                  });
                });
            });
          }
        });
      }
    }, 100);
  }
}
