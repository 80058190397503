import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { LandingPageGuardService } from "./guards/landing-page-guard.service";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [LandingPageGuardService],
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebsiteRoutingModule {}
