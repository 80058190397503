import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-comment-list",
  templateUrl: "./comment-list.component.html",
  styleUrls: ["./comment-list.component.scss"],
})
export class CommentListComponent implements OnInit {
  @Input() comments;
  @Input() comments_global;
  @Input() isInspection;
  @Input() userTaskId;

  constructor() {}

  ngOnInit() {}
}
