import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EventService {
  public onUserUpdate$ = new EventEmitter();
  public onProjectSubmit$ = new EventEmitter();
  public onProjectUpdate$ = new EventEmitter();
  public onProjectDestroy$ = new EventEmitter();
  public onMouseWheelEvent$ = new EventEmitter();
  public onMouseDownEvent$ = new EventEmitter();
  public onKeydownEvent$ = new EventEmitter();
  public onKeyupEvent$ = new EventEmitter();
  public onInspectionUpdate$ = new EventEmitter();
  public onSelectedItemChanged$ = new EventEmitter();

  constructor() {}
}
