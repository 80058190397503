import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AttributeControlService } from "../../../shared/services/attribute-control.service";
import { AuthService } from "../../../core/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  attributes: any = [
    {
      code: "username",
      name: "Username",
      widget: "username",
      options: [],
    },
    {
      code: "password",
      name: "Password",
      widget: "password",
      options: [],
    },
  ];

  form: FormGroup;
  loading = false;
  @Output() formSubmitEvent = new EventEmitter();

  constructor(
    private acs: AttributeControlService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.acs.toFormGroup(this.attributes);
  }

  onSubmit() {
    this.loading = true;
    this.auth.login(this.form).subscribe(
      (res) => {
        this.loading = false;
        this.router.navigate(["/projects"]);
      },
      (err) => {
        this.loading = false;
      }
    );
  }
}
