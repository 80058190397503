import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProjectListPageComponent } from "./pages/project-list/project-list-page.component";
import { ProjectDetailComponent } from "./pages/project-detail/project-detail.component";
import { AuthGuardService } from "../shared/guards/auth-guard.service";

const routes: Routes = [
  {
    path: "projects",
    component: ProjectListPageComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "projects/:id",
    component: ProjectDetailComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
