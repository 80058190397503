import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AttributeControlService } from "../../services/attribute-control.service";
import { AnnotatorEventService } from "../../services/annotator-event.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() attributes: any;
  @Input() form: FormGroup;
  @Input() values: any;
  @Input() variations;
  @Input() formClass;
  @Input() labelIndex;
  @Input() isReadOnly;
  @Output() formSubmitEvent = new EventEmitter();
  unsubscribe: Subject<void> = new Subject();

  constructor(
    private acs: AttributeControlService,
    private cdf: ChangeDetectorRef,
    public annotatorEvent: AnnotatorEventService
  ) {
    this.annotatorEvent.onRegionVariationIndexChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        const index = res.val;
        this.attributes = this.variations[index].attributes;
        if (this.attributes) {
          this.form = this.acs.toFormGroup(this.attributes, this.values);
        }
      });
  }

  ngOnInit() {
    if (this.attributes) {
      this.form = this.acs.toFormGroup(this.attributes, this.values);
      this.onSubmit();
      this.cdf.detectChanges();
      if (this.isReadOnly) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  onSubmit() {
    this.formSubmitEvent.emit(this.form);
    this.annotatorEvent.onBoxUpdated$.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
