import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { FileMetadata } from "../../../model/metadata";
import { FileRegion } from "../../../model/file-region";
import { AnnotatorService } from "../../../shared/services/annotator.service";
import { EventService } from "../../../core/services/event.service";
import { AnnotatorEventService } from "../../../shared/services/annotator-event.service";
import { takeUntil } from "rxjs/operators";
import { nanoid } from "nanoid";
import * as _ from "lodash";

@Component({
  selector: "app-image-canvas",
  templateUrl: "./image-canvas.component.html",
  styleUrls: ["./image-canvas.component.scss"],
})
export class ImageCanvasComponent implements AfterViewInit, OnDestroy {
  private REGION_SHAPE = {
    RECT: "rect",
    CIRCLE: "circle",
    ELLIPSE: "ellipse",
    POLYGON: "polygon",
    POINT: "point",
    POLYLINE: "polyline",
  };

  @ViewChild("canvas") public canvas: ElementRef;
  @ViewChild("image_panel") public image_panel: ElementRef;
  @ViewChild("image") public imageViewChild: ElementRef;
  @ViewChild("mouseCanvas") public mouseCanvas: ElementRef;
  @Input() variations;
  @Input() imgUrl: string;
  @Input() mode: string;
  @Input() brightness: number;
  @Input() projectData: any;
  @Input() zoom;
  @Input() variationObject;
  @Input() specification;
  @Input() isInspection;

  @Output() changeSelectedRegionEvent = new EventEmitter();
  @Input() _rdp_current_shape = this.REGION_SHAPE.RECT;

  unsubscribe: Subject<void> = new Subject();

  private cx: CanvasRenderingContext2D;

  private RDP_DISPLAY_AREA_CONTENT_NAME = {
    IMAGE: "image_panel",
  };

  public imageEl;

  public split_guide = {
    horizontal_block_length: 0,
    vertical_block_length: 0,
    block_array: [],
    image_width: 0,
    image_height: 0,
  };

  public imagePanelEl: any;
  public canvasEl: HTMLCanvasElement;
  public mouseCanvasEl: HTMLCanvasElement;
  private mouseCx: CanvasRenderingContext2D;

  private GUIDE_W = 0;
  private GUIDE_H = 0;
  private GUIDE_LIST = [];
  private RDP_REGION_EDGE_TOL = 5; // pixel
  private RDP_REGION_CONTROL_POINT_SIZE = 3;
  private RDP_RECT_CENTER_POINT_SIZE = 2;
  private RDP_REGION_POINT_RADIUS = 5;
  private RDP_POLYGON_VERTEX_MATCH_TOL = 5;
  private RDP_REGION_MIN_DIM = 3;
  private RDP_MOUSE_CLICK_TOL = 2;
  private RDP_ELLIPSE_EDGE_TOL = 0.2; // euclidean distance
  private RDP_THETA_TOL = Math.PI / 18; // 10 degrees
  private RDP_POLYGON_RESIZE_VERTEX_OFFSET = 100;
  private RDP_MOUSE_GUIDE_COLOR = "#fff";

  // Region Theme
  private RDP_THEME_REGION_BOUNDARY_WIDTH = 2;
  private RDP_THEME_BOUNDARY_LINE_COLOR = "#71e03b";
  private RDP_THEME_BOUNDARY_FILL_COLOR = "#71e03b";
  private RDP_THEME_SEL_REGION_FILL_COLOR = "#808080";
  private RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR = "#3961e2";
  private RDP_THEME_SEL_REGION_OPACITY = 0.4;
  private RDP_THEME_CONTROL_POINT_COLOR = "#ffffff";
  private RDP_RECT_CENTER_POINT_COLOR = "#71e03b";

  private _rdp_is_region_selected = false;
  private _rdp_is_user_drawing_region = false;
  private _rdp_current_image_loaded = false;
  private _rdp_is_window_resized = false;
  private _rdp_is_user_resizing_region = false;
  private _rdp_is_user_moving_region = false;
  private _rdp_is_user_drawing_polygon = false;
  private _rdp_is_all_region_selected = false;
  private _rdp_is_user_updating_attribute_name = false;
  private _rdp_is_user_updating_attribute_value = false;
  private _rdp_is_canvas_zoomed = false;
  private _rdp_is_region_id_visible = true;
  private _rdp_is_region_boundary_visible = true;
  private _rdp_is_ctrl_pressed = false;
  private _rdp_is_check_pattern = false;

  // region
  // private _rdp_current_shape = this.REGION_SHAPE.RECT;
  // private _rdp_current_shape = this.REGION_SHAPE.POLYGON;
  private _rdp_current_polygon_region_id = -1;
  private _rdp_screen_click_x0 = 0;
  private _rdp_screen_click_y0 = 0;
  private _rdp_click_x0 = 0;
  private _rdp_click_y0 = 0;
  private _rdp_click_x1 = 0;
  private _rdp_click_y1 = 0;
  private _rdp_region_click_x;
  private _rdp_region_click_y;
  private _rdp_region_edge = [-1, -1];
  private _rdp_current_x = 0;
  private _rdp_current_y = 0;
  private _rdp_prev_x = 0;
  private _rdp_prev_y = 0;
  private _rdp_canvas_regions = []; // image regions spec. in canvas space
  // current image
  private _rdp_current_image_filename;
  private _rdp_current_image;
  private _rdp_current_image_width;
  private _rdp_current_image_height;
  private _rdp_image_id = ""; // id={filename+length} of current image

  private clientWidth;
  private clientHeight;
  private _rdp_canvas_width;
  private _rdp_canvas_height;

  // region copy/paste
  private _rdp_region_selected_flag = []; // region select flag for current image
  private _rdp_copied_image_regions = [];

  private isUserMovingPanel = false;
  public image_panel_left = 20;
  public image_panel_top = 20;

  public undoStack = [];
  public redoStack = [];

  public defaultVariation = null;
  public configurations = null;
  // rdp settings
  private _rdp_settings = {
    ui: {
      image: {
        region_label: "__rdp_region_id__",
        region_label_font: "10px Sans",
      },
    },
  };

  public filter = "";
  public showMouseGuide = true;
  public mouseGuideMode = "LINE"; // RECT
  private allowCreate = true;
  private showLabelDisplayName = false;
  private showRectCenterPoint = false;
  private lockStatusList = [];
  private labelReadOnly = false;

  constructor(
    private cdf: ChangeDetectorRef,
    private event: EventService,
    private annotatorEvent: AnnotatorEventService,
    public annotatorService: AnnotatorService
  ) {
    this.annotatorEvent.onPolygonModeChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: string) => {
        const shape = mode.toUpperCase();
        this._rdp_current_shape = this.REGION_SHAPE[shape];
      });

    this.annotatorEvent.onBoxUpdated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.saveUndoStack(
          this.annotatorService.metadata[this._rdp_image_id].regions
        );
        this.clearRedoStack();
      });

    this.annotatorEvent.onFilterUpdated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.filter = e + "";
        this.setDefaultVariation(e);
        this._rdp_redraw_reg_canvas();
      });

    this.annotatorEvent.onCheckPatternChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this._rdp_is_check_pattern = !this._rdp_is_check_pattern;
        this._rdp_redraw_reg_canvas();
      });

    this.annotatorEvent.onDrawCanvasUpdate$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        this.toggle_all_regions_selection(false);
        this.setSelectedRegionId(-1);
        this._rdp_is_region_selected = false;
        this._rdp_is_user_drawing_region = false;
        this._rdp_is_user_resizing_region = false;
        this._rdp_is_user_moving_region = false;
        this._rdp_is_user_drawing_polygon = false;
        this._rdp_is_all_region_selected = false;
        this.loadData(e);
      });

    this.annotatorEvent.onLockStatusChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lockList: [number]) => {
          this.lockStatusList = [...lockList];
      });

    event.onProjectUpdate$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.paramsInit();
      this.canvasInit();
    });
  }

  ngAfterViewInit() {
    if (this.variations.length > 0) {
      let index = 0;
      for (const variation of this.variations) {
        if (!!variation.is_default) {
          this.defaultVariation = {
            variation: variation.code,
            variation_display: variation.name,
            variation_index: index,
          };
          break;
        }
        index += 1;
      }
    }
    this.canvasInit();
    this.cdf.detectChanges();
  }

  loadImage(): void {
    this.imageEl = this.imageViewChild.nativeElement;
    this.split_guide.image_width = this.imageEl.naturalWidth;
    this.split_guide.image_height = this.imageEl.naturalHeight;

    const fontSize = Math.max(this.split_guide.image_width / 100, 10);
    this._rdp_settings = {
      ui: {
        image: {
          region_label: "__rdp_region_id__",
          region_label_font: fontSize + "px Sans",
        },
      },
    };
  }

  ngOnDestroy(): void {
    this.event.onProjectDestroy$.emit();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public paramsInit(mode?) {
    this.annotatorService.currentImageId = "";
    this.annotatorService.selectedRegionId = -1;
    this.annotatorService.attributes = {
      region: {},
      file: {},
    };
    this.annotatorService.metadata = {};
    this._rdp_is_region_selected = false;
    this._rdp_is_user_drawing_region = false;
    this._rdp_current_image_loaded = false;
    this._rdp_is_window_resized = false;
    this._rdp_is_user_resizing_region = false;
    this._rdp_is_user_moving_region = false;
    this._rdp_is_user_drawing_polygon = false;
    this._rdp_is_all_region_selected = false;
    this._rdp_is_user_updating_attribute_name = false;
    this._rdp_is_user_updating_attribute_value = false;
    this._rdp_is_canvas_zoomed = false;
    this._rdp_is_region_id_visible = true;
    this._rdp_is_region_boundary_visible = true;
    this._rdp_is_ctrl_pressed = false;

    // region
    this._rdp_current_shape = mode ? mode : this.REGION_SHAPE.RECT;
    this._rdp_current_polygon_region_id = -1;
    // this.annotatorService.selectedRegionId = -1;
    this._rdp_screen_click_x0 = 0;
    this._rdp_screen_click_y0 = 0;
    this._rdp_click_x0 = 0;
    this._rdp_click_y0 = 0;
    this._rdp_click_x1 = 0;
    this._rdp_click_y1 = 0;
    this._rdp_region_edge = [-1, -1];
    this._rdp_current_x = 0;
    this._rdp_current_y = 0;
    this._rdp_prev_x = 0;
    this._rdp_prev_y = 0;
    this._rdp_canvas_regions = []; // image regions spec. in canvas space

    // current image
    this._rdp_image_id = ""; // id={filename+length} of current image

    // region copy/paste
    this._rdp_region_selected_flag = []; // region select flag for current image
    this._rdp_copied_image_regions = [];

    this.isUserMovingPanel = false;
    this.image_panel_left = 20;
    this.image_panel_top = 20;
    this.configurations = null;
    // rdp settings
    // this._rdp_settings = {
    //   ui: {
    //     image: {
    //       region_label: "__rdp_region_id__",
    //       region_label_font: "2rem Sans",
    //     },
    //   },
    // };

    this.defaultVariation = null;
    if (this.variations.length > 0) {
      for (const variation of this.variations) {
        if (!!variation.is_default) {
          this.defaultVariation = variation;
          break;
        }
      }
    }
  }

  public canvasInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.imagePanelEl = this.image_panel.nativeElement;
    this.cx = this.canvasEl.getContext("2d");

    this.mouseCanvasEl = this.mouseCanvas.nativeElement;
    this.mouseCx = this.mouseCanvasEl.getContext("2d");

    this.canvasEl.width = 1;
    this.canvasEl.height = 1;

    this.cx.lineWidth = 3;
    this.cx.lineCap = "round";
    this.cx.strokeStyle = "#000";

    window.addEventListener("resize", () => {
      this.loadImage();
    });

    if (!!this.specification) {
      if (this.specification.guide_list) {
        this.GUIDE_LIST = this.specification.guide_list;
      } else {
        if (this.specification.guide_w) {
          this.GUIDE_W = this.specification.guide_w;
          this.GUIDE_H = this.specification.guide_h
            ? this.specification.guide_h
            : this.specification.guide_w;
        }
      }
      if (this.specification.configurations) {
        this.configurations = this.specification.configurations;
        if (
          this.isInspection &&
          this.configurations.inspection &&
          this.configurations.inspection.disable_create
        ) {
          this.allowCreate = !this.configurations.inspection.disable_create;
        } else {
          this.allowCreate = true;
        }

        if (this.configurations.boundary_width) {
          this.RDP_THEME_REGION_BOUNDARY_WIDTH =
            this.configurations.boundary_width;
        }

        if (this.configurations.point_radius) {
          this.RDP_REGION_POINT_RADIUS = this.configurations.point_radius;
        }

        if (this.configurations.mouse_guide_color) {
          this.RDP_MOUSE_GUIDE_COLOR = this.configurations.mouse_guide_color;
        }

        if (this.configurations.sel_region_fill_color) {
          this.RDP_THEME_SEL_REGION_FILL_COLOR =
            this.configurations.sel_region_fill_color;
        }

        if (this.configurations.sel_region_rect_center_point_color) {
          this.RDP_RECT_CENTER_POINT_COLOR =
            this.configurations.sel_region_rect_center_point_color;
        }

        if (this.configurations.split_guide_size) {
          const arr = this.configurations.split_guide_size.split("x");
          if (arr.length === 2) {
            this.split_guide.horizontal_block_length = arr[0];
            this.split_guide.vertical_block_length = arr[1];
            this.split_guide.block_array = new Array(
              this.split_guide.horizontal_block_length *
                this.split_guide.vertical_block_length
            );
          }
        }

        if (this.configurations.show_label_display_name) {
          this.showLabelDisplayName = true;
        }

        if (this.configurations.show_rect_center_point) {
          this.showRectCenterPoint = true;
        }

        if (this.configurations.label_read_only) {
          this.labelReadOnly = true;
        }
      }
    }

    this._rdp_clear_reg_canvas();
    this.captureEvents(this.imagePanelEl, this.canvasEl, this.mouseCanvasEl);
    this.project_file_add_url_input_done(this.imgUrl, this.projectData);
  }

  @HostListener("window:resize", ["$event"])
  private onResize(e) {
    this._rdp_update_ui_components();
  }

  public captureEvents(
    panel,
    canvasEl: HTMLCanvasElement,
    mouseCanvasEl: HTMLCanvasElement
  ) {
    // this will capture all mousedown events from the canvas element
    const mousedown = fromEvent(panel, "mousedown").pipe(
      takeUntil(this.unsubscribe)
    );
    const mousemove = fromEvent(panel, "mousemove").pipe(
      takeUntil(this.unsubscribe)
    );
    const mouseover = fromEvent(panel, "mouseover").pipe(
      takeUntil(this.unsubscribe)
    );
    const mouseup = fromEvent(panel, "mouseup").pipe(
      takeUntil(this.unsubscribe)
    );
    const mouseout = fromEvent(panel, "mouseout").pipe(
      takeUntil(this.unsubscribe)
    );

    const mouseGuideMove = fromEvent(mouseCanvasEl, "mousemove").pipe(
      takeUntil(this.unsubscribe)
    );

    const mouseGuideOut = fromEvent(mouseCanvasEl, "mouseout").pipe(
      takeUntil(this.unsubscribe)
    );

    const windowKeydown = fromEvent(window, "keydown")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.windowKeydownHandler(e);
      });

    const keydown = fromEvent(panel, "keydown")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.canvasKeydownHandler(e);
      });

    const windowKeyup = fromEvent(window, "keyup")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.windowKeyupHandler(e);
      });

    const keyup = fromEvent(panel, "keyup")
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => {
        this.canvasKeyupHandler(e);
      });

    const mousedownSubscription = mousedown.subscribe((e) => {
      this.canvasMousedownHandler(e);
    });
    const mousemoveSubscription = mousemove.subscribe((e) => {
      this.canvasMousemoveHandler(e);
    });
    const mouseoverSubscription = mouseover.subscribe((e) => {
      this.canvasMouseoverHandler(e);
    });
    const mouseupSubscription = mouseup.subscribe((e) => {
      this.canvasMouseupHandler(e);
    });

    mouseGuideMove.subscribe((e: any) => {
      if (this.showMouseGuide) {
        const pos = this.getCanvasMousePosition(this.mouseCanvasEl, e);
        this.drawMouseGuide(pos, this.mouseGuideMode);
      }
    });

    mouseGuideOut.subscribe((e: any) => {
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
    });

    mouseout.subscribe((e) => {
      this.annotatorEvent.onScrollUpdate$.emit(null);
    });
  }

  private canvasMouseoverHandler(e) {
    this._rdp_redraw_reg_canvas();
    // this.canvasEl.focus();
  }

  // user clicks on the canvas
  private canvasMousedownHandler(e) {
    e.stopPropagation();
    this._rdp_click_x0 = e.offsetX;
    this._rdp_click_y0 = e.offsetY;
    this._rdp_screen_click_x0 = e.screenX / this.zoom;
    this._rdp_screen_click_y0 = e.screenY / this.zoom;

    if (this.mode === "annotation") {
      this._rdp_region_edge = this.is_on_region_corner(
        this._rdp_click_x0,
        this._rdp_click_y0
      );
      const region_id = this.is_inside_region(
        this._rdp_click_x0,
        this._rdp_click_y0
      );
      this.annotatorEvent.onSelectBoundingBox$.emit(region_id);
      if (this._rdp_is_region_selected) {
        // configurations 설정
        if (this.labelReadOnly) {
          return;
        }
        // check if user clicked on the region boundary
        if (this._rdp_region_edge[1] > 0) {
          if (!this._rdp_is_user_resizing_region) {
            if (
              this._rdp_region_edge[0] !==
              this.annotatorService.selectedRegionId
            ) {
              this.setSelectedRegionId(this._rdp_region_edge[0]);
            }
            // resize region
            this._rdp_is_user_resizing_region = true;
          }
        } else {
          const yes = this.is_inside_this_region(
            this._rdp_click_x0,
            this._rdp_click_y0,
            this.annotatorService.selectedRegionId
          );
          if (yes) {
            if (!this._rdp_is_user_moving_region) {
              this._rdp_is_user_moving_region = true;
              this._rdp_region_click_x = this._rdp_click_x0;
              this._rdp_region_click_y = this._rdp_click_y0;
            }
          }
          if (region_id === -1) {
            // mousedown on outside any region
            this._rdp_is_user_drawing_region = true;
            // unselect all regions
            this._rdp_is_region_selected = false;
            this.setSelectedRegionId(-1);
            this.toggle_all_regions_selection(false);
          }
        }
      } else {
        if (region_id === -1) {
          // mousedown outside a region
          if (
            this._rdp_current_shape !== this.REGION_SHAPE.POLYGON &&
            this._rdp_current_shape !== this.REGION_SHAPE.POLYLINE &&
            this._rdp_current_shape !== this.REGION_SHAPE.POINT
          ) {
            // this is a bounding box drawing event
            this._rdp_is_user_drawing_region = true;
          }
        } else {
          // mousedown inside a region
          // this could lead to (1) region selection or (2) region drawing
          this._rdp_is_user_drawing_region = true;
        }
      }
    } else if (this.mode === "move") {
      this.isUserMovingPanel = true;
    }
  }

  private canvasMouseupHandler(e) {
    e.stopPropagation();

    // If shift key pressed, not use from event offset value
    if (!e.shiftKey) {
      this._rdp_click_x1 = e.offsetX;
      this._rdp_click_y1 = e.offsetY;
    }

    const click_dx = Math.abs(this._rdp_click_x1 - this._rdp_click_x0);
    const click_dy = Math.abs(this._rdp_click_y1 - this._rdp_click_y0);

    // indicates that user has finished moving a region
    if (this.mode === "annotation") {
      if (this._rdp_is_user_moving_region) {
        this._rdp_is_user_moving_region = false;
        this.canvasEl.style.cursor = "default";

        const move_x = Math.round(
          this._rdp_click_x1 - this._rdp_region_click_x
        );
        const move_y = Math.round(
          this._rdp_click_y1 - this._rdp_region_click_y
        );

        if (
          Math.abs(move_x) > this.RDP_MOUSE_CLICK_TOL ||
          Math.abs(move_y) > this.RDP_MOUSE_CLICK_TOL
        ) {
          this._rdp_move_selected_regions(move_x, move_y);
        } else {
          const nested_region_id = this.is_inside_region(
            this._rdp_click_x0,
            this._rdp_click_y0,
            true
          );
          if (
            nested_region_id >= 0 &&
            nested_region_id !== this.annotatorService.selectedRegionId
          ) {
            this.setSelectedRegionId(nested_region_id);
            this._rdp_is_region_selected = true;
            this._rdp_is_user_moving_region = false;

            // de-select all other regions if the user has not pressed Shift
            if (!e.shiftKey) {
              this.toggle_all_regions_selection(false);
            }
            this.set_region_select_state(nested_region_id, true);
          } else {
            // user clicking inside an already selected region
            // indicates that the user intends to draw a nested region
            this.toggle_all_regions_selection(false);
            this._rdp_is_region_selected = false;

            switch (this._rdp_current_shape) {
              case this.REGION_SHAPE.POLYLINE: // handled by case for POLYGON
                // user has clicked on the first point in a new polygon
                // see also event 'mouseup' for _rdp_is_user_drawing_polygon=true
                this._rdp_is_user_drawing_polygon = true;

                const canvas_polyline_region = new FileRegion();
                canvas_polyline_region.shape_attributes.category =
                  this._rdp_current_shape;
                canvas_polyline_region.shape_attributes.all_points_x = [
                  Math.round(this._rdp_click_x0),
                ];
                canvas_polyline_region.shape_attributes.all_points_y = [
                  Math.round(this._rdp_click_y0),
                ];
                const new_length = this._rdp_canvas_regions.push(
                  canvas_polyline_region
                );
                this._rdp_current_polygon_region_id = new_length - 1;
                break;

              case this.REGION_SHAPE.POLYGON:
                // user has clicked on the first point in a new polygon
                // see also event 'mouseup' for _rdp_is_user_drawing_polygon=true
                this._rdp_is_user_drawing_polygon = true;

                const canvas_polygon_region = new FileRegion();
                canvas_polygon_region.shape_attributes.category =
                  this._rdp_current_shape;
                canvas_polygon_region.shape_attributes.all_points_x = [
                  Math.round(this._rdp_click_x0),
                ];
                canvas_polygon_region.shape_attributes.all_points_y = [
                  Math.round(this._rdp_click_y0),
                ];
                const new_length_temp = this._rdp_canvas_regions.push(
                  canvas_polygon_region
                );
                this._rdp_current_polygon_region_id = new_length_temp - 1;
                break;

              case this.REGION_SHAPE.POINT:
                // todo: 필요없는 코드(아래)
                // const canvas_point_region = new FileRegion();
                // canvas_point_region.shape_attributes.category = this.REGION_SHAPE.POINT;
                // canvas_point_region.shape_attributes.cx = Math.round(this._rdp_click_x0);
                // canvas_point_region.shape_attributes.cy = Math.round(this._rdp_click_y0);
                // const new_len = this._rdp_canvas_regions.push(canvas_point_region);
                // this._rdp_current_polygon_region_id = new_len - 1;
                // this._rdp_canvas_regions.push(canvas_point_region);
                break;
            }
          }
        }
        this._rdp_redraw_reg_canvas();
        this.saveUndoStack(
          this.annotatorService.metadata[this._rdp_image_id].regions
        );
        this.clearRedoStack();
        // this.canvasEl.focus();
        return;
      }

      // indicates that user has finished resizing a region
      if (this._rdp_is_user_resizing_region) {
        // _rdp_click(x0,y0) to _rdp_click(x1,y1)
        this._rdp_is_user_resizing_region = false;
        this.canvasEl.style.cursor = "default";

        // update the region
        const region_id = this._rdp_region_edge[0];

        if (region_id >= 0) {
          const image_attr =
            this.annotatorService.metadata[this._rdp_image_id].regions[
              region_id
            ].shape_attributes;
          const canvas_attr =
            this._rdp_canvas_regions[region_id].shape_attributes;

          switch (canvas_attr.category) {
            case this.REGION_SHAPE.RECT:
              const d = [canvas_attr.x, canvas_attr.y, 0, 0];
              d[2] = d[0] + canvas_attr.width;
              d[3] = d[1] + canvas_attr.height;

              const mx = this._rdp_current_x;
              const my = this._rdp_current_y;
              let preserve_aspect_ratio = false;

              // constrain (mx,my) to lie on a line connecting a diagonal of rectangle
              if (this._rdp_is_ctrl_pressed) {
                preserve_aspect_ratio = true;
              }

              this.rect_update_corner(
                this._rdp_region_edge[1],
                d,
                mx,
                my,
                preserve_aspect_ratio
              );
              this.rect_standardize_coordinates(d);

              const w = Math.abs(d[2] - d[0]);
              const h = Math.abs(d[3] - d[1]);

              image_attr.x = Math.round(d[0]);
              image_attr.y = Math.round(d[1]);
              image_attr.width = Math.round(w);
              image_attr.height = Math.round(h);

              canvas_attr.x = Math.round(image_attr.x);
              canvas_attr.y = Math.round(image_attr.y);
              canvas_attr.width = Math.round(image_attr.width);
              canvas_attr.height = Math.round(image_attr.height);
              break;

            case this.REGION_SHAPE.CIRCLE:
              const dx = Math.abs(canvas_attr.cx - this._rdp_current_x);
              const dy = Math.abs(canvas_attr.cy - this._rdp_current_y);
              const new_r = Math.sqrt(dx * dx + dy * dy);

              image_attr.r = Math.round(new_r);
              canvas_attr.r = Math.round(image_attr.r);
              break;

            case this.REGION_SHAPE.ELLIPSE:
              let new_rx = canvas_attr.rx;
              let new_ry = canvas_attr.ry;
              const new_dx = Math.abs(canvas_attr.cx - this._rdp_current_x);
              const new_dy = Math.abs(canvas_attr.cy - this._rdp_current_y);

              switch (this._rdp_region_edge[1]) {
                case 5:
                  new_ry = new_dy;
                  break;

                case 6:
                  new_rx = new_dx;
                  break;

                default:
                  new_rx = new_dx;
                  new_ry = new_dy;
                  break;
              }

              image_attr.rx = Math.round(new_rx);
              image_attr.ry = Math.round(new_ry);

              canvas_attr.rx = Math.round(image_attr.rx);
              canvas_attr.ry = Math.round(image_attr.ry);
              break;

            case this.REGION_SHAPE.POLYLINE: // handled by polygon
            case this.REGION_SHAPE.POLYGON:
              const moved_vertex_id =
                this._rdp_region_edge[1] -
                this.RDP_POLYGON_RESIZE_VERTEX_OFFSET;

              if (this.isCtrlorMetaKeydown(e)) {
                // if on vertex, delete it
                // if on edge, add a new vertex
                const r =
                  this._rdp_canvas_regions[
                    this.annotatorService.selectedRegionId
                  ].shape_attributes;
                const shape = r.category;
                const is_on_vertex = this.is_on_polygon_vertex(
                  r.all_points_x,
                  r.all_points_y,
                  this._rdp_current_x,
                  this._rdp_current_y
                );

                if (is_on_vertex === this._rdp_region_edge[1]) {
                  // click on vertex, hence delete vertex
                  if (
                    this._rdp_polygon_del_vertex(region_id, moved_vertex_id)
                  ) {
                    // console.log("delete " + moved_vertex_id);
                  }
                } else {
                  const is_on_edge = this.is_on_polygon_edge(
                    r.all_points_x,
                    r.all_points_y,
                    this._rdp_current_x,
                    this._rdp_current_y
                  );
                  if (is_on_edge === this._rdp_region_edge[1]) {
                    // click on edge, hence add new vertex
                    const vertex_index =
                      is_on_edge - this.RDP_POLYGON_RESIZE_VERTEX_OFFSET;
                    let canvas_x0 = Math.round(this._rdp_click_x1);
                    let canvas_y0 = Math.round(this._rdp_click_y1);
                    const img_x0 = Math.round(canvas_x0);
                    const img_y0 = Math.round(canvas_y0);
                    canvas_x0 = Math.round(img_x0);
                    canvas_y0 = Math.round(img_y0);

                    this._rdp_canvas_regions[
                      region_id
                    ].shape_attributes.all_points_x.splice(
                      vertex_index + 1,
                      0,
                      canvas_x0
                    );
                    this._rdp_canvas_regions[
                      region_id
                    ].shape_attributes.all_points_y.splice(
                      vertex_index + 1,
                      0,
                      canvas_y0
                    );
                    this.annotatorService.metadata[this._rdp_image_id].regions[
                      region_id
                    ].shape_attributes.all_points_x.splice(
                      vertex_index + 1,
                      0,
                      img_x0
                    );
                    this.annotatorService.metadata[this._rdp_image_id].regions[
                      region_id
                    ].shape_attributes.all_points_y.splice(
                      vertex_index + 1,
                      0,
                      img_y0
                    );
                  }
                }
              } else {
                // update coordinate of vertex
                const imx = Math.round(this._rdp_current_x);
                const imy = Math.round(this._rdp_current_y);
                image_attr.all_points_x[moved_vertex_id] = imx;
                image_attr.all_points_y[moved_vertex_id] = imy;
                canvas_attr.all_points_x[moved_vertex_id] = Math.round(imx);
                canvas_attr.all_points_y[moved_vertex_id] = Math.round(imy);
              }
              break;
          } // end of switch()
        }
        this._rdp_redraw_reg_canvas();
        this.saveUndoStack(
          this.annotatorService.metadata[this._rdp_image_id].regions
        );
        this.clearRedoStack();
        // this.canvasEl.focus();
        return;
      }

      // denotes a single click (= mouse down + mouse up)
      if (
        click_dx < this.RDP_MOUSE_CLICK_TOL ||
        click_dy < this.RDP_MOUSE_CLICK_TOL
      ) {
        // if user is already drawing polygon, then each click adds a new point
        if (this._rdp_is_user_drawing_polygon) {
          const canvas_x0 = Math.round(this._rdp_click_x1);
          const canvas_y0 = Math.round(this._rdp_click_y1);
          const n =
            this._rdp_canvas_regions[this._rdp_current_polygon_region_id]
              .shape_attributes.all_points_x.length;
          const last_x0 =
            this._rdp_canvas_regions[this._rdp_current_polygon_region_id]
              .shape_attributes.all_points_x[n - 1];
          const last_y0 =
            this._rdp_canvas_regions[this._rdp_current_polygon_region_id]
              .shape_attributes.all_points_y[n - 1];
          // discard if the click was on the last vertex
          if (canvas_x0 !== last_x0 || canvas_y0 !== last_y0) {
            // user clicked on a new polygon point
            this._rdp_canvas_regions[
              this._rdp_current_polygon_region_id
            ].shape_attributes.all_points_x.push(canvas_x0);
            this._rdp_canvas_regions[
              this._rdp_current_polygon_region_id
            ].shape_attributes.all_points_y.push(canvas_y0);
          }
        } else {
          const region_id = this.is_inside_region(
            this._rdp_click_x0,
            this._rdp_click_y0
          );
          if (region_id >= 0) {
            // first click selects region
            this.setSelectedRegionId(region_id);
            this._rdp_is_region_selected = true;
            this._rdp_is_user_moving_region = false;
            this._rdp_is_user_drawing_region = false;

            // de-select all other regions if the user has not pressed Shift
            if (!e.shiftKey) {
              this.toggle_all_regions_selection(false);
            }
            this.set_region_select_state(region_id, true);
          } else {
            if (this._rdp_is_user_drawing_region) {
              // clear all region selection
              this._rdp_is_user_drawing_region = false;
              this._rdp_is_region_selected = false;
              this.toggle_all_regions_selection(false);
            } else {
              switch (this._rdp_current_shape) {
                case this.REGION_SHAPE.POLYLINE: // handled by case for POLYGON
                  if (this.canCreate("polyline") && this.allowCreate) {
                    this._rdp_is_user_drawing_polygon = true;

                    const canvas_polyline_region = new FileRegion();
                    canvas_polyline_region.shape_attributes.category =
                      this._rdp_current_shape;
                    canvas_polyline_region.shape_attributes.all_points_x = [
                      Math.round(this._rdp_click_x0),
                    ];
                    canvas_polyline_region.shape_attributes.all_points_y = [
                      Math.round(this._rdp_click_y0),
                    ];
                    const len = this._rdp_canvas_regions.push(
                      canvas_polyline_region
                    );
                    this._rdp_current_polygon_region_id = len - 1;
                  }
                  break;
                case this.REGION_SHAPE.POLYGON:
                  // user has clicked on the first point in a new polygon
                  // see also event 'mouseup' for _rdp_is_user_moving_region=true
                  if (this.canCreate("polygon") && this.allowCreate) {
                    this._rdp_is_user_drawing_polygon = true;

                    const canvas_polygon_region = new FileRegion();
                    canvas_polygon_region.shape_attributes.category =
                      this._rdp_current_shape;
                    canvas_polygon_region.shape_attributes.all_points_x = [
                      Math.round(this._rdp_click_x0),
                    ];
                    canvas_polygon_region.shape_attributes.all_points_y = [
                      Math.round(this._rdp_click_y0),
                    ];

                    const new_length = this._rdp_canvas_regions.push(
                      canvas_polygon_region
                    );
                    this._rdp_current_polygon_region_id = new_length - 1;
                  }
                  break;

                case this.REGION_SHAPE.POINT:
                  // user has marked a landmark point
                  if (this.canCreate("point") && this.allowCreate) {
                    const point_region = new FileRegion();
                    point_region.shape_attributes.category =
                      this.REGION_SHAPE.POINT;
                    point_region.shape_attributes.cx = Math.round(
                      this._rdp_click_x0
                    );
                    point_region.shape_attributes.cy = Math.round(
                      this._rdp_click_y0
                    );
                    point_region.id = nanoid();

                    // console.log("defaultVariation", this.defaultVariation);
                    if (
                      this.defaultVariation &&
                      this.defaultVariation.variation
                    ) {
                      point_region.region_attributes = JSON.parse(
                        JSON.stringify(this.defaultVariation)
                      );
                    }

                    this.annotatorService.metadata[
                      this._rdp_image_id
                    ].regions.push(point_region);

                    const canvas_point_region = new FileRegion();
                    canvas_point_region.shape_attributes.category =
                      this.REGION_SHAPE.POINT;
                    canvas_point_region.shape_attributes.cx = Math.round(
                      this._rdp_click_x0
                    );
                    canvas_point_region.shape_attributes.cy = Math.round(
                      this._rdp_click_y0
                    );
                    canvas_point_region.id = point_region.id;
                    this._rdp_canvas_regions.push(canvas_point_region);

                    // Remove dupId
                    const regions =
                      this.annotatorService.metadata[this._rdp_image_id]
                        .regions;
                    const dupCnt = regions.filter(
                      (d) => d.id === point_region.id
                    ).length;
                    if (dupCnt > 1) {
                      regions.pop();
                    }
                  }
                  break;
              }
            }
          }
        }
        this.saveUndoStack(this._rdp_canvas_regions);
        this.clearRedoStack();
        this._rdp_redraw_reg_canvas();
        // this.canvasEl.focus();
        return;
      }

      // indicates that user has finished drawing a new region
      if (this._rdp_is_user_drawing_region && this.allowCreate) {
        this._rdp_is_user_drawing_region = false;
        let region_x0 = this._rdp_click_x0;
        let region_y0 = this._rdp_click_y0;
        let region_x1 = this._rdp_click_x1;
        let region_y1 = this._rdp_click_y1;

        const original_img_region = new FileRegion();
        const canvas_img_region = new FileRegion();
        const region_dx = Math.abs(region_x1 - region_x0);
        const region_dy = Math.abs(region_y1 - region_y0);
        let new_region_added = false;

        if (
          region_dx > this.RDP_REGION_MIN_DIM &&
          region_dy > this.RDP_REGION_MIN_DIM
        ) {
          // avoid regions with 0 dim
          let cx;
          let cy;
          switch (this._rdp_current_shape) {
            case this.REGION_SHAPE.RECT:
              if (this.canCreate("rect")) {
                // ensure that (x0,y0) is top-left and (x1,y1) is bottom-right
                if (this._rdp_click_x0 < this._rdp_click_x1) {
                  region_x0 = this._rdp_click_x0;
                  region_x1 = this._rdp_click_x1;
                } else {
                  region_x0 = this._rdp_click_x1;
                  region_x1 = this._rdp_click_x0;
                }

                if (this._rdp_click_y0 < this._rdp_click_y1) {
                  region_y0 = this._rdp_click_y0;
                  region_y1 = this._rdp_click_y1;
                } else {
                  region_y0 = this._rdp_click_y1;
                  region_y1 = this._rdp_click_y0;
                }

                const x = Math.round(region_x0);
                const y = Math.round(region_y0);
                const width = Math.round(region_dx);
                const height = Math.round(region_dy);
                original_img_region.shape_attributes.category = "rect";
                original_img_region.shape_attributes.x = x;
                original_img_region.shape_attributes.y = y;
                original_img_region.shape_attributes.width = width;
                original_img_region.shape_attributes.height = height;
                original_img_region.id = nanoid();

                canvas_img_region.shape_attributes.category = "rect";
                canvas_img_region.shape_attributes.x = Math.round(x);
                canvas_img_region.shape_attributes.y = Math.round(y);
                canvas_img_region.shape_attributes.width = Math.round(width);
                canvas_img_region.shape_attributes.height = Math.round(height);
                canvas_img_region.id = original_img_region.id;
                new_region_added = true;
              }
              break;

            case this.REGION_SHAPE.CIRCLE:
              if (this.canCreate("circle")) {
                cx = Math.round(region_x0);
                cy = Math.round(region_y0);
                const r = Math.round(
                  Math.sqrt(region_dx * region_dx + region_dy * region_dy)
                );

                original_img_region.shape_attributes.category = "circle";
                original_img_region.shape_attributes.cx = cx;
                original_img_region.shape_attributes.cy = cy;
                original_img_region.shape_attributes.r = r;
                original_img_region.id = nanoid();

                canvas_img_region.shape_attributes.category = "circle";
                canvas_img_region.shape_attributes.cx = Math.round(cx);
                canvas_img_region.shape_attributes.cy = Math.round(cy);
                canvas_img_region.shape_attributes.r = Math.round(r);
                canvas_img_region.id = original_img_region.id;

                new_region_added = true;
              }
              break;

            case this.REGION_SHAPE.ELLIPSE:
              if (this.canCreate("ellipse")) {
                cx = Math.round(region_x0);
                cy = Math.round(region_y0);
                const rx = Math.round(region_dx);
                const ry = Math.round(region_dy);

                original_img_region.shape_attributes.category = "ellipse";
                original_img_region.shape_attributes.cx = cx;
                original_img_region.shape_attributes.cy = cy;
                original_img_region.shape_attributes.rx = rx;
                original_img_region.shape_attributes.ry = ry;
                original_img_region.id = nanoid();

                canvas_img_region.shape_attributes.category = "ellipse";
                canvas_img_region.shape_attributes.cx = Math.round(cx);
                canvas_img_region.shape_attributes.cy = Math.round(cy);
                canvas_img_region.shape_attributes.rx = Math.round(rx);
                canvas_img_region.shape_attributes.ry = Math.round(ry);
                canvas_img_region.id = original_img_region.id;

                new_region_added = true;
              }
              break;

            case this.REGION_SHAPE.POINT: // handled by case RDP_REGION_SHAPE.POLYGON
            case this.REGION_SHAPE.POLYLINE: // handled by case RDP_REGION_SHAPE.POLYGON
            case this.REGION_SHAPE.POLYGON:
              // handled by _rdp_is_user_drawing polygon
              break;
          } // end of switch

          if (new_region_added) {
            const n1 =
              this.annotatorService.metadata[this._rdp_image_id].regions.push(
                original_img_region
              );
            this._rdp_canvas_regions =
              this.annotatorService.metadata[this._rdp_image_id].regions.slice(
                0
              );
            const new_region_id = n1 - 1;
            //
            this.set_region_annotations_to_default_value(new_region_id);
            this.select_only_region(new_region_id);
          }
          this.saveUndoStack(this._rdp_canvas_regions);
          this.clearRedoStack();
          this._rdp_redraw_reg_canvas();
        } else {
          // console.log("Prevented accidental addition of a very small region.");
        }
        return;
      }
    } else if (this.mode === "move") {
      this.isUserMovingPanel = false;
    }
    this.annotatorEvent.onScrollUpdate$.emit(null);
  }

  private windowKeydownHandler(e) {
    if (e.target === document.body) {
      // process the keyboard event
      this._rdp_handle_global_keydown_event(e);
    }
  }

  private windowKeyupHandler(e) {
    if (e.key === "Shift") {
      this.annotatorEvent.onScrollUpdate$.emit(null);
      this.isUserMovingPanel = false;
      this.mode = "annotation";
    }
    if (e.target === document.body) {
      // process the keyboard event
      this._rdp_handle_global_keyup_event(e);
    }
  }

  private canvasKeydownHandler(e) {
    if (this.isCtrlorMetaKeydown(e)) {
      this._rdp_is_ctrl_pressed = true;
    }
    // Remove move mode
    // if (e.shiftKey) {
    //   this.mode = "move";
    // }
    if (this._rdp_current_image_loaded) {
      if (this.isNumeric(e.key)) {
        // tslint:disable-next-line:radix
        this.annotatorEvent.onNumericKeyDown$.emit(parseInt(e.key));
      }

      if (e.key === "Enter") {
        if (
          this._rdp_current_shape === this.REGION_SHAPE.POLYLINE ||
          this._rdp_current_shape === this.REGION_SHAPE.POLYGON
        ) {
          this._rdp_polyshape_finish_drawing();
        }
      }
      if (e.key === "Backspace") {
        if (
          this._rdp_current_shape === this.REGION_SHAPE.POLYLINE ||
          this._rdp_current_shape === this.REGION_SHAPE.POLYGON
        ) {
          this._rdp_polyshape_delete_last_vertex();
        }
      }

      if (e.code === "KeyA") {
        this.sel_all_regions();
        e.preventDefault();
        return;
      }

      if (e.code === "KeyC") {
        if (this._rdp_is_region_selected || this._rdp_is_all_region_selected) {
          this.copy_sel_regions();
        }
        e.preventDefault();
        return;
      }

      if (e.code === "KeyV") {
        if (
          !!this.allowCreate &&
          !this._rdp_is_user_drawing_polygon &&
          !this._rdp_is_user_drawing_region
        ) {
          this.paste_sel_regions_in_current_image();
          this.saveUndoStack(
            this.annotatorService.metadata[this._rdp_image_id].regions
          );
          this.clearRedoStack();
          e.preventDefault();
        }
        return;
      }

      if (e.code === "KeyL") {
        this.toggle_region_id_visibility();
        e.preventDefault();
        return;
      }

      if (e.code === "KeyB") {
        this.toggle_region_boundary_visibility();
        e.preventDefault();
        return;
      }
    }
    this._rdp_handle_global_keydown_event(e);
  }

  public isNumeric(num) {
    return !isNaN(num);
  }

  private canvasKeyupHandler(e) {
    if (e.which === 17 || e.which === 91) {
      this._rdp_is_ctrl_pressed = false;
    }
  }

  private canvasMousemoveHandler(e) {
    if (!this._rdp_current_image_loaded) {
      return;
    }
    this._rdp_prev_x = this._rdp_current_x;
    this._rdp_prev_y = this._rdp_current_y;
    this._rdp_current_x = e.offsetX;
    this._rdp_current_y = e.offsetY;

    if (this.mode === "annotation") {
      if (this._rdp_is_region_selected) {
        if (!this._rdp_is_user_resizing_region) {
          // console.log(this.annotatorService.selectedRegionId);
          // console.log(this._rdp_region_edge);

          // check if user moved mouse cursor to region boundary
          // which indicates an intention to resize the region
          this._rdp_region_edge = this.is_on_region_corner(
            this._rdp_current_x,
            this._rdp_current_y
          );
          if (
            this._rdp_region_edge[0] === this.annotatorService.selectedRegionId
          ) {
            switch (this._rdp_region_edge[1]) {
              // rect
              case 1: // Fall-through // top-left corner of rect
              case 3: // bottom-right corner of rect
                this.imagePanelEl.style.cursor = "nwse-resize";
                break;
              case 2: // Fall-through // top-right corner of rect
              case 4: // bottom-left corner of rect
                this.imagePanelEl.style.cursor = "nesw-resize";
                break;

              case 5: // Fall-through // top-middle point of rect
              case 7: // bottom-middle point of rect
                this.imagePanelEl.style.cursor = "ns-resize";
                break;
              case 6: // Fall-through // top-middle point of rect
              case 8: // bottom-middle point of rect
                this.imagePanelEl.style.cursor = "ew-resize";
                break;

              // circle and ellipse
              case 5:
                this.imagePanelEl.style.cursor = "n-resize";
                break;
              case 6:
                this.imagePanelEl.style.cursor = "e-resize";
                break;

              default:
                this.imagePanelEl.style.cursor = "default";
                break;
            }

            if (
              this._rdp_region_edge[1] >= this.RDP_POLYGON_RESIZE_VERTEX_OFFSET
            ) {
              // indicates mouse over polygon vertex
              this.imagePanelEl.style.cursor = "crosshair";
            }
          } else {
            const yes = this.is_inside_this_region(
              this._rdp_current_x,
              this._rdp_current_y,
              this.annotatorService.selectedRegionId
            );
            if (yes) {
              this.imagePanelEl.style.cursor = "move";
            } else {
              this.imagePanelEl.style.cursor = "default";
            }
          }
        }
      }

      if (this._rdp_is_user_drawing_region) {
        // draw region as the user drags the mouse cursor
        if (this._rdp_canvas_regions.length) {
          this._rdp_redraw_reg_canvas(); // clear old intermediate rectangle
        } else {
          // first region being drawn, just clear the full region canvas
          this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        }

        let region_x0 = this._rdp_click_x0;
        let region_y0 = this._rdp_click_y0;

        const dx = Math.round(
          Math.abs(this._rdp_current_x - this._rdp_click_x0)
        );
        const dy = Math.round(
          Math.abs(this._rdp_current_y - this._rdp_click_y0)
        );

        switch (this._rdp_current_shape) {
          case this.REGION_SHAPE.RECT:
            this._rdp_is_user_drawing_polygon = false; // 폴리곤을 그리는 도중에 바운딩 박스를 그릴 경우 폴리곤 해제

            if (this._rdp_click_x0 < this._rdp_current_x) {
              if (this._rdp_click_y0 < this._rdp_current_y) {
                region_x0 = this._rdp_click_x0;
                region_y0 = this._rdp_click_y0;
              } else {
                region_x0 = this._rdp_click_x0;
                region_y0 = this._rdp_current_y;
              }
            } else {
              if (this._rdp_click_y0 < this._rdp_current_y) {
                region_x0 = this._rdp_current_x;
                region_y0 = this._rdp_click_y0;
              } else {
                region_x0 = this._rdp_current_x;
                region_y0 = this._rdp_current_y;
              }
            }

            this._rdp_draw_rect_region(region_x0, region_y0, dx, dy, false);
            break;

          case this.REGION_SHAPE.CIRCLE:
            const circle_radius = Math.round(Math.sqrt(dx * dx + dy * dy));
            this._rdp_draw_circle_region(
              region_x0,
              region_y0,
              circle_radius,
              false
            );
            break;

          case this.REGION_SHAPE.ELLIPSE:
            this._rdp_draw_ellipse_region(region_x0, region_y0, dx, dy, false);
            break;

          case this.REGION_SHAPE.POLYLINE: // handled by polygon
          case this.REGION_SHAPE.POLYGON:
            break;
        }
        // this.canvasEl.focus();
      }

      if (this._rdp_is_user_resizing_region) {
        // user has clicked mouse on bounding box edge and is now moving it
        // draw region as the user drags the mouse coursor
        if (this._rdp_canvas_regions.length) {
          this._rdp_redraw_reg_canvas(); // clear old intermediate rectangle
        } else {
          // first region being drawn, just clear the full region canvas
          this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        }

        const region_id = this._rdp_region_edge[0];
        if (region_id >= 0) {
          // if (region_id >= 0 && this._rdp_is_region_selected && !!this._rdp_region_selected_flag[region_id]) {
          const attr = this._rdp_canvas_regions[region_id].shape_attributes;
          let dx;
          let dy;
          switch (attr.category) {
            case this.REGION_SHAPE.RECT:
              // original rectangle
              const d = [attr.x, attr.y, 0, 0];
              d[2] = d[0] + attr.width;
              d[3] = d[1] + attr.height;

              const mx = this._rdp_current_x;
              const my = this._rdp_current_y;
              let preserve_aspect_ratio = false;
              // constrain (mx,my) to lie on a line connecting a diagonal of rectangle
              if (this._rdp_is_ctrl_pressed) {
                preserve_aspect_ratio = true;
              }

              this.rect_update_corner(
                this._rdp_region_edge[1],
                d,
                mx,
                my,
                preserve_aspect_ratio
              );
              this.rect_standardize_coordinates(d);

              const w = Math.abs(d[2] - d[0]);
              const h = Math.abs(d[3] - d[1]);
              this._rdp_draw_rect_region(d[0], d[1], w, h, true);
              break;

            case this.REGION_SHAPE.CIRCLE:
              dx = Math.abs(attr.cx - this._rdp_current_x);
              dy = Math.abs(attr.cy - this._rdp_current_y);
              const new_r = Math.sqrt(dx * dx + dy * dy);
              this._rdp_draw_circle_region(attr.cx, attr.cy, new_r, true);
              break;

            case this.REGION_SHAPE.ELLIPSE:
              let new_rx = attr.rx;
              let new_ry = attr.ry;
              dx = Math.abs(attr.cx - this._rdp_current_x);
              dy = Math.abs(attr.cy - this._rdp_current_y);
              switch (this._rdp_region_edge[1]) {
                case 5:
                  new_ry = dy;
                  break;

                case 6:
                  new_rx = dx;
                  break;

                default:
                  new_rx = dx;
                  new_ry = dy;
                  break;
              }
              this._rdp_draw_ellipse_region(
                attr.cx,
                attr.cy,
                new_rx,
                new_ry,
                true
              );
              break;

            case this.REGION_SHAPE.POLYLINE: // handled by polygon
            case this.REGION_SHAPE.POLYGON:
              const moved_all_points_x = attr.all_points_x.slice(0);
              const moved_all_points_y = attr.all_points_y.slice(0);
              const moved_vertex_id =
                this._rdp_region_edge[1] -
                this.RDP_POLYGON_RESIZE_VERTEX_OFFSET;

              moved_all_points_x[moved_vertex_id] = this._rdp_current_x;
              moved_all_points_y[moved_vertex_id] = this._rdp_current_y;

              this._rdp_draw_polygon_region(
                moved_all_points_x,
                moved_all_points_y,
                true,
                attr.category
              );
              break;
          }
          // this.canvasEl.focus();
        }
      }

      if (this._rdp_is_user_moving_region) {
        // draw region as the user drags the mouse coursor
        if (this._rdp_canvas_regions.length) {
          this._rdp_redraw_reg_canvas(); // clear old intermediate rectangle
        } else {
          // first region being drawn, just clear the full region canvas
          this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        }

        const move_x = this._rdp_current_x - this._rdp_region_click_x;
        const move_y = this._rdp_current_y - this._rdp_region_click_y;
        const attr =
          this._rdp_canvas_regions[this.annotatorService.selectedRegionId]
            .shape_attributes;
        switch (attr.category) {
          case this.REGION_SHAPE.RECT:
            this._rdp_draw_rect_region(
              attr.x + move_x,
              attr.y + move_y,
              attr.width,
              attr.height,
              true
            );
            break;

          case this.REGION_SHAPE.CIRCLE:
            this._rdp_draw_circle_region(
              attr.cx + move_x,
              attr.cy + move_y,
              attr.r,
              true
            );
            break;

          case this.REGION_SHAPE.ELLIPSE:
            this._rdp_draw_ellipse_region(
              attr.cx + move_x,
              attr.cy + move_y,
              attr.rx,
              attr.ry,
              true
            );
            break;

          case this.REGION_SHAPE.POLYLINE: // handled by polygon
          case this.REGION_SHAPE.POLYGON:
            const moved_all_points_x = attr.all_points_x.slice(0);
            const moved_all_points_y = attr.all_points_y.slice(0);
            for (let i = 0; i < moved_all_points_x.length; ++i) {
              moved_all_points_x[i] += move_x;
              moved_all_points_y[i] += move_y;
            }
            this._rdp_draw_polygon_region(
              moved_all_points_x,
              moved_all_points_y,
              true,
              attr.category
            );
            break;

          case this.REGION_SHAPE.POINT:
            this._rdp_draw_point_region(
              attr.cx + move_x,
              attr.cy + move_y,
              true,
              attr.category
            );
            break;
        }
        // this.canvasEl.focus();
        return;
      }

      if (this._rdp_is_user_drawing_polygon) {
        this._rdp_redraw_reg_canvas();
        const attr =
          this._rdp_canvas_regions[this._rdp_current_polygon_region_id]
            .shape_attributes;
        const all_points_x = attr.all_points_x;
        const all_points_y = attr.all_points_y;
        const npts = all_points_x.length;

        if (npts > 0) {
          let line_x = [all_points_x.slice(npts - 1), this._rdp_current_x];
          let line_y = [all_points_y.slice(npts - 1), this._rdp_current_y];

          // If shift key pressed, draw horizontal or vertical straight line
          if (e.shiftKey) {
            // calculate angle
            const rad = Math.atan2(
              line_y[1] - line_y[0],
              line_x[1] - line_x[0]
            );
            const angle = Math.abs((rad * 180) / Math.PI);

            if ((angle > 45 && angle < 135) || (angle > 225 && angle < 315)) {
              line_x = [
                all_points_x.slice(npts - 1),
                all_points_x.slice(npts - 1),
              ];
              line_y = line_y;
            } else {
              line_x = line_x;
              line_y = [
                all_points_y.slice(npts - 1),
                all_points_y.slice(npts - 1),
              ];
            }

            this._rdp_click_x1 = line_x[1];
            this._rdp_click_y1 = line_y[1];
          }

          this._rdp_draw_polygon_region(line_x, line_y, false, attr.category);
        }
      }
    } else if (this.mode === "move") {
      if (this.isUserMovingPanel) {
        this.annotatorEvent.onScrollUpdate$.emit({
          x: e.clientX,
          y: e.clientY,
        });
      }
    }
  }

  private _rdp_handle_global_keyup_event(e) {
    if (this._rdp_current_image_loaded) {
    }
  }

  private _rdp_handle_global_keydown_event(e) {
    if (this._rdp_current_image_loaded) {
      if (e.code === "Equal") {
        const afterZoom = this.zoom * 1.2;
        const temp = {
          zoom: afterZoom,
          scroll: this.calculateScrollValue(afterZoom),
        };
        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }

      if (e.code === "Minus") {
        const afterZoom = this.zoom * 0.8;
        const temp = {
          zoom: afterZoom,
          scroll: this.calculateScrollValue(afterZoom),
        };

        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }

      if (e.code === "Digit0") {
        const zoom = {
          zoom: 1,
        };
        this.annotatorEvent.onZoomUpdate$.emit(zoom);
      }

      if (e.code === "Comma") {
        this.annotatorEvent.onBrightnessUpdate$.emit(-5);
        return;
      }

      if (e.code === "KeyP") {
        this.annotatorEvent.onCheckPatternChanged$.emit();
        return;
      }

      if (e.code === "Period") {
        this.annotatorEvent.onBrightnessUpdate$.emit(5);
        return;
      }

      if (e.code === "KeyS" && this.isCtrlorMetaKeydown(e)) {
        e.preventDefault();
        e.stopPropagation();
        this.annotatorEvent.onSubmit$.emit();
        return;
      }

      if (e.code === "KeyZ" && this.isCtrlorMetaKeydown(e)) {
        e.preventDefault();
        e.stopPropagation();
        if (!!e.shiftKey) {
          this.redo();
        } else {
          this.undo();
        }
      }
      if (this._rdp_is_region_selected || this._rdp_is_all_region_selected) {
        if (e.code === "KeyD") {
          if (this.canDelete()) {
            if (!this._rdp_is_user_drawing_polygon) {
              this.del_sel_regions();
              this.saveUndoStack(
                this.annotatorService.metadata[this._rdp_image_id].regions
              );
              this.clearRedoStack();
              e.preventDefault();
              return;
            }
          }
        }
      }
      if (this._rdp_is_region_selected) {
        if (
          e.key === "ArrowRight" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowDown" ||
          e.key === "ArrowUp"
        ) {
          let del = 1;
          if (e.shiftKey) {
            del = 10;
          }
          let move_x = 0;
          let move_y = 0;
          switch (e.key) {
            case "ArrowLeft":
              move_x = -del;
              break;
            case "ArrowUp":
              move_y = -del;
              break;
            case "ArrowRight":
              move_x = del;
              break;
            case "ArrowDown":
              move_y = del;
              break;
          }
          this._rdp_move_selected_regions(move_x, move_y);
          this._rdp_redraw_reg_canvas();
          this.saveUndoStack(
            this.annotatorService.metadata[this._rdp_image_id].regions
          );
          this.clearRedoStack();
          e.preventDefault();
          return;
        } else if (e.key === "]") {
          for (let i = 0; i < this._rdp_region_selected_flag.length - 1; i++) {
            if (!!this._rdp_region_selected_flag[i]) {
              this.select_only_region(i + 1, true);
              e.preventDefault();
              return;
            }
          }
        } else if (e.key === "[") {
          for (let i = 1; i < this._rdp_region_selected_flag.length; i++) {
            if (!!this._rdp_region_selected_flag[i]) {
              this.select_only_region(i - 1, true);
              e.preventDefault();
              return;
            }
          }
        }
      }
    }

    if (e.code === "KeyG") {
      this.showMouseGuide = !this.showMouseGuide;
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
      e.preventDefault();
      return;
    }

    if (e.code === "KeyM") {
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
      if (this.mouseGuideMode !== "LINE") {
        this.mouseGuideMode = "LINE";
      } else {
        this.mouseGuideMode = "RECT";
      }
      e.preventDefault();
      return;
    }

    if (e.key === "Escape") {
      e.preventDefault();
      if (this._rdp_is_user_resizing_region) {
        // cancel region resizing action
        this._rdp_is_user_resizing_region = false;
      }

      if (this._rdp_is_region_selected) {
        // clear all region selections
        this._rdp_is_region_selected = false;
        this.setSelectedRegionId(-1);
        this.toggle_all_regions_selection(false);
      }

      if (this._rdp_is_user_drawing_polygon) {
        this._rdp_is_user_drawing_polygon = false;
        this._rdp_canvas_regions.splice(this._rdp_current_polygon_region_id, 1);
      }

      if (this._rdp_is_user_drawing_region) {
        this._rdp_is_user_drawing_region = false;
      }

      if (this._rdp_is_user_resizing_region) {
        this._rdp_is_user_resizing_region = false;
      }

      if (
        this._rdp_is_user_updating_attribute_name ||
        this._rdp_is_user_updating_attribute_value
      ) {
        this._rdp_is_user_updating_attribute_name = false;
        this._rdp_is_user_updating_attribute_value = false;
      }

      if (this._rdp_is_user_moving_region) {
        this._rdp_is_user_moving_region = false;
      }

      this._rdp_redraw_reg_canvas();
      return;
    }
  }

  private _rdp_move_selected_regions(move_x, move_y) {
    const n = this._rdp_region_selected_flag.length;
    for (let i = 0; i < n; ++i) {
      if (this._rdp_region_selected_flag[i]) {
        this._rdp_move_region(i, move_x, move_y);
      }
    }
  }

  private _rdp_move_region(region_id, move_x, move_y) {
    const image_attr =
      this.annotatorService.metadata[this._rdp_image_id].regions[region_id]
        .shape_attributes;
    const canvas_attr = this._rdp_canvas_regions[region_id].shape_attributes;
    switch (canvas_attr.category) {
      case this.REGION_SHAPE.RECT:
        const xnew = Math.max(0, image_attr.x + Math.round(move_x));
        const ynew = Math.max(0, image_attr.y + Math.round(move_y));
        image_attr.x = xnew;
        image_attr.y = ynew;

        canvas_attr.x = Math.round(image_attr.x);
        canvas_attr.y = Math.round(image_attr.y);
        break;

      case this.REGION_SHAPE.CIRCLE: // Fall-through
      case this.REGION_SHAPE.ELLIPSE: // Fall-through
      case this.REGION_SHAPE.POINT:
        const cxnew = image_attr.cx + Math.round(move_x);
        const cynew = image_attr.cy + Math.round(move_y);
        image_attr.cx = cxnew;
        image_attr.cy = cynew;

        canvas_attr.cx = Math.round(image_attr.cx);
        canvas_attr.cy = Math.round(image_attr.cy);
        break;

      case this.REGION_SHAPE.POLYLINE: // handled by polygon
      case this.REGION_SHAPE.POLYGON:
        const img_px = image_attr.all_points_x;
        const img_py = image_attr.all_points_y;
        for (let i = 0; i < img_px.length; ++i) {
          img_px[i] = img_px[i] + Math.round(move_x);
          img_py[i] = img_py[i] + Math.round(move_y);
        }

        const canvas_px = canvas_attr.all_points_x;
        const canvas_py = canvas_attr.all_points_y;
        for (let i = 0; i < canvas_px.length; ++i) {
          canvas_px[i] = Math.round(img_px[i]);
          canvas_py[i] = Math.round(img_py[i]);
        }
        break;
    }
  }

  public select_only_region(region_id, isSidebarClick?) {
    this.toggle_all_regions_selection(false);
    this.set_region_select_state(region_id, true);
    this._rdp_is_region_selected = true;
    this._rdp_is_all_region_selected = false;
    this.setSelectedRegionId(region_id);
    if (isSidebarClick) {
      this._rdp_redraw_reg_canvas();
      this.scrollToRegionId(region_id);
      this.annotatorEvent.onSelectBoundingBox$.emit(region_id);
    } else {
      this.annotatorEvent.onSelectBoundingBox$.emit(region_id);
    }
  }

  private scrollToRegionId(region_id) {
    const displayArea = document.getElementById("displayArea");
    const zoom = this.zoom;
    const region_obj =
      this.annotatorService.metadata[this._rdp_image_id].regions[region_id];

    if (region_obj && region_obj.shape_attributes) {
      if (region_obj.shape_attributes.category === "rect") {
        const scrollTop = region_obj.shape_attributes.y * zoom;
        displayArea.scrollTop = scrollTop;
      } else if (region_obj.shape_attributes.category === "polygon") {
        const scrollTop = region_obj.shape_attributes.all_points_y[0] * zoom;
        displayArea.scrollTop = scrollTop;
      }
    }
  }

  private is_on_region_corner(px, py) {
    const localRegionEdge = [-1, -1]; // region_id, corner_id [top-left=1,top-right=2,bottom-right=3,bottom-left=4]

    for (let i = 0; i < this._rdp_canvas_regions.length; ++i) {
      const region_obj =
        this.annotatorService.metadata[this._rdp_image_id].regions[i];
      if (
        !this._rdp_is_region_selected ||
        this._rdp_region_selected_flag[i] === true
      ) {
        if (
          !this.filter ||
          this.isEmpty(region_obj) ||
          !(region_obj.region_attributes.variation + "") ||
          region_obj.region_attributes.variation + "" === this.filter
        ) {
          const shapeAttr = this._rdp_canvas_regions[i].shape_attributes;
          let result = null;
          localRegionEdge[0] = i;

          switch (shapeAttr.category) {
            case this.REGION_SHAPE.RECT:
              result = this.is_on_rect_edge(
                shapeAttr.x,
                shapeAttr.y,
                shapeAttr.width,
                shapeAttr.height,
                px,
                py
              );
              break;

            case this.REGION_SHAPE.CIRCLE:
              result = this.is_on_circle_edge(
                shapeAttr.cx,
                shapeAttr.cy,
                shapeAttr.r,
                px,
                py
              );
              break;

            case this.REGION_SHAPE.ELLIPSE:
              result = this.is_on_ellipse_edge(
                shapeAttr.cx,
                shapeAttr.cy,
                shapeAttr.rx,
                shapeAttr.ry,
                px,
                py
              );
              break;

            case this.REGION_SHAPE.POLYLINE: // handled by polygon
            case this.REGION_SHAPE.POLYGON:
              result = this.is_on_polygon_vertex(
                shapeAttr.all_points_x,
                shapeAttr.all_points_y,
                px,
                py
              );
              if (result === 0) {
                result = this.is_on_polygon_edge(
                  shapeAttr.all_points_x,
                  shapeAttr.all_points_y,
                  px,
                  py
                );
              }
              break;

            case this.REGION_SHAPE.POINT:
              // since there are no edges of a point
              result = 0;
              break;
          }

          if (result > 0) {
            localRegionEdge[1] = result;
            return localRegionEdge;
          }
        }
      }
    }
    localRegionEdge[0] = -1;
    return localRegionEdge;
  }

  private is_on_rect_edge(x, y, w, h, px, py) {
    const dx0 = Math.abs(x - px);
    const dy0 = Math.abs(y - py);
    const dx1 = Math.abs(x + w - px);
    const dy1 = Math.abs(y + h - py);
    // [top-left=1,top-right=2,bottom-right=3,bottom-left=4]
    if (dx0 < this.RDP_REGION_EDGE_TOL && dy0 < this.RDP_REGION_EDGE_TOL) {
      return 1;
    }
    if (dx1 < this.RDP_REGION_EDGE_TOL && dy0 < this.RDP_REGION_EDGE_TOL) {
      return 2;
    }
    if (dx1 < this.RDP_REGION_EDGE_TOL && dy1 < this.RDP_REGION_EDGE_TOL) {
      return 3;
    }

    if (dx0 < this.RDP_REGION_EDGE_TOL && dy1 < this.RDP_REGION_EDGE_TOL) {
      return 4;
    }

    const mx0 = Math.abs(x + w / 2 - px);
    const my0 = Math.abs(y + h / 2 - py);

    // [top-middle=5,right-middle=6,bottom-middle=7,left-middle=8]
    if (mx0 < this.RDP_REGION_EDGE_TOL && dy0 < this.RDP_REGION_EDGE_TOL) {
      return 5;
    }
    if (dx1 < this.RDP_REGION_EDGE_TOL && my0 < this.RDP_REGION_EDGE_TOL) {
      return 6;
    }
    if (mx0 < this.RDP_REGION_EDGE_TOL && dy1 < this.RDP_REGION_EDGE_TOL) {
      return 7;
    }
    if (dx0 < this.RDP_REGION_EDGE_TOL && my0 < this.RDP_REGION_EDGE_TOL) {
      return 8;
    }

    return 0;
  }

  private is_on_circle_edge(cx, cy, r, px, py) {
    const dx = cx - px;
    const dy = cy - py;
    if (Math.abs(Math.sqrt(dx * dx + dy * dy) - r) < this.RDP_REGION_EDGE_TOL) {
      const theta = Math.atan2(py - cy, px - cx);
      if (
        Math.abs(theta - Math.PI / 2) < this.RDP_THETA_TOL ||
        Math.abs(theta + Math.PI / 2) < this.RDP_THETA_TOL
      ) {
        return 5;
      }
      if (
        Math.abs(theta) < this.RDP_THETA_TOL ||
        Math.abs(Math.abs(theta) - Math.PI) < this.RDP_THETA_TOL
      ) {
        return 6;
      }

      if (theta > 0 && theta < Math.PI / 2) {
        return 1;
      }
      if (theta > Math.PI / 2 && theta < Math.PI) {
        return 4;
      }
      if (theta < 0 && theta > -(Math.PI / 2)) {
        return 2;
      }
      if (theta < -(Math.PI / 2) && theta > -Math.PI) {
        return 3;
      }
    } else {
      return 0;
    }
  }

  private is_on_ellipse_edge(cx, cy, rx, ry, px, py) {
    const dx = (cx - px) / rx;
    const dy = (cy - py) / ry;

    if (
      Math.abs(Math.sqrt(dx * dx + dy * dy) - 1) < this.RDP_ELLIPSE_EDGE_TOL
    ) {
      const theta = Math.atan2(py - cy, px - cx);
      if (
        Math.abs(theta - Math.PI / 2) < this.RDP_THETA_TOL ||
        Math.abs(theta + Math.PI / 2) < this.RDP_THETA_TOL
      ) {
        return 5;
      }
      if (
        Math.abs(theta) < this.RDP_THETA_TOL ||
        Math.abs(Math.abs(theta) - Math.PI) < this.RDP_THETA_TOL
      ) {
        return 6;
      }
    } else {
      return 0;
    }
  }

  private is_on_polygon_vertex(all_points_x, all_points_y, px, py) {
    let i;
    const n = all_points_x.length;

    for (i = 0; i < n; ++i) {
      if (
        Math.abs(all_points_x[i] - px) < this.RDP_POLYGON_VERTEX_MATCH_TOL &&
        Math.abs(all_points_y[i] - py) < this.RDP_POLYGON_VERTEX_MATCH_TOL
      ) {
        return this.RDP_POLYGON_RESIZE_VERTEX_OFFSET + i;
      }
    }
    return 0;
  }

  private is_on_polygon_edge(all_points_x, all_points_y, px, py) {
    let _i;
    let _n = all_points_x.length;
    let _di;
    const _d = [];
    for (_i = 0; _i < _n - 1; ++_i) {
      _di = this.dist_to_line(
        px,
        py,
        all_points_x[_i],
        all_points_y[_i],
        all_points_x[_i + 1],
        all_points_y[_i + 1]
      );
      _d.push(_di);
    }
    // closing edge
    _di = this.dist_to_line(
      px,
      py,
      all_points_x[_n - 1],
      all_points_y[_n - 1],
      all_points_x[0],
      all_points_y[0]
    );
    _d.push(_di);

    let smallestValue = _d[0];
    let smallestIndex = 0;
    _n = _d.length;
    for (_i = 1; _i < _n; ++_i) {
      if (_d[_i] < smallestValue) {
        smallestValue = _d[_i];
        smallestIndex = _i;
      }
    }
    if (smallestValue < this.RDP_POLYGON_VERTEX_MATCH_TOL) {
      return this.RDP_POLYGON_RESIZE_VERTEX_OFFSET + smallestIndex;
    } else {
      return 0;
    }
  }

  private is_point_inside_bounding_box(x, y, x1, y1, x2, y2) {
    // ensure that (x1,y1) is top left and (x2,y2) is bottom right corner of rectangle
    const rect: any = {};
    if (x1 < x2) {
      rect.x1 = x1;
      rect.x2 = x2;
    } else {
      rect.x1 = x2;
      rect.x2 = x1;
    }
    if (y1 < y2) {
      rect.y1 = y1;
      rect.y2 = y2;
    } else {
      rect.y1 = y2;
      rect.y2 = y1;
    }

    return x >= rect.x1 && x <= rect.x2 && y >= rect.y1 && y <= rect.y2;
  }

  private dist_to_line(x, y, x1, y1, x2, y2) {
    if (this.is_point_inside_bounding_box(x, y, x1, y1, x2, y2)) {
      const dy = y2 - y1;
      const dx = x2 - x1;
      const nr = Math.abs(dy * x - dx * y + x2 * y1 - y2 * x1);
      const dr = Math.sqrt(dx * dx + dy * dy);
      const dist = nr / dr;
      return Math.round(dist);
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  private is_inside_region(px, py, descendingOrder?) {
    const N = this._rdp_canvas_regions.length;
    let start;
    let end;
    let del;

    if (N === 0) {
      return -1;
    }
    // traverse the canvas regions in alternating ascending
    // and descending order to solve the issue of nested regions
    if (descendingOrder) {
      start = N - 1;
      end = -1;
      del = -1;
    } else {
      start = 0;
      end = N;
      del = 1;
    }

    let i = start;
    while (i !== end) {
      const yes = this.is_inside_this_region(px, py, i);
      if (yes) {
        // TODO: 긴급요청으로 인한 hotfix 처리 리펙토링 필요
        //labelReadOnly가 false 일때만
        if (!this.labelReadOnly && this.lockStatusList.includes(i)) {
          // bbox 선택시 move cursor 변경될 경우 초기화
          if (this.imagePanelEl.style.cursor === "move") {
            this.imagePanelEl.style.cursor = "default";
          }
          return -1;
        } else {
          return i;
        }
      }
      i = i + del;
    }
    return -1;
  }

  private is_inside_this_region(px, py, region_id) {
    const attr = this._rdp_canvas_regions[region_id].shape_attributes;
    let result = null;
    const region =
      this.annotatorService.metadata[this._rdp_image_id].regions[region_id];
    if (
      !this.filter ||
      this.isEmpty(region) ||
      !region.region_attributes.variation ||
      region.region_attributes.variation + "" === this.filter
    ) {
      switch (attr.category) {
        case this.REGION_SHAPE.RECT:
          result = this.is_inside_rect(
            attr.x,
            attr.y,
            attr.width,
            attr.height,
            px,
            py
          );
          break;

        case this.REGION_SHAPE.CIRCLE:
          result = this.is_inside_circle(attr.cx, attr.cy, attr.r, px, py);
          break;

        case this.REGION_SHAPE.ELLIPSE:
          result = this.is_inside_ellipse(
            attr.cx,
            attr.cy,
            attr.rx,
            attr.ry,
            px,
            py
          );
          break;

        case this.REGION_SHAPE.POLYLINE: // handled by POLYGON
        case this.REGION_SHAPE.POLYGON:
          result = this.is_inside_polygon(
            attr.all_points_x,
            attr.all_points_y,
            px,
            py
          );
          break;

        case this.REGION_SHAPE.POINT:
          result = this.is_inside_point(attr.cx, attr.cy, px, py);
          break;
      }
    }

    return result;
  }

  private is_inside_circle(cx, cy, r, px, py) {
    const dx = px - cx;
    const dy = py - cy;
    return dx * dx + dy * dy < r * r;
  }

  private is_inside_rect(x, y, w, h, px, py) {
    return px > x && px < x + w && py > y && py < y + h;
  }

  private is_inside_ellipse(cx, cy, rx, ry, px, py) {
    const dx = cx - px;
    const dy = cy - py;
    return (dx * dx) / (rx * rx) + (dy * dy) / (ry * ry) < 1;
  }

  private is_inside_polygon(all_points_x, all_points_y, px, py) {
    if (all_points_x.length === 0 || all_points_y.length === 0) {
      return 0;
    }

    let wn = 0; // the  winding number counter
    const n = all_points_x.length;
    let i;
    // loop through all edges of the polygon
    for (i = 0; i < n - 1; ++i) {
      // edge from V[i] to  V[i+1]
      // tslint:disable-next-line:no-shadowed-variable
      const is_left_value = this.is_left(
        all_points_x[i],
        all_points_y[i],
        all_points_x[i + 1],
        all_points_y[i + 1],
        px,
        py
      );

      if (all_points_y[i] <= py) {
        if (all_points_y[i + 1] > py && is_left_value > 0) {
          ++wn;
        }
      } else {
        if (all_points_y[i + 1] <= py && is_left_value < 0) {
          --wn;
        }
      }
    }

    // also take into account the loop closing edge that connects last point with first point
    const is_left_value = this.is_left(
      all_points_x[n - 1],
      all_points_y[n - 1],
      all_points_x[0],
      all_points_y[0],
      px,
      py
    );

    if (all_points_y[n - 1] <= py) {
      if (all_points_y[0] > py && is_left_value > 0) {
        ++wn;
      }
    } else {
      if (all_points_y[0] <= py && is_left_value < 0) {
        --wn;
      }
    }

    if (wn === 0) {
      return 0;
    } else {
      return 1;
    }
  }

  private is_inside_point(cx, cy, px, py) {
    const dx = px - cx;
    const dy = py - cy;
    const r2 =
      this.RDP_POLYGON_VERTEX_MATCH_TOL *
      this.RDP_POLYGON_VERTEX_MATCH_TOL *
      this.RDP_POLYGON_VERTEX_MATCH_TOL;
    return dx * dx + dy * dy < r2;
  }

  private is_left(x0, y0, x1, y1, x2, y2) {
    return (x1 - x0) * (y2 - y0) - (x2 - x0) * (y1 - y0);
  }

  private toggle_all_regions_selection(is_selected) {
    const n = this.annotatorService.metadata[this._rdp_image_id].regions.length;
    let i;
    this._rdp_region_selected_flag = [];
    for (i = 0; i < n; ++i) {
      this._rdp_region_selected_flag[i] = is_selected;
    }
    this._rdp_is_all_region_selected = is_selected;
  }

  private rect_update_corner(corner_id, d, x, y, preserve_aspect_ratio?) {
    // pre-condition : d[x0,y0,x1,y1] is standardized
    // post-condition : corner is moved ( d may not stay standardized )
    // tslint:disable-next-line:one-variable-per-declaration
    let dx, dy, norm, nx, ny, proj, proj_x, proj_y;

    if (preserve_aspect_ratio) {
      switch (corner_id) {
        case 1: // Fall-through // top-left
        case 3: // bottom-right
          dx = d[2] - d[0];
          dy = d[3] - d[1];
          norm = Math.sqrt(dx * dx + dy * dy);
          nx = dx / norm; // x component of unit vector along the diagonal of rect
          ny = dy / norm; // y component
          proj = (x - d[0]) * nx + (y - d[1]) * ny;
          proj_x = nx * proj;
          proj_y = ny * proj;
          // constrain (mx,my) to lie on a line connecting (x0,y0) and (x1,y1)
          x = Math.round(d[0] + proj_x);
          y = Math.round(d[1] + proj_y);
          break;

        case 2: // Fall-through // top-right
        case 4: // bottom-left
          dx = d[2] - d[0];
          dy = d[1] - d[3];
          norm = Math.sqrt(dx * dx + dy * dy);
          nx = dx / norm; // x component of unit vector along the diagonal of rect
          ny = dy / norm; // y component
          proj = (x - d[0]) * nx + (y - d[3]) * ny;
          proj_x = nx * proj;
          proj_y = ny * proj;
          // constrain (mx,my) to lie on a line connecting (x0,y0) and (x1,y1)
          x = Math.round(d[0] + proj_x);
          y = Math.round(d[3] + proj_y);
          break;
      }
    }

    switch (corner_id) {
      case 1: // top-left
        d[0] = x;
        d[1] = y;
        break;

      case 3: // bottom-right
        d[2] = x;
        d[3] = y;
        break;

      case 2: // top-right
        d[2] = x;
        d[1] = y;
        break;

      case 4: // bottom-left
        d[0] = x;
        d[3] = y;
        break;

      case 5: // top-middle
        d[1] = y;
        break;

      case 6: // right-middle
        d[2] = x;
        break;

      case 7: // bottom-middle
        d[3] = y;
        break;

      case 8: // left-middle
        d[0] = x;
        break;
    }
  }

  private rect_standardize_coordinates(d) {
    if (d[0] > d[2]) {
      const t = d[0];
      d[0] = d[2];
      d[2] = t;
    }

    if (d[1] > d[3]) {
      const t = d[1];
      d[1] = d[3];
      d[3] = t;
    }
  }

  private _rdp_draw_rect_region(x, y, w, h, is_selected, category?) {
    if (is_selected) {
      this._rdp_draw_rect(x, y, w, h);

      // if (category) {
      //   this.cx.strokeStyle = this.variationObject[category].color ?
      //     this.variationObject[category].color : this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      // } else {
      this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      // }
      // this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.stroke();

      this.cx.fillStyle = this.RDP_THEME_SEL_REGION_FILL_COLOR;
      this.cx.globalAlpha = this.RDP_THEME_SEL_REGION_OPACITY;
      this.cx.fill();
      this.cx.globalAlpha = 1.0;

      this._rdp_draw_control_point(x, y);
      this._rdp_draw_control_point(x + w, y + h);
      this._rdp_draw_control_point(x, y + h);
      this._rdp_draw_control_point(x + w, y);
      this._rdp_draw_control_point(x + w / 2, y);
      this._rdp_draw_control_point(x + w / 2, y + h);
      this._rdp_draw_control_point(x, y + h / 2);
      this._rdp_draw_control_point(x + w, y + h / 2);
    } else {
      // draw a fill line
      if (category) {
        this.cx.strokeStyle = this.variationObject[category].color
          ? this.variationObject[category].color
          : this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      } else {
        this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      }
      // this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this._rdp_draw_rect(x, y, w, h);
      this.cx.stroke();

      if (
        w > this.RDP_THEME_REGION_BOUNDARY_WIDTH &&
        h > this.RDP_THEME_REGION_BOUNDARY_WIDTH
      ) {
        // draw a boundary line on both sides of the fill line
        if (category) {
          this.cx.strokeStyle = this.variationObject[category].color;
        } else {
          this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_LINE_COLOR;
        }
        // this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_LINE_COLOR;
        this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 4;
      }
    }
    if (this.showRectCenterPoint) {
      this._rdp_draw_rect_center_point(x + w / 2 , y + h / 2);
    }
  }

  private _draw_line(x0, y0, x1, y1) {
    if (!this.cx) {
      return;
    }
    this.cx.beginPath();
    if (x0 && y0) {
      this.cx.moveTo(x0, y0); // from
      this.cx.lineTo(x1, y1);
      this.cx.stroke();
    }
  }

  private _rdp_draw_rect(x, y, w, h) {
    this.cx.beginPath();
    this.cx.moveTo(x, y);
    this.cx.lineTo(x + w, y);
    this.cx.lineTo(x + w, y + h);
    this.cx.lineTo(x, y + h);
    this.cx.closePath();
  }

  private _rdp_draw_control_point(cx, cy, color?) {
    this.cx.beginPath();
    this.cx.arc(
      cx,
      cy,
      this.RDP_REGION_CONTROL_POINT_SIZE,
      0,
      2 * Math.PI,
      false
    );
    this.cx.closePath();

    this.cx.fillStyle = color ? color : this.RDP_THEME_CONTROL_POINT_COLOR;
    this.cx.globalAlpha = 1.0;
    this.cx.fill();
  }

  private _rdp_draw_rect_center_point(cx, cy) {
    this.cx.beginPath();
    this.cx.arc(
      cx,
      cy,
      this.RDP_RECT_CENTER_POINT_SIZE,
      0,
      2 * Math.PI,
      false
    );
    this.cx.closePath();

    this.cx.fillStyle = this.RDP_RECT_CENTER_POINT_COLOR;
    this.cx.globalAlpha = 1.0;
    this.cx.fill();
  }

  private project_file_add_url_input_done(input, dataList?) {
    if (input !== "") {
      const img_id = this.project_file_add_url(input, dataList);
      this.showImage();
    }
  }

  private project_file_add_url(url, dataList?) {
    if (url !== "") {
      // let size = -1; // convention: files added using url have size = -1
      let img_id = this._rdp_get_image_id(url, -1);

      if (!this.annotatorService.metadata.hasOwnProperty(img_id)) {
        img_id = this.project_add_new_file(url);
      } else {
        this._rdp_image_id = img_id;
        this.annotatorService.currentImageId = img_id;
      }

      if (dataList) {
        this.annotatorService.metadata[img_id].regions = dataList;
      }
      return img_id;
    }
  }

  private _rdp_get_image_id(filename, size) {
    if (typeof size === "undefined") {
      return filename;
    } else {
      return filename + size;
    }
  }

  private project_add_new_file(filename) {
    const size = -1;

    const img_id = this._rdp_get_image_id(filename, size);

    if (!this.annotatorService.metadata.hasOwnProperty(img_id)) {
      this.annotatorService.metadata[img_id] = new FileMetadata(filename, size);
      this._rdp_image_id = img_id;
      this.annotatorService.currentImageId = img_id;
    }

    return img_id;
  }

  private showImage() {
    this._rdp_current_image = this.imageViewChild.nativeElement;
    if (!this._rdp_current_image) {
      console.log("current image err");
      return;
    }

    fromEvent(this._rdp_current_image, "load").subscribe(() => {
      this._rdp_current_image.classList.add("visible");

      this._rdp_current_image_filename =
        this.annotatorService.metadata[this._rdp_image_id].filename;
      this._rdp_current_image_loaded = true;

      // update the current state of application
      this._rdp_click_x0 = 0;
      this._rdp_click_y0 = 0;
      this._rdp_click_x1 = 0;
      this._rdp_click_y1 = 0;
      this._rdp_is_user_drawing_region = false;
      this._rdp_is_window_resized = false;
      this._rdp_is_user_resizing_region = false;
      this._rdp_is_user_moving_region = false;
      this._rdp_is_user_drawing_polygon = false;
      this._rdp_is_region_selected = false;
      this.setSelectedRegionId(-1);
      this._rdp_current_image_width = this._rdp_current_image.naturalWidth;
      this._rdp_current_image_height = this._rdp_current_image.naturalHeight;

      if (
        this._rdp_current_image_width === 0 ||
        this._rdp_current_image_height === 0
      ) {
        // for error image icon
        this._rdp_current_image_width = 640;
        this._rdp_current_image_height = 480;
      }

      if (this._rdp_current_image_width >= 17000) {
        alert("이미지 크기가 초과 하였습니다(Image size exceeded).");
      }

      const de = document.documentElement;
      this.clientHeight = de.clientHeight;
      const sidebar = document.getElementById("sidebarLabel");
      const commentList = document.getElementById("commentList");
      const platform = navigator.platform.slice(0, 3);
      if (platform !== "Mac") {
        this.clientWidth =
          de.scrollWidth - sidebar.clientWidth - commentList.clientWidth - 150;
      } else {
        this.clientWidth =
          de.scrollWidth - sidebar.clientWidth - commentList.clientWidth - 80;
      }
      if (sidebar.style.display === "none") {
        this.clientWidth = de.scrollWidth;
      }

      this._rdp_canvas_width = this._rdp_current_image_width;
      this._rdp_canvas_height = this._rdp_current_image_height;

      if (this._rdp_canvas_width > this.clientWidth) {
        //   resize image to match the panel width
        const afterZoom =
          this.clientWidth / this._rdp_current_image.naturalWidth;
        const temp = {
          zoom: afterZoom,
          scroll: this.calculateScrollValue(afterZoom),
        };
        this.annotatorEvent.onZoomUpdate$.emit(temp);
      }
      // if (this._rdp_canvas_height > image_panel_height) {
      //   // resize further image if its height is larger than the image panel
      //   const scale_height = image_panel_height / this._rdp_canvas_height;
      //   this._rdp_canvas_height = image_panel_height;
      //   this._rdp_canvas_width = this._rdp_canvas_width * scale_height;
      // }

      this._rdp_canvas_width = Math.round(this._rdp_canvas_width);
      this._rdp_canvas_height = Math.round(this._rdp_canvas_height);
      this.image_panel_top = 20;
      this.image_panel_left = 20;

      this.set_all_canvas_size(this._rdp_canvas_width, this._rdp_canvas_height);

      // reset all regions to "not selected" state
      this.toggle_all_regions_selection(false);

      // ensure that all the canvas are visible
      this.set_display_area_content(this.RDP_DISPLAY_AREA_CONTENT_NAME.IMAGE);

      // refresh the annotations panel

      this._rdp_load_canvas_regions(); // image to canvas space transform
      this._rdp_redraw_reg_canvas();
      // this.canvasEl.focus();

      // Preserve zoom level
    });
  }

  private set_all_canvas_size(w, h) {
    this.canvasEl.height = h;
    this.canvasEl.width = w;
    this.mouseCanvasEl.width = w;
    this.mouseCanvasEl.height = h;
    this.image_panel.nativeElement.style.height = h + "px";
    this.image_panel.nativeElement.style.width = w + "px";
    this._rdp_canvas_width = w;
    this._rdp_canvas_height = h;
  }

  private _rdp_redraw_reg_canvas() {
    if (this._rdp_current_image_loaded) {
      this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      if (this._rdp_canvas_regions.length > 0) {
        if (this._rdp_is_region_boundary_visible) {
          this.draw_all_regions();
        }
        if (this._rdp_is_region_id_visible) {
          this.draw_all_region_id();
        }
      }
    }
  }

  private draw_all_regions() {
    for (let i = 0; i < this._rdp_canvas_regions.length; ++i) {
      const region =
        this.annotatorService.metadata[this._rdp_image_id].regions[i];
      const variation_str =
        region && region.region_attributes && region.region_attributes.variation
          ? region.region_attributes.variation + ""
          : null;
      if (
        !this.filter ||
        this.isEmpty(region) ||
        !variation_str ||
        variation_str === this.filter
      ) {
        const attr = this._rdp_canvas_regions[i].shape_attributes;
        let variation;
        if (region && region.region_attributes) {
          variation = region.region_attributes.variation;
        }
        const is_selected = this._rdp_region_selected_flag[i];
        switch (attr.category) {
          case this.REGION_SHAPE.RECT:
            this._rdp_draw_rect_region(
              attr.x,
              attr.y,
              attr.width,
              attr.height,
              is_selected,
              variation
            );
            break;

          case this.REGION_SHAPE.CIRCLE:
            this._rdp_draw_circle_region(attr.cx, attr.cy, attr.r, is_selected);
            break;

          case this.REGION_SHAPE.ELLIPSE:
            this._rdp_draw_ellipse_region(
              attr.cx,
              attr.cy,
              attr.rx,
              attr.ry,
              is_selected
            );
            break;

          case this.REGION_SHAPE.POLYLINE: // handled by polygon
          case this.REGION_SHAPE.POLYGON:
            this._rdp_draw_polygon_region(
              attr.all_points_x,
              attr.all_points_y,
              is_selected,
              attr.category,
              variation
            );
            break;

          case this.REGION_SHAPE.POINT:
            this._rdp_draw_point_region(
              attr.cx,
              attr.cy,
              is_selected,
              variation
            );
            break;
        }
      }
    }
  }

  private draw_all_region_id() {
    this.cx.shadowColor = "transparent";
    this.cx.font = this._rdp_settings.ui.image.region_label_font;

    for (
      let i = 0;
      i < this.annotatorService.metadata[this._rdp_image_id].regions.length;
      ++i
    ) {
      const canvas_reg = this._rdp_canvas_regions[i];
      const meta_reg =
        this.annotatorService.metadata[this._rdp_image_id].regions[i];
      // console.log(canvas_reg);
      // console.log(meta_reg);
      const variation_str =
        meta_reg &&
        meta_reg.region_attributes &&
        meta_reg.region_attributes.variation
          ? meta_reg.region_attributes.variation + ""
          : null;

      if (
        !this.filter ||
        this.isEmpty(meta_reg) ||
        !variation_str ||
        variation_str === this.filter
      ) {
        const bbox = this.get_region_bounding_box(canvas_reg);
        let x = bbox[0];
        let y = bbox[1];
        const w = Math.abs(bbox[2] - bbox[0]);

        const char_width = this.cx.measureText("M").width;
        const char_height = 1.8 * char_width;

        let annotation_str = (i + 1).toString();
        // tslint:disable-next-line:max-line-length
        const rattr =
          meta_reg.region_attributes[this._rdp_settings.ui.image.region_label];
        const rshape = meta_reg.shape_attributes.category;
        if (this._rdp_settings.ui.image.region_label !== "__rdp_region_id__") {
          if (typeof rattr !== "undefined") {
            switch (typeof rattr) {
              default:
              case "string":
                annotation_str = rattr;
                break;
              case "object":
                annotation_str = Object.keys(rattr).join(",");
                break;
            }
          } else {
            annotation_str = "undefined";
          }
        }

        let bgnd_rect_width;
        const strw = this.cx.measureText(annotation_str).width;
        if (strw > w) {
          if (
            this._rdp_settings.ui.image.region_label === "__rdp_region_id__"
          ) {
            // region-id is always visible in full
            bgnd_rect_width = strw + char_width;
          } else {
            // if text overflows, crop it
            const str_max = Math.floor((w * annotation_str.length) / strw);
            if (str_max > 1) {
              annotation_str = annotation_str.substr(0, str_max - 1) + ".";
              bgnd_rect_width = w;
            } else {
              annotation_str = annotation_str.substr(0, 1) + ".";
              bgnd_rect_width = 2 * char_width;
            }
          }
        } else {
          bgnd_rect_width = strw + char_width;
        }

        if (
          canvas_reg.shape_attributes.category === this.REGION_SHAPE.POLYGON ||
          canvas_reg.shape_attributes.category === this.REGION_SHAPE.POLYLINE
        ) {
          // put label near the first vertex
          x = canvas_reg.shape_attributes.all_points_x[0];
          y = canvas_reg.shape_attributes.all_points_y[0];
        } else {
          // center the label
          x = x - (bgnd_rect_width / 2 - w / 2);
        }

        // ensure that the text is within the image boundaries
        if (y < char_height) {
          y = char_height;
        }

        // first, draw a background rectangle first
        this.cx.fillStyle = "black";
        this.cx.globalAlpha = 0.8;
        this.cx.fillRect(
          Math.floor(x),
          Math.floor(y - 1.1 * char_height),
          Math.floor(bgnd_rect_width),
          Math.floor(char_height)
        );

        // then, draw text over this background rectangle
        this.cx.globalAlpha = 1.0;
        this.cx.fillStyle = "yellow";
        let labelText = annotation_str;
        if (this.showLabelDisplayName) {
          const variationDisplay = meta_reg.region_attributes.variation_display
            ? meta_reg.region_attributes.variation_display
            : "";
          labelText += "    " + variationDisplay;
        }
        this.cx.fillText(
          labelText,
          Math.floor(x + 0.4 * char_width),
          Math.floor(y - 0.35 * char_height)
        );
      }
    }
  }

  private get_region_bounding_box(region) {
    const d = region.shape_attributes;
    const bbox = new Array(4);

    switch (d.category) {
      case "rect":
        bbox[0] = d.x;
        bbox[1] = d.y;
        bbox[2] = d.x + d.width;
        bbox[3] = d.y + d.height;
        break;

      case "circle":
        bbox[0] = d.cx - d.r;
        bbox[1] = d.cy - d.r;
        bbox[2] = d.cx + d.r;
        bbox[3] = d.cy + d.r;
        break;

      case "ellipse":
        bbox[0] = d.cx - d.rx;
        bbox[1] = d.cy - d.ry;
        bbox[2] = d.cx + d.rx;
        bbox[3] = d.cy + d.ry;
        break;

      case "polyline": // handled by polygon
      case "polygon":
        const all_points_x = d.all_points_x;
        const all_points_y = d.all_points_y;

        let minx = Number.MAX_SAFE_INTEGER;
        let miny = Number.MAX_SAFE_INTEGER;
        let maxx = 0;
        let maxy = 0;
        for (let i = 0; i < all_points_x.length; ++i) {
          if (all_points_x[i] < minx) {
            minx = all_points_x[i];
          }
          if (all_points_x[i] > maxx) {
            maxx = all_points_x[i];
          }
          if (all_points_y[i] < miny) {
            miny = all_points_y[i];
          }
          if (all_points_y[i] > maxy) {
            maxy = all_points_y[i];
          }
        }
        bbox[0] = minx;
        bbox[1] = miny;
        bbox[2] = maxx;
        bbox[3] = maxy;
        break;

      case "point":
        bbox[0] = d.cx - this.RDP_REGION_POINT_RADIUS;
        bbox[1] = d.cy - this.RDP_REGION_POINT_RADIUS;
        bbox[2] = d.cx + this.RDP_REGION_POINT_RADIUS;
        bbox[3] = d.cy + this.RDP_REGION_POINT_RADIUS;
        break;
    }
    return bbox;
  }

  private set_display_area_content(content_name) {
    if (this.is_content_name_valid(content_name)) {
      this.clear_display_area();
      const p = document.getElementById(content_name);
      if (p.classList) {
        p.classList.remove("display_none");
      }
    }
  }

  private is_content_name_valid(content_name) {
    let e;
    for (e in this.RDP_DISPLAY_AREA_CONTENT_NAME) {
      if (this.RDP_DISPLAY_AREA_CONTENT_NAME[e] === content_name) {
        return true;
      }
    }
    return false;
  }

  private clear_display_area() {
    const panels = document.getElementsByClassName("display_area_content");
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < panels.length; ++i) {
      panels[i].classList.add("display_none");
    }
  }

  private _rdp_load_canvas_regions() {
    // load all existing annotations into _rdp_canvas_regions
    const regions = this.annotatorService.metadata[this._rdp_image_id].regions;
    this._rdp_canvas_regions = [];
    for (let i = 0; i < regions.length; ++i) {
      const region_i = new FileRegion();
      // tslint:disable-next-line:forin
      for (const key in regions[i].shape_attributes) {
        region_i.shape_attributes[key] = regions[i].shape_attributes[key];
      }
      // tslint:disable-next-line:forin
      for (const key in regions[i].region_attributes) {
        region_i.region_attributes[key] = regions[i].region_attributes[key];
      }
      this._rdp_canvas_regions.push(region_i);
      let cx;
      let cy;

      switch (this._rdp_canvas_regions[i].shape_attributes.category) {
        case this.REGION_SHAPE.RECT:
          const x = regions[i].shape_attributes.x;
          const y = regions[i].shape_attributes.y;
          const width = regions[i].shape_attributes.width;
          const height = regions[i].shape_attributes.height;

          this._rdp_canvas_regions[i].shape_attributes.x = Math.round(x);
          this._rdp_canvas_regions[i].shape_attributes.y = Math.round(y);
          this._rdp_canvas_regions[i].shape_attributes.width =
            Math.round(width);
          this._rdp_canvas_regions[i].shape_attributes.height =
            Math.round(height);
          break;

        case this.REGION_SHAPE.CIRCLE:
          cx = regions[i].shape_attributes.cx;
          cy = regions[i].shape_attributes.cy;
          const r = regions[i].shape_attributes.r;
          this._rdp_canvas_regions[i].shape_attributes.cx = Math.round(cx);
          this._rdp_canvas_regions[i].shape_attributes.cy = Math.round(cy);
          this._rdp_canvas_regions[i].shape_attributes.r = Math.round(r);
          break;

        case this.REGION_SHAPE.ELLIPSE:
          cx = regions[i].shape_attributes.cx;
          cy = regions[i].shape_attributes.cy;
          const rx = regions[i].shape_attributes.rx;
          const ry = regions[i].shape_attributes.ry;
          this._rdp_canvas_regions[i].shape_attributes.cx = Math.round(cx);
          this._rdp_canvas_regions[i].shape_attributes.cy = Math.round(cy);
          this._rdp_canvas_regions[i].shape_attributes.rx = Math.round(rx);
          this._rdp_canvas_regions[i].shape_attributes.ry = Math.round(ry);
          break;

        case this.REGION_SHAPE.POLYLINE: // handled by polygon
        case this.REGION_SHAPE.POLYGON:
          const all_points_x =
            regions[i].shape_attributes.all_points_x.slice(0);
          const all_points_y =
            regions[i].shape_attributes.all_points_y.slice(0);
          for (let j = 0; j < all_points_x.length; ++j) {
            all_points_x[j] = Math.round(all_points_x[j]);
            all_points_y[j] = Math.round(all_points_y[j]);
          }
          this._rdp_canvas_regions[i].shape_attributes.all_points_x =
            all_points_x;
          this._rdp_canvas_regions[i].shape_attributes.all_points_y =
            all_points_y;
          break;

        case this.REGION_SHAPE.POINT:
          cx = regions[i].shape_attributes.cx;
          cy = regions[i].shape_attributes.cy;

          this._rdp_canvas_regions[i].shape_attributes.cx = Math.round(cx);
          this._rdp_canvas_regions[i].shape_attributes.cy = Math.round(cy);
          break;
      }
    }
    this.saveUndoStack(this._rdp_canvas_regions);
    this.clearRedoStack();
  }

  private set_region_select_state(region_id, is_selected) {
    this._rdp_region_selected_flag[region_id] = is_selected;
  }

  private set_region_annotations_to_default_value(rid) {
    if (this.defaultVariation) {
      this.annotatorService.metadata[this._rdp_image_id].regions[
        rid
      ].region_attributes = this.defaultVariation;
    }
    this.event.onSelectedItemChanged$.emit({ rid, isSidebarClick: false });
  }

  private _rdp_polygon_del_vertex(region_id, vertex_id) {
    const rs = this._rdp_canvas_regions[region_id].shape_attributes;
    const npts = rs.all_points_x.length;
    const shape = rs.category;
    if (
      shape !== this.REGION_SHAPE.POLYGON &&
      shape !== this.REGION_SHAPE.POLYLINE
    ) {
      console.log("Vertices can only be deleted from polygon/polyline.");
      return false;
    }
    if (npts <= 3 && shape === this.REGION_SHAPE.POLYGON) {
      console.log(
        "Failed to delete vertex because a polygon must have at least 3 vertices."
      );
      return false;
    }
    if (npts <= 2 && shape === this.REGION_SHAPE.POLYLINE) {
      console.log(
        "Failed to delete vertex because a polyline must have at least 2 vertices."
      );
      return false;
    }
    // delete vertex from canvas
    this._rdp_canvas_regions[region_id].shape_attributes.all_points_x.splice(
      vertex_id,
      1
    );
    this._rdp_canvas_regions[region_id].shape_attributes.all_points_y.splice(
      vertex_id,
      1
    );

    // delete vertex from image metadata
    this.annotatorService.metadata[this._rdp_image_id].regions[
      region_id
    ].shape_attributes.all_points_x.splice(vertex_id, 1);
    this.annotatorService.metadata[this._rdp_image_id].regions[
      region_id
    ].shape_attributes.all_points_y.splice(vertex_id, 1);
    return true;
  }

  private _rdp_draw_circle_region(circlex, cy, r, is_selected) {
    if (is_selected) {
      this._rdp_draw_circle(circlex, cy, r);

      this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.stroke();

      this.cx.fillStyle = this.RDP_THEME_SEL_REGION_FILL_COLOR;
      this.cx.globalAlpha = this.RDP_THEME_SEL_REGION_OPACITY;
      this.cx.fill();
      this.cx.globalAlpha = 1.0;

      this._rdp_draw_control_point(circlex + r, cy);
    } else {
      // draw a fill line
      this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this._rdp_draw_circle(circlex, cy, r);
      this.cx.stroke();

      if (r > this.RDP_THEME_REGION_BOUNDARY_WIDTH) {
        // draw a boundary line on both sides of the fill line
        this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_LINE_COLOR;
        this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 4;
        this._rdp_draw_circle(
          circlex,
          cy,
          r - this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
        );
        this.cx.stroke();
        this._rdp_draw_circle(
          circlex,
          cy,
          r + this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
        );
        this.cx.stroke();
      }
    }
  }

  private _rdp_draw_circle(circlex, cy, r) {
    this.cx.beginPath();
    this.cx.arc(circlex, cy, r, 0, 2 * Math.PI, false);
    this.cx.closePath();
  }

  private _rdp_draw_ellipse_region(cx, cy, rx, ry, is_selected) {
    if (is_selected) {
      this._rdp_draw_ellipse(cx, cy, rx, ry);

      this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.stroke();

      this.cx.fillStyle = this.RDP_THEME_SEL_REGION_FILL_COLOR;
      this.cx.globalAlpha = this.RDP_THEME_SEL_REGION_OPACITY;
      this.cx.fill();
      this.cx.globalAlpha = 1.0;

      this._rdp_draw_control_point(cx + rx, cy);
      this._rdp_draw_control_point(cx, cy - ry);
    } else {
      // draw a fill line
      this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this._rdp_draw_ellipse(cx, cy, rx, ry);
      this.cx.stroke();

      if (
        rx > this.RDP_THEME_REGION_BOUNDARY_WIDTH &&
        ry > this.RDP_THEME_REGION_BOUNDARY_WIDTH
      ) {
        // draw a boundary line on both sides of the fill line
        this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_LINE_COLOR;
        this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 4;
        this._rdp_draw_ellipse(
          cx,
          cy,
          rx + this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2,
          ry + this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
        );
        this.cx.stroke();
        this._rdp_draw_ellipse(
          cx,
          cy,
          rx - this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2,
          ry - this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
        );
        this.cx.stroke();
      }
    }
  }

  private _rdp_draw_ellipse(cx, cy, rx, ry) {
    this.cx.save();

    this.cx.beginPath();
    this.cx.translate(cx - rx, cy - ry);
    this.cx.scale(rx, ry);
    this.cx.arc(1, 1, 1, 0, 2 * Math.PI, false);

    this.cx.restore(); // restore to original state
    this.cx.closePath();
  }

  private _rdp_draw_polygon_region(
    all_points_x,
    all_points_y,
    is_selected,
    shape,
    category?
  ) {
    if (is_selected) {
      this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.beginPath();
      this.cx.moveTo(all_points_x[0], all_points_y[0]);

      for (let i = 1; i < all_points_x.length; ++i) {
        this.cx.lineTo(all_points_x[i], all_points_y[i]);
      }
      if (shape === this.REGION_SHAPE.POLYGON) {
        this.cx.lineTo(all_points_x[0], all_points_y[0]); // close loop
      }
      this.cx.stroke();

      this.cx.fillStyle = this.RDP_THEME_SEL_REGION_FILL_COLOR;
      this.cx.globalAlpha = this.RDP_THEME_SEL_REGION_OPACITY;
      this.cx.fill();
      this.cx.globalAlpha = 1.0;
      for (let i = 0; i < all_points_x.length; ++i) {
        this._rdp_draw_control_point(all_points_x[i], all_points_y[i]);
      }
    } else {
      // draw a fill line
      if (category) {
        this.cx.strokeStyle = this.variationObject[category].color;
      } else {
        this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      }
      // this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.beginPath();
      this.cx.moveTo(all_points_x[0], all_points_y[0]);
      for (let i = 0; i < all_points_x.length; ++i) {
        this.cx.lineTo(all_points_x[i], all_points_y[i]);
      }
      if (shape === this.REGION_SHAPE.POLYGON) {
        this.cx.lineTo(all_points_x[0], all_points_y[0]); // close loop
      }
      this.cx.stroke();

      if (this._rdp_is_check_pattern) {
        for (let i = 0; i < all_points_x.length; ++i) {
          let color = null;
          if (i < 1) {
            color = "#ff0000";
          } else if (i < 2) {
            color = "#0000ff";
          }
          this._rdp_draw_control_point(all_points_x[i], all_points_y[i], color);
        }
      }
    }
  }

  private _rdp_draw_point_region(cx, cy, is_selected, category?) {
    if (is_selected) {
      this._rdp_draw_point(cx, cy, this.RDP_REGION_POINT_RADIUS);

      if (category && this.variationObject[category]) {
        this.cx.strokeStyle = this.variationObject[category].color;
      } else {
        this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      }
      // this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this.cx.stroke();

      if (category && this.variationObject[category]) {
        this.cx.fillStyle = this.variationObject[category].color;
      } else {
        this.cx.fillStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      }
      this.cx.fill();
      // this.cx.globalAlpha = 1.0;
    } else {
      // draw a fill line
      if (category && this.variationObject[category]) {
        this.cx.strokeStyle = this.variationObject[category].color;
      } else {
        this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      }
      // this.cx.strokeStyle = this.RDP_THEME_BOUNDARY_FILL_COLOR;
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2;
      this._rdp_draw_point(cx, cy, this.RDP_REGION_POINT_RADIUS);
      this.cx.stroke();

      // draw a boundary line on both sides of the fill line
      if (category && this.variationObject[category]) {
        this.cx.strokeStyle = this.variationObject[category].color;
      } else {
        this.cx.strokeStyle = this.RDP_THEME_SEL_REGION_FILL_BOUNDARY_COLOR;
      }
      this.cx.lineWidth = this.RDP_THEME_REGION_BOUNDARY_WIDTH / 4;
      this._rdp_draw_point(
        cx,
        cy,
        this.RDP_REGION_POINT_RADIUS - this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
      );
      this.cx.stroke();
      this._rdp_draw_point(
        cx,
        cy,
        this.RDP_REGION_POINT_RADIUS + this.RDP_THEME_REGION_BOUNDARY_WIDTH / 2
      );
      this.cx.stroke();
    }
  }

  private _rdp_draw_point(cx, cy, r) {
    this.cx.lineWidth = 5;
    this.cx.beginPath();
    this.cx.arc(cx, cy, r, 0, 2 * Math.PI, false);
    this.cx.closePath();
  }

  private _rdp_polyshape_finish_drawing() {
    if (this._rdp_is_user_drawing_polygon) {
      // double click is used to indicate completion of
      // polygon or polyline drawing action
      const new_region_id = this._rdp_current_polygon_region_id;
      const new_region_shape = this._rdp_current_shape;

      const npts =
        this._rdp_canvas_regions[new_region_id].shape_attributes.all_points_x
          .length;
      if (npts <= 2 && new_region_shape === this.REGION_SHAPE.POLYGON) {
        return;
      }
      if (npts <= 1 && new_region_shape === this.REGION_SHAPE.POLYLINE) {
        return;
      }

      const img_id = this._rdp_image_id;
      this._rdp_current_polygon_region_id = -1;
      this._rdp_is_user_drawing_polygon = false;
      this._rdp_is_user_drawing_region = false;

      // this.annotatorService.metadata[img_id].regions[new_region_id] = {}; // create placeholder
      this._rdp_polyshape_add_new_polyshape(
        img_id,
        new_region_shape,
        new_region_id
      );
      this.select_only_region(new_region_id); // select new region
      this.set_region_annotations_to_default_value(new_region_id);

      this.saveUndoStack(
        this.annotatorService.metadata[this._rdp_image_id].regions
      );
      this.clearRedoStack();
      this._rdp_redraw_reg_canvas();
      // this.canvasEl.focus();
    }
    return;
  }

  private _rdp_polyshape_add_new_polyshape(img_id, region_shape, region_id) {
    // add all polygon points stored in _rdp_canvas_regions[]
    const all_points_x =
      this._rdp_canvas_regions[region_id].shape_attributes.all_points_x.slice(
        0
      );
    const all_points_y =
      this._rdp_canvas_regions[region_id].shape_attributes.all_points_y.slice(
        0
      );

    const canvas_all_points_x = [];
    const canvas_all_points_y = [];
    const n = all_points_x.length;
    let i;
    for (i = 0; i < n; ++i) {
      all_points_x[i] = Math.round(all_points_x[i]);
      all_points_y[i] = Math.round(all_points_y[i]);

      canvas_all_points_x[i] = Math.round(all_points_x[i]);
      canvas_all_points_y[i] = Math.round(all_points_y[i]);
    }

    const polygon_region = new FileRegion();
    polygon_region.shape_attributes.category = region_shape;
    polygon_region.shape_attributes.all_points_x = all_points_x;
    polygon_region.shape_attributes.all_points_y = all_points_y;
    polygon_region.id = nanoid();

    // update canvas
    this._rdp_canvas_regions[region_id].shape_attributes.category =
      region_shape;
    this._rdp_canvas_regions[region_id].shape_attributes.all_points_x =
      canvas_all_points_x;
    this._rdp_canvas_regions[region_id].shape_attributes.all_points_y =
      canvas_all_points_y;
    this._rdp_canvas_regions[region_id].id = polygon_region.id;
    this.annotatorService.metadata[img_id].regions[region_id] = polygon_region;
  }

  private _rdp_polyshape_delete_last_vertex() {
    if (this._rdp_is_user_drawing_polygon) {
      const npts =
        this._rdp_canvas_regions[this._rdp_current_polygon_region_id]
          .shape_attributes.all_points_x.length;
      if (npts > 0) {
        this._rdp_canvas_regions[
          this._rdp_current_polygon_region_id
        ].shape_attributes.all_points_x.splice(npts - 1, 1);
        this._rdp_canvas_regions[
          this._rdp_current_polygon_region_id
        ].shape_attributes.all_points_y.splice(npts - 1, 1);

        this._rdp_redraw_reg_canvas();
        // this.canvasEl.focus();
      }
    }
  }

  private sel_all_regions() {
    if (!this._rdp_current_image_loaded) {
      return;
    }

    this.toggle_all_regions_selection(true);
    this._rdp_is_all_region_selected = true;
    this._rdp_redraw_reg_canvas();
  }

  private copy_sel_regions() {
    if (!this._rdp_current_image_loaded) {
      return;
    }

    if (this._rdp_is_region_selected || this._rdp_is_all_region_selected) {
      this._rdp_copied_image_regions.splice(0);
      for (
        let i = 0;
        i < this.annotatorService.metadata[this._rdp_image_id].regions.length;
        ++i
      ) {
        const img_region =
          this.annotatorService.metadata[this._rdp_image_id].regions[i];
        const canvas_region = this._rdp_canvas_regions[i];
        if (this._rdp_region_selected_flag[i]) {
          this._rdp_copied_image_regions.push(
            this.clone_image_region(img_region)
          );
        }
      }
    }
  }

  private clone_image_region(r0) {
    const r1 = new FileRegion();

    // copy shape attributes
    // tslint:disable-next-line:forin
    for (const key in r0.shape_attributes) {
      r1.shape_attributes[key] = this.clone_value(r0.shape_attributes[key]);
    }

    // copy region attributes
    // tslint:disable-next-line:forin
    for (const key in r0.region_attributes) {
      r1.region_attributes[key] = this.clone_value(r0.region_attributes[key]);
    }
    r1.id = nanoid();
    return r1;
  }

  private clone_value(value) {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.slice(0);
      } else {
        const copy = {};
        for (const p in value) {
          if (value.hasOwnProperty(p)) {
            copy[p] = this.clone_value(value[p]);
          }
        }
        return copy;
      }
    }
    return value;
  }

  private paste_sel_regions_in_current_image() {
    if (!this._rdp_current_image_loaded) {
      return;
    }

    if (this._rdp_copied_image_regions.length) {
      let pasted_reg_count = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this._rdp_copied_image_regions.length; ++i) {
        // ensure copied the regions are within this image's boundaries
        const bbox = this.get_region_bounding_box(
          this._rdp_copied_image_regions[i]
        );
        if (
          bbox[2] < this._rdp_current_image_width &&
          bbox[3] < this._rdp_current_image_height
        ) {
          const r = this.clone_image_region(this._rdp_copied_image_regions[i]);
          this.annotatorService.metadata[this._rdp_image_id].regions.push(r);
          pasted_reg_count += 1;
        }
      }
      this._rdp_load_canvas_regions();
      const discarded_reg_count =
        this._rdp_copied_image_regions.length - pasted_reg_count;
      this._rdp_redraw_reg_canvas();
      // this.canvasEl.focus();
    }
  }

  private toggle_region_id_visibility() {
    this._rdp_is_region_id_visible = !this._rdp_is_region_id_visible;
    this._rdp_redraw_reg_canvas();
    // this.canvasEl.focus();
  }

  private del_sel_regions() {
    if (!this._rdp_current_image_loaded) {
      return;
    }

    let del_region_count = 0;
    if (this._rdp_is_all_region_selected) {
      del_region_count = this._rdp_canvas_regions.length;
      this._rdp_canvas_regions.splice(0);
      this.annotatorService.metadata[this._rdp_image_id].regions.splice(0);
    } else {
      const sorted_sel_reg_id = [];
      for (let i = 0; i < this._rdp_canvas_regions.length; ++i) {
        if (this._rdp_region_selected_flag[i]) {
          sorted_sel_reg_id.push(i);
          this._rdp_region_selected_flag[i] = false;
        }
      }
      sorted_sel_reg_id.sort((a, b) => {
        return b - a;
      });

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < sorted_sel_reg_id.length; ++i) {
        this.annotatorService.metadata[this._rdp_image_id].regions.splice(
          sorted_sel_reg_id[i],
          1
        );
        this._rdp_canvas_regions =
          this.annotatorService.metadata[this._rdp_image_id].regions.slice(0);
        del_region_count += 1;
      }

      if (sorted_sel_reg_id.length) {
        this.canvasEl.style.cursor = "default";
      }
    }

    this._rdp_is_all_region_selected = false;
    this._rdp_is_region_selected = false;
    this.setSelectedRegionId(-1);

    if (this._rdp_canvas_regions.length === 0) {
      // all regions were deleted, hence clear region canvas
      this._rdp_clear_reg_canvas();
    } else {
      this._rdp_redraw_reg_canvas();
    }
    // this.canvasEl.focus();
  }

  private _rdp_clear_reg_canvas() {
    this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
  }

  private _rdp_update_ui_components() {
    if (!this._rdp_current_image_loaded) {
      return;
    }

    if (!this._rdp_is_window_resized && this._rdp_current_image_loaded) {
      this._rdp_is_window_resized = true;
      this.showImage();
    }
  }

  public setSelectedRegionId(id) {
    this.annotatorService.setSelectedRegionId(id);
    this.changeSelectedRegionEvent.emit(id);
  }

  public toggle_region_boundary_visibility() {
    this._rdp_is_region_boundary_visible =
      !this._rdp_is_region_boundary_visible;
    this._rdp_redraw_reg_canvas();
    // this.canvasEl.focus();
  }

  private isCtrlorMetaKeydown(e) {
    return e.ctrlKey || e.metaKey;
  }

  private drawMouseGuide(pos, mode?) {
    if (pos) {
      this.mouseCx.clearRect(
        0,
        0,
        this._rdp_canvas_width,
        this._rdp_canvas_height
      );
      this.mouseCx.beginPath();
      if (mode === "LINE" || (this.GUIDE_W === 0 && this.GUIDE_LIST === [])) {
        this.mouseCx.moveTo(pos.x, 0);
        this.mouseCx.lineTo(pos.x, this._rdp_canvas_height);
        this.mouseCx.moveTo(0, pos.y);
        this.mouseCx.lineTo(this._rdp_canvas_width, pos.y);
        this.mouseCx.strokeStyle = this.RDP_MOUSE_GUIDE_COLOR;
      } else {
        // 마우스 가이드 사각형
        if (this.GUIDE_LIST.length !== 0) {
          this.GUIDE_LIST.forEach(row => {
            this.mouseCx.moveTo(pos.x, pos.y);
            this.mouseCx.lineTo(pos.x + row.guide_w, pos.y);
            this.mouseCx.lineTo(pos.x + row.guide_w, pos.y + row.guide_h);
            this.mouseCx.lineTo(pos.x, pos.y + row.guide_h);
            this.mouseCx.lineTo(pos.x, pos.y);
            this.mouseCx.strokeStyle = "#ff0000";
          });
        } else {
          this.mouseCx.moveTo(pos.x, pos.y);
          this.mouseCx.lineTo(pos.x + this.GUIDE_W, pos.y);
          this.mouseCx.lineTo(pos.x + this.GUIDE_W, pos.y + this.GUIDE_H);
          this.mouseCx.lineTo(pos.x, pos.y + this.GUIDE_H);
          this.mouseCx.lineTo(pos.x, pos.y);
          this.mouseCx.strokeStyle = "#ff0000";
        }
      }
      this.mouseCx.stroke();
      this.mouseCx.strokeStyle = this.RDP_MOUSE_GUIDE_COLOR;
    }
  }

  private getCanvasMousePosition(el, e: MouseEvent) {
    const rect = el.getBoundingClientRect();
    const pos = {
      x: Math.round((e.clientX - rect.left) / this.zoom),
      y: Math.round((e.clientY - rect.top) / this.zoom),
    };
    return pos;
  }

  private calculateScrollValue(zoom) {
    if (this._rdp_current_x && this._rdp_current_y) {
      const scrollX = this._rdp_current_x * zoom - this.clientWidth / 2;
      const scrollY = this._rdp_current_y * zoom - this.clientHeight / 2;
      const scroll = {
        x: scrollX > 0 ? scrollX : 0,
        y: scrollY > 0 ? scrollY : 0,
      };
      return scroll;
    } else {
      return null;
    }
  }

  public saveUndoStack(item) {
    let copy;
    if (typeof item === "string") {
      copy = item;
    } else {
      copy = JSON.stringify(item);
    }
    if (!this._rdp_is_user_drawing_polygon) {
      this.undoStack.push(copy);
      if (this.undoStack.length > 100) {
        this.undoStack.shift();
      }
    }
  }

  public saveRedoStack(item) {
    this.redoStack.push(item);
    if (this.redoStack.length > 100) {
      this.redoStack.shift();
    }
  }

  public clearRedoStack() {
    this.redoStack = [];
  }

  public undo() {
    this.toggle_all_regions_selection(false);
    this.setSelectedRegionId(-1);
    this._rdp_is_region_selected = false;
    this._rdp_is_user_drawing_region = false;
    this._rdp_is_user_resizing_region = false;
    this._rdp_is_user_moving_region = false;
    this._rdp_is_user_drawing_polygon = false;
    this._rdp_is_all_region_selected = false;
    if (this.undoStack.length > 1) {
      const currentData = this.undoStack.pop();
      const historyData = this.undoStack[this.undoStack.length - 1];
      this.saveRedoStack(currentData);
      this.loadData(historyData);
    }
  }

  public redo() {
    this.toggle_all_regions_selection(false);
    this.setSelectedRegionId(-1);
    this._rdp_is_region_selected = false;
    this._rdp_is_user_drawing_region = false;
    this._rdp_is_user_resizing_region = false;
    this._rdp_is_user_moving_region = false;
    this._rdp_is_user_drawing_polygon = false;
    this._rdp_is_all_region_selected = false;
    if (this.redoStack.length > 0) {
      const temp = this.redoStack.pop();
      this.saveUndoStack(temp);
      this.loadData(temp);
    }
  }

  public loadData(data) {
    const parseData = typeof data === "string" ? JSON.parse(data) : data;
    const canvasData = _.cloneDeep(parseData);
    const metaData = _.cloneDeep(parseData);

    this._rdp_canvas_regions = canvasData;
    this.annotatorService.metadata[this._rdp_image_id].regions = metaData;

    this._rdp_redraw_reg_canvas();
  }

  public undoStackInit() {
    this.undoStack = [];
    this.saveUndoStack(
      this.annotatorService.metadata[this._rdp_image_id].regions
    );
  }

  regionIsShow(val) {
    if (!!this.filter === false) {
      return true;
    } else {
      return val.region_attributes.variation === this.filter;
    }
  }

  setDefaultVariation(val?) {
    if (!val) {
      let index = 0;
      for (const variation of this.variations) {
        if (!!variation.is_default) {
          this.defaultVariation = {
            variation: variation.code,
            variation_display: variation.name,
            variation_index: index,
          };
          break;
        }
        index += 1;
      }
      if (index === this.variations.length) {
        this.defaultVariation = null;
      }
    } else {
      let index = 0;
      for (const variation of this.variations) {
        if (variation.code === val) {
          this.defaultVariation = {
            variation: variation.code,
            variation_display: variation.name,
            variation_index: index,
          };
          break;
        }
        index += 1;
      }
    }
  }

  isEmpty(obj) {
    if (!!obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  canCreate(category) {
    if (!this.configurations) {
      return true;
    } else {
      if (
        !!this.configurations[category] &&
        !!this.configurations[category].disable_create
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  canDelete(category?) {
    if (!!category) {
      if (!this.configurations) {
        return true;
      } else {
        if (
          !!this.configurations[category] &&
          !!this.configurations[category].disable_delete
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (!this.configurations) {
        return true;
      } else {
        if (
          !!this.configurations["default"] &&
          !!this.configurations["default"].disable_delete
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
}
