export class VariationAttribute {
  code: string;
  name: string;
  widget: string;
  value: any;
  options: any;
  required: boolean;
  description: string;

  constructor(options: {
    code?: string;
    name?: string;
    widget?: string;
    description?: string;
    options?: any;
    required?: boolean;
    value?: any;
  }) {
    this.code = options.code || "";
    this.name = options.name || "";
    this.widget = options.widget || "";
    this.description = options.description || "";
    this.options = options.options || [];
    this.required = !!options.required;
    this.value = options.value;
  }
}
