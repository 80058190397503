import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AttributeControlService } from "../../../shared/services/attribute-control.service";

@Component({
  selector: "app-user-create-form",
  templateUrl: "./user-create-form.component.html",
  styleUrls: ["./user-create-form.component.scss"],
})
export class UserCreateFormComponent implements OnInit {
  attributes: any = [
    {
      code: "username",
      name: "아이디",
      widget: "text",
      options: [],
    },
    {
      code: "password1",
      name: "비밀번호",
      widget: "password",
      options: [],
    },
    {
      code: "password2",
      name: "비밀번호 확인",
      widget: "password",
      options: [],
    },
  ];

  form: FormGroup;
  @Output() formSubmitEvent = new EventEmitter();

  constructor(private acs: AttributeControlService) {}

  ngOnInit(): void {
    this.form = this.acs.toFormGroup(this.attributes);
  }

  onSubmit() {
    // console.log(this.form.value);
  }
}
