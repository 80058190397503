import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { ImageAnnotatorModule } from "./image-annotator/image-annotator.module";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { httpInterceptorProviders } from "./core/http-interceptors";
import { AccountModule } from "./account/account.module";
import { AppRoutingModule } from "./app-routing.module";
import { WebsiteModule } from "./website/website.module";
import { ProjectModule } from "./project/project.module";
import { AudioAnnotatorModule } from "./audio-annotator/audio-annotator.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ImageAnnotatorModule,
    AudioAnnotatorModule,
    CoreModule,
    AccountModule,
    AppRoutingModule,
    WebsiteModule,
    ProjectModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
      timeOut: 10000,
    }),
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
