export class FileMetadata {
  filename: string;
  size: any;
  regions: any;
  segmentations: any;
  file_attributes: any;

  constructor(filename, size) {
    this.filename = filename;
    this.size = size; // file size in bytes
    this.regions = [];
    this.segmentations = [];
    this.file_attributes = {};
  }
}
