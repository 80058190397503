import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AudioAnnotatorComponent } from "./pages/audio-annotator.component";
import { PronunciationListComponent } from "./components/pronunciation-list/pronunciation-list.component";

@NgModule({
  declarations: [AudioAnnotatorComponent, PronunciationListComponent],
  imports: [SharedModule, CommonModule, FormsModule],
  exports: [AudioAnnotatorComponent, PronunciationListComponent],
})
export class AudioAnnotatorModule {}
