import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { AnnotatorService } from "../../services/annotator.service";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";
import { EventService } from "../../../core/services/event.service";
import { AnnotatorEventService } from "../../services/annotator-event.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-sidebar-label",
  templateUrl: "./sidebar-label.component.html",
  styleUrls: ["./sidebar-label.component.scss"],
})
export class SidebarLabelComponent {
  @Input() project: any;
  @Input() category: string;
  @Input() specification: any;
  @Input() isInspection: boolean;
  @Input() inspections = [];
  @Output() removeColorEvent = new EventEmitter();
  @ViewChild(DynamicFormComponent) dynamicForm;
  @ViewChild("sidebarList") sidebarList: ElementRef;
  regionVariationIndex: any;
  currentAttributes: any = {};
  currentIndex = 0;
  currentPercent = 0;
  unsubscribe: Subject<void> = new Subject();
  errors = [];
  instanceColors = [];
  filter = "";
  isSelectDisabled = false;

  lockList = [];
  isAllLockStatus = false;
  checkedIncludeIslock = false;
  // font awesome
  faLockOpen = faLockOpen;
  faLock = faLock;

  constructor(
    public annotatorService: AnnotatorService,
    private annotatorEvent: AnnotatorEventService,
    private cdf: ChangeDetectorRef,
    private event: EventService
  ) {
    event.onProjectUpdate$.subscribe(this.initSidebar.bind(this));
    event.onProjectDestroy$.subscribe(this.destroySidebar.bind(this));
    annotatorEvent.onPercentUpdate$.subscribe((e) => {
      this.currentPercent = e;
    });
    this.annotatorEvent.onNumericKeyDown$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        if (this.category === "image") {
          if (
            this.currentIndex >= 0 &&
            e > 0 &&
            e <= this.project.variations.length
          ) {
            const num = e - 1;
            this.regionVariationIndex = num;
            this.dynamicForm.onSubmit();
          }
        }
      });

    this.annotatorEvent.onSelectBoundingBox$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: number) => {
        this.currentIndex = e;
        if (!this.filter) {
         if (e !== -1) {
            if (this.sidebarList.nativeElement.children[e]) {
               this.sidebarList.nativeElement.closest("#sidebarColumn").scrollTop = this.sidebarList.nativeElement.children[e].offsetTop;
            }
          }
        }
      });

    this.annotatorEvent.onErrorsUpdated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e: any) => {
        if (this.category === "image") {
          this.errors = e.error;
        }
      });
  }

  initSidebar(id?) {
    if (this.category === "segmentation") {
      this.currentIndex = 0;
      this.currentPercent = 0;
    } else if (this.category === "instance_segmentation") {
      this.currentIndex = 0;
      this.currentPercent = 0;
      this.project.original_variations = JSON.parse(
        JSON.stringify(this.project.variations)
      );
      if (
        this.project.data.instances &&
        this.project.data.instances.length > 0
      ) {
        this.project.variations = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.project.data.instances.length; i++) {
          this.project.variations.push({
            name: this.project.data.instances[i].name,
            code: this.project.data.instances[i].code,
          });
        }
      }
    } else {
      this.regionVariationIndex = null;
      this.currentAttributes = {};
      if (
        this.specification &&
        this.specification.configurations &&
        this.specification.configurations.classification
      ) {
        this.isSelectDisabled =
          !!this.specification.configurations.classification.disable_update;
      }

      if (
        id !== -1 &&
        id !== null &&
        typeof id !== "undefined" &&
        typeof id !== "object"
      ) {
        const region = this.getRegionList()[id];
        if (!this.isEmpty(region.region_attributes)) {
          this.regionVariationIndex = region.region_attributes.variation_index;
          for (const key of Object.keys(region.region_attributes)) {
            if (key !== "variation" && key !== "variation_index") {
              this.currentAttributes[key] = region.region_attributes[key];
            }
          }
        }
      } else {
        this.currentAttributes = {};
      }
    }
    this.setLock();
  }

  setLock() {
    const currentList = this.getRegionList();
    currentList.forEach((r, i) => {
      if (r.is_lock === true) {
        if (!this.lockList.includes(i)) {
          this.lockList.push(i);
        }
        this.checkedIncludeIslock = true;
      }
    });
    this.annotatorEvent.onLockStatusChanged$.emit(this.lockList);
  }

  destroySidebar() {
    if (this.category === "segmentation") {
      this.annotatorService.init();
      this.event.onProjectUpdate$.emit();
    }
  }

  getRegionList() {
    const currentImageId = this.annotatorService.currentImageId;
    return currentImageId
      ? this.annotatorService.metadata[this.annotatorService.currentImageId]
          .regions
      : [];
  }

  isEmpty(obj) {
    if (!!obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  selectItem(rid) {
    this.currentIndex = rid;
    this.event.onSelectedItemChanged$.emit({
      rid,
      isSidebarClick: true,
    });
  }

  removeColor(index) {
    this.removeColorEvent.emit(index);
  }

  removeInstance(index) {
    if (confirm("Are you sure you want to delete instance?")) {
      this.removeColor(index);
      this.project.variations.splice(index, 1);
    }
  }

  checkOriginalImage() {
    this.annotatorEvent.onCheckOriginalImageUpdate$.emit();
  }

  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    const colorCodes = this.project.variations.map((d) => d.code);
    if (colorCodes.indexOf(color) > -1) {
      return this.getRandomColor();
    } else {
      return color;
    }
  }

  addInstance() {
    let code = "";
    if (
      this.project.original_variations &&
      this.project.original_variations.length > 0
    ) {
      const defaultVariations = this.project.original_variations.filter(
        (d) => d.is_default
      );
      if (defaultVariations && defaultVariations.length > 0) {
        code = defaultVariations[0].code;
      }
    }

    this.project.variations.push({
      name: code,
      code: this.getRandomColor(),
    });
  }

  onRegionVariationIndexChanged($event) {
    this.dynamicForm.onSubmit();
    this.annotatorEvent.onRegionVariationIndexChanged$.emit({
      val: this.regionVariationIndex,
    });
  }

  onAttributeSubmit($event) {
    const formValue = $event ? $event.value : {};
    const currentRegionId = this.annotatorService.selectedRegionId;
    if (currentRegionId !== -1) {
      const currentRegionList = this.getRegionList();
      currentRegionList[currentRegionId].region_attributes = {
        variation: this.project.variations[this.regionVariationIndex].code,
        variation_index: this.regionVariationIndex,
        variation_display:
          this.project.variations[this.regionVariationIndex].name,
      };
      for (const key of Object.keys(formValue)) {
        currentRegionList[currentRegionId].region_attributes[key] =
          formValue[key];
      }
    }
  }

  getClass(index, labelId?) {
    let className = "";
    if (this.annotatorService.selectedRegionId === index) {
      className += "selected";
    }
    if (this.errors.includes(index)) {
      className += " has-error";
    }
    if (labelId && this.selectInspection(labelId)) {
      className += " has-inspection";
    }
    return className;
  }

  onFilterChanged(e) {
    this.annotatorEvent.onFilterUpdated$.emit(e);
  }

  isShow(val) {
    if (!!this.filter === false) {
      return true;
    } else {
      if (!val.region_attributes.variation) {
        return true;
      } else {
        return val.region_attributes.variation + "" === this.filter;
      }
    }
  }

  selectInspection(id) {
    if (this.inspections && this.inspections.length > 0) {
      return this.inspections.find(
        (inspection) =>
          inspection.label_id === id && !!inspection.is_resolved === false
      );
    } else {
      return null;
    }
  }

  toggleLock(e, index, isLock) {
    e.stopPropagation();
    if (isLock) {
      return;
    }
    const isInclude = this.lockList.includes(index);
    if (isInclude) {
      const i = this.lockList.findIndex(r => r === index);
      this.lockList.splice(i, 1);
    } else {
      this.lockList.push(index);
    }
    this.annotatorEvent.onLockStatusChanged$.emit(this.lockList);
  }

  isLock(index) {
    const isInclude = this.lockList.includes(index);
    if (isInclude) {
      return true;
    } else {
      return false;
    }
  }

  toggleAllLock(e) {
    e.stopPropagation();

    this.isAllLockStatus = !this.isAllLockStatus;
    const lockAllList = this.getRegionList();

    if (this.isAllLockStatus) {
      lockAllList.forEach((r, i) => {
        this.lockList.push(i);
      });
    } else {
      this.lockList = [];
    }
    this.annotatorEvent.onLockStatusChanged$.emit(this.lockList);
  }
}
