import { NgModule } from "@angular/core";

import { WebsiteRoutingModule } from "./website-routing.module";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { SharedModule } from "../shared/shared.module";
import { AccountModule } from "../account/account.module";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [LandingPageComponent],
  imports: [SharedModule, WebsiteRoutingModule, AccountModule, CommonModule],
})
export class WebsiteModule {}
