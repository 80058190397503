import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "objectToList",
})
export class ObjectToListPipe implements PipeTransform {
  transform(dict: any) {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ k: key, v: dict[key] });
      }
    }
    return a;
  }
}
