import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../../../shared/services/project.service";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list-page.component.html",
  styleUrls: ["./project-list-page.component.scss"],
})
export class ProjectListPageComponent implements OnInit {
  projects$;
  currentNumber = 1;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.onPageChanged(1);
  }

  onPageChanged(num, link?) {
    this.currentNumber = +num;
    this.projects$ = null;
    this.projects$ = this.projectService.getList(link);
  }
}
