import { Component, Input, OnInit } from "@angular/core";
import { AnnotatorEventService } from "../../services/annotator-event.service";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  @Input() toolbar;
  @Input() btn_group;
  @Input() isViewMode;
  @Input() isInspection;
  @Input() actions;
  @Input() zoom;
  @Input() url_samples;
  @Input() raw_data;
  @Input() specification;
  currentPolygonMode;
  custom_button_link;
  custom_button_display_name;

  constructor(public annotatorEvent: AnnotatorEventService) {}

  ngOnInit() {
    if (this.toolbar) {
      this.currentPolygonMode = this.toolbar[0].display_name;
    }
    if (
      this.raw_data &&
      this.raw_data.data &&
      this.raw_data.data.custom_button_link
    ) {
      this.custom_button_link =
        this.raw_data.data.custom_button_link;
    }

    if (this.specification && this.specification.custom_button_display_name) {
      this.custom_button_display_name = this.specification.custom_button_display_name;
    }
  }

  onActionClicked(e) {
    const action: any = e;
    const isCompleted = !!action.data.status || !!action.data.status_reserved;
    if (!e.message_confirm || confirm(action.message_confirm)) {
      this.annotatorEvent.onSubmit$.emit({
        isCompleted,
        action,
      });
    }
  }

  modalOpen() {
    this.annotatorEvent.onModalOpen$.emit();
  }

  setBrightness(val) {
    this.annotatorEvent.onBrightnessUpdate$.emit(val);
  }

  setPolygonModeChanged(mode) {
    this.currentPolygonMode = mode;
    this.annotatorEvent.onPolygonModeChanged$.emit(mode);
  }

  setZoom(val) {
    this.annotatorEvent.onZoomUpdate$.emit({ zoom: this.zoom * val });
  }

  resetZoom() {
    this.annotatorEvent.onZoomReset$.emit();
  }

  openLink(url) {
    window.open(url, "_blank");
  }

  requestNewData(val) {
    this.annotatorEvent.onDataRequest$.emit(val);
  }
}
