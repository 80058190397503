import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TokenLoginComponent } from "./components/token-login/token-login.component";

const routes: Routes = [
  {
    path: "login/token/:token",
    component: TokenLoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
