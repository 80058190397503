import { Component, OnInit } from "@angular/core";
import { EventService } from "../../../core/services/event.service";
import { UsertaskService } from "../../services/usertask.service";

@Component({
  selector: "app-usertask-list",
  templateUrl: "./usertask-list.component.html",
  styleUrls: ["./usertask-list.component.scss"],
})
export class UsertaskListComponent implements OnInit {
  paginatedResult$;

  constructor(
    private event: EventService,
    private usertaskService: UsertaskService
  ) {}

  ngOnInit() {
    this.getPaginatedResult();
  }

  getPaginatedResult() {
    this.paginatedResult$ = this.usertaskService.getListPaginated({
      is_mine: true,
      status: "completed",
    });
  }

  getPaginatedResultByUrl(url) {
    this.paginatedResult$ = this.usertaskService.getListPaginatedByUrl(url);
  }
}
