import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProjectListPageComponent } from "./pages/project-list/project-list-page.component";
import { ProjectRoutingModule } from "./project-routing.module";
import { ProjectDetailComponent } from "./pages/project-detail/project-detail.component";
import { AudioAnnotatorModule } from "../audio-annotator/audio-annotator.module";
import { ImageAnnotatorModule } from "../image-annotator/image-annotator.module";
import { UsertaskListComponent } from "./components/usertask-list/usertask-list.component";
import { SharedModule } from "../shared/shared.module";
import { ClassificationAnnotatorModule } from "../classification-annotator/classification-annotator.module";

@NgModule({
  declarations: [
    ProjectListPageComponent,
    ProjectDetailComponent,
    UsertaskListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectRoutingModule,
    AudioAnnotatorModule,
    ImageAnnotatorModule,
    ClassificationAnnotatorModule,
  ],
})
export class ProjectModule {}
