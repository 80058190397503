import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class InspectionService {
  constructor(private http: HttpClient) {}

  create(data) {
    return this.http.post(environment.apiUrl + "inspections/", data);
  }

  patch(id, data) {
    return this.http.patch(
      environment.apiUrl + "inspections/" + id + "/",
      data
    );
  }
}
