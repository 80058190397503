import { Component, Input, OnInit } from "@angular/core";
import { InspectionService } from "../../services/inspection.service";
import { AnnotatorEventService } from "../../services/annotator-event.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-comment-detail",
  templateUrl: "./comment-detail.component.html",
  styleUrls: ["./comment-detail.component.scss"],
})
export class CommentDetailComponent implements OnInit {
  @Input() inspection;
  @Input() isInspection;

  is_resolved;

  constructor(
    private annotatorEvent: AnnotatorEventService,
    private toastr: ToastrService,
    private inspectionService: InspectionService
  ) {}

  ngOnInit() {}

  submit(is_resolved) {
    const data = {
      content: this.inspection.content,
      tags: this.inspection.tags,
      is_resolved: this.is_resolved,
    };
    this.inspectionService.patch(this.inspection.id, data).subscribe((res) => {
      this.inspection = res;
      this.annotatorEvent.onInspectionCreate$.emit({
        data: res,
        action: "change",
      });
      this.toastr.info("코멘트가 변경되었습니다.");
    });
  }
}
